import { Action } from 'redux';
import { ModalPayload } from './types';

export const GET_GLOBAL_MODAL = 'store/globalModal/GetGlobalModal';
export const SET_GLOBAL_MODAL = 'store/globalModal/SetGlobalModal';
export const SET_CLOSE_GLOBAL_MODAL = 'store/globalModal/SetCloseGlobalModal';

export interface GetGlobalModalAction extends Action {
    globalModal: any;
}
export interface SetGlobalModalAction extends Action {
    globalModal: any;
}
export interface SetCloseGlobalModalAction extends Action {}

export interface ModalDeleteAction extends Action {
    modalPayload: ModalPayload;
}

export type GlobalModalAction = GetGlobalModalAction
    | SetGlobalModalAction
    | SetCloseGlobalModalAction
    | ModalDeleteAction;
