import ApiHelper, { API_ERM_URL, BASE_API_URL } from 'helpers/apiHelper';
import { ModalPayload } from 'store/GlobalModal/types';
import { FiltersState } from 'store/Filters/types';
import { AssociateAndResolveBody, ResolveConflictBody } from 'store/Erm/FlaggedEntity/types';

export const getEntities = async (filter: FiltersState) => {
    return ApiHelper.doRequest(
        `${API_ERM_URL}/entities/`,
        'get',
        {...filter}
    );
};

export const getEntityProperties = async ( id: number): Promise<any> => {
    try {
        const response = await ApiHelper.get(`${API_ERM_URL}/entities/${id}/`);
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const getEntityAuditTrail = async ( id: number): Promise<any> => {
    try {
        const response = await ApiHelper.get(`${API_ERM_URL}/audit_trails/?target_id=${id}`);
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const updateEntityProperties = async ( payload: any): Promise<any> => {
    try {
        const response = await ApiHelper.patch(`${API_ERM_URL}/entities/${payload.id}/`, payload.data);
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const addOtherName = async ( entityId: number, newObj: object ): Promise<any> => {
    return ApiHelper.doRequest(
        `${API_ERM_URL}/entities/${entityId}/other_names/`,
        'post',
        newObj
    );
};

export const updateEntityOtherName = async (
    entityId: number, otherNameId: number, updateData: object
): Promise<any> => {
    return ApiHelper.doRequest(
        `${API_ERM_URL}/entities/${entityId}/other_names/${otherNameId}/`,
        'patch',
        { ... updateData}
    );
};

export const swapLegalAndOtherName = async ( entityId: number, otherNameId: number): Promise<any> => {
    return ApiHelper.doRequest(
        `${API_ERM_URL}/entities/${entityId}/other_names/${otherNameId}/use_as_legal/`,
        'post',
        {}
    );
};

export const getRelationships = async ( filter: any): Promise<any> => {
    try {
        const response = await ApiHelper.get(`${API_ERM_URL}/relationships/`,  { ...filter });
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const getRelationship = async ( id: any): Promise<any> => {
    try {
        const response = await ApiHelper.get(`${API_ERM_URL}/relationships/${id}/`);
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const putRelationship = async (entityId: any, id: any, body: any): Promise<any> => {
    try {
        const response = await ApiHelper.put(`${API_ERM_URL}/entities/${entityId}/relationships/${id}/`, body);
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const createRelationship = async ( payload: any): Promise<any> => {
    try {
        const response = await ApiHelper.post(
            `${API_ERM_URL}/entities/${payload.start_entity}/relationships/`,
            { ...payload }
            );
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const deleteRelationship = async (id: number): Promise<any> => {
    try {
        const response = await ApiHelper.delete(`${API_ERM_URL}/relationships/${id}/`);
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors({}));
        }
        return Promise.resolve({});
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const getFlaggedEntities = async ( filter: any): Promise<any> => {
    try {
        const response = await ApiHelper.get(`${API_ERM_URL}/flagged_entity_messages/`,  { ...filter });
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const getMergeEntities = async (filter: FiltersState) => {
    return ApiHelper.doRequest(
        `${API_ERM_URL}/entities/merging/`,
        'get',
        {...filter}
    );
};

export const mergeEntities = async (mergingEntityRowId: string) => {
    return ApiHelper.doRequest(
        `${API_ERM_URL}/entities/merging/merge-entities/`,
        'get',
        {
            id: mergingEntityRowId,
        }
    );
};

export const bulkMergeEntities = async (filter: FiltersState): Promise<any> => {
    return ApiHelper.doRequest(
        `${API_ERM_URL}/entities/merging/merge-entities/`,
        'get',
        {...filter}
    );
};

export const createDataSourceForEntity = async (entityId: any, dataSource: any): Promise<any> => {
    try {
        const response = await ApiHelper.post(`${API_ERM_URL}/entities/${entityId}/data_sources/`,  { ...dataSource });
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const propagateEntityToCredit = async (entityId: string): Promise<any> => {
    return ApiHelper.doRequest(
        `${API_ERM_URL}/entities/${entityId}/propagate/`,
        'get',
    );
};

export const createEntity = async (payload: any): Promise<any> => {
    try {
        const response = await ApiHelper.post(`${API_ERM_URL}/entities/`,  { ...payload });
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const deleteFlaggedEntities = async ( messageIds: any): Promise<any> => {
    try {
        const response = await ApiHelper.post(
            `${API_ERM_URL}/flagged_entity_messages/suppress/`,
            { message_ids: messageIds }
        );
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const assignFlaggedEntities = async ( messageIds: any, userId: any ): Promise<any> => {
    try {
        const response = await ApiHelper.post(
            `${API_ERM_URL}/flagged_entity_messages/assign/`,
            {
                message_ids: messageIds,
                user_id:  userId
            }
        );
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const reprocessFlaggedEntities = async ( messageIds: any): Promise<any> => {
    try {
        const response = await ApiHelper.post(
            `${API_ERM_URL}/flagged_entity_messages/reprocess/`,
            { message_ids: messageIds }
        );
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const getFlaggedRelationships = async ( filter: any): Promise<any> => {
    try {
        const response = await ApiHelper.get(`${API_ERM_URL}/flagged_relationship_messages/`,  { ...filter });
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const associateAndResolveEntity = async (flaggedMessageId: number, body: AssociateAndResolveBody) => {
    return ApiHelper.doRequest(
        `${API_ERM_URL}/flagged_entity_messages/${flaggedMessageId}/associate/`,
        'post',
        body
    );
};

export const resolveConflictForFlaggedEntity = async (flaggedMessageId: number, body: ResolveConflictBody) => {
    return ApiHelper.doRequest(
        `${API_ERM_URL}/flagged_entity_messages/${flaggedMessageId}/resolve_conflict/`,
        'post',
        body
    );
};

export const deleteFlaggedRelationships = async ( messageIds: any): Promise<any> => {
    try {
        const response = await ApiHelper.post(
            `${API_ERM_URL}/flagged_relationship_messages/suppress/`,
            { message_ids: messageIds }
        );
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const assignFlaggedRelationships = async ( messageIds: any, userId: any ): Promise<any> => {
    try {
        const response = await ApiHelper.post(
            `${API_ERM_URL}/flagged_relationship_messages/assign/`,
            {
                message_ids: messageIds,
                user_id:  userId
            }
        );
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const reprocessFlaggedRelationships = async ( messageIds: any): Promise<any> => {
    try {
        const response = await ApiHelper.post(
            `${API_ERM_URL}/flagged_relationship_messages/reprocess/`,
            { message_ids: messageIds }
        );
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const modifyFlaggedRelationships = async (id: any, body: any): Promise<any> => {
    try {
        const response = await ApiHelper.put(
            `${API_ERM_URL}/flagged_relationship_messages/${id}/`,
            { ...body }
        );
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const getAuditTrailEntities = async (filter: any): Promise<any> => {
    try {
        const response = await ApiHelper.get(`${API_ERM_URL}/audit_trails/`, {...filter});
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }
        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const deleteDatasourceById = async (payload: ModalPayload): Promise<any> => {
    try {
        const response = await ApiHelper.delete(
            `${API_ERM_URL}/entities/${payload.id}/data_sources/${payload.row.id}/`
        );
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors({}));
        }
        return Promise.resolve({});
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const editDatasource = async (payload: any): Promise<any> => {
    try {
        const response = await ApiHelper.put(
            `${API_ERM_URL}/entities/${payload.data.payload.id}/data_sources/${payload.data.payload.row.id}/`,
            payload.newObj
        );
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors({}));
        }
        return Promise.resolve({});
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const addDatasource = async (payload: any): Promise<any> => {
    try {
        const response = await ApiHelper.post(
            `${API_ERM_URL}/entities/${payload.data.payload.id}/data_sources/`,
            payload.newObj
        );
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors({}));
        }
        return Promise.resolve({});
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const getAssignToItems = async (filter: any): Promise<any> => {

    const response = await ApiHelper.doRequest(
        `${BASE_API_URL}/credit/users/`,
        'get',
        {
             ...filter
        }
    );

    return Promise.resolve(response);
};

export const bulkReprocessFlaggedRelationships = async (): Promise<any> => {

    const response = await ApiHelper.doRequest(
        `${API_ERM_URL}/flagged_relationship_messages/bulk_reprocess/`,
        'post',
        {

        }
    );

    return Promise.resolve(response);
};

export const bulkReprocessFlaggedEntities = async (): Promise<any> => {

    const response = await ApiHelper.doRequest(
        `${API_ERM_URL}/flagged_entity_messages/bulk_reprocess/`,
        'post',
        {

        }
    );

    return Promise.resolve(response);
};