import * as React from 'react';
import { Container } from 'reactstrap';
import SplitPane from 'react-split-pane';

import Logo from './Shared/Logo/Logo';
import NavigationPanel from './Shared/Navigation/Panel';
import { NavigationButton } from '../store/types';
import { MuiThemeProvider } from 'material-ui/styles';

const styles = require('./AppPage.scss');

interface Props {
    buttons: NavigationButton[];

    minSize: number;
    maxSize: number;
    size?: number;
    handleDrag: (newSize: number) => void;
    handleDragStart: () => void;
    handleDragEnd: (newSize: number) => void;
}

class AppPage extends React.Component<Props> {
    render() {
        return (
            <MuiThemeProvider>
                <Container fluid={true} className="h-100">
                    <SplitPane
                        className={styles.splitPane}
                        split="vertical"
                        minSize={this.props.minSize}
                        maxSize={this.props.maxSize}
                        size={this.props.size}
                        onChange={this.props.handleDrag}
                        onDragStarted={this.props.handleDragStart}
                        onDragFinished={this.props.handleDragEnd}
                    >
                        <div className={`${styles.panel} d-flex flex-column`}>
                            <Logo />
                            <NavigationPanel buttons={this.props.buttons} />
                        </div>
                        <div className={`${styles.content} d-flex flex-column`}>
                            {this.props.children}
                        </div>
                    </SplitPane>
                </Container>
            </MuiThemeProvider>
        );
    }
}

export default AppPage;
