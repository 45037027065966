import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EditDatasource
    from '../../../../components/Erm/Actions/Properties/EditDatasource';
import { editDatasource } from '../../../../store/Erm/Entity/actionCreators';
import { setCloseGlobalModal } from '../../../../store/GlobalModal/actionCreators';

const mapStateToProps = (state: any, ownProps: any) => {
    const modal = state.globalModal;
    return {
        payload: modal && modal.payload ? modal.payload : {},
    };

};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            editDatasource,
            closeModal: () => {
                return setCloseGlobalModal();
            },
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDatasource);
