import { User } from './models/user';

export interface UsersState {
    list: GetListResponse;
    refreshStep: number;
}

export interface UsersFilter {
    limit: number;
    offset: number;
    ordering: string;
    email: string;
}

export interface GetListResponse {
    count: number;
    results: User[];
    next: string;
    previous: string;
}

export enum UserGlobalRole {
    None = 'none',
    Concierge = 'concierge',
    Sysadmin = 'sysadmin'
}