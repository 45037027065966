import { FilterItems, FilterNames, FiltersState } from './types';
import {
    GET_FILTERS_ITEMS, GetFiltersItemsAction, SET_FILTERS_ITEMS, SetFiltersAction, SetFiltersItemsAction,
    GET_FILTERS, SET_FILTERS
} from './actions';
import { DEFAULT_FILTER } from '../../constants';

const initialState: FiltersState = {
    filter_items: {
      [FilterItems.ermAssignTo]: []
    },
    erm_entities: {
            ...DEFAULT_FILTER,
            entity_type: ''
    },
    erm_flagged_entities: {
        ...DEFAULT_FILTER,
    },
    erm_flagged_relationships: {
        ...DEFAULT_FILTER,
        assigned_to: ''
    },
    [FilterNames.ErmRelationships]: {
        ...DEFAULT_FILTER,
    },
    [FilterNames.ErmEntityRelationships]: {
        ...DEFAULT_FILTER,
    }

};

export const filtersReducer = (state: FiltersState = initialState, action: any) => {
    switch (action.type) {
        case GET_FILTERS:
            return {
                ...state,
                [action.filterName]: action.payload
            };
        case SET_FILTERS:
            action = (<SetFiltersAction> action);
            return {
                ...state,
                [action.filterName]: action.payload
            };
        case GET_FILTERS_ITEMS:
            action = (<GetFiltersItemsAction> action);
            return state;
        case SET_FILTERS_ITEMS:
            action = (<SetFiltersItemsAction> action);
            return {
                ...state,
                filter_items: {
                    ...state.filter_items,
                    [action.filterItemName]: action.payload
                }
            };
        default:
            return state;
    }
};