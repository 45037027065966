import * as React from 'react';
import { Modal as RSModal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

interface Props {
    isOpened: boolean;
    title: JSX.Element | JSX.Element[] | string;
    body: JSX.Element | JSX.Element[] | string;
    toggle: () => void;
    buttons: {
        color: string;
        title: string;
        onClick: () => void;
    }[];
    size?: string;
}

const Modal: React.FunctionComponent<Props> = ({isOpened, title, body, toggle, buttons, size}) => {
    return (
        <RSModal isOpen={isOpened} toggle={toggle} size={size}>
            <ModalHeader toggle={toggle}>
                {title}
            </ModalHeader>
            <ModalBody>
                {body}
            </ModalBody>
            <ModalFooter>
                {buttons.map((button, idx) => (
                    <Button key={idx} color={button.color} onClick={button.onClick}>
                        {button.title}
                    </Button>
                ))}
            </ModalFooter>
        </RSModal>
    );
};

Modal.defaultProps = {
    size: 'md'
};

export default Modal;