import * as React from 'react';
import { CellTypeChoices, FiltersItems, HeaderSettings } from '../../../store/types';
import { formatText, replaceParam } from '../../../helpers/tableHelper';
import { NavLink, Link } from 'react-router-dom';
import { isUndefined } from 'util';
import { mapUsersByUuid } from '../../../helpers/selectorHelper';
import GlobalHelper from '../../../helpers/globalHelper';

interface Props {
    rows: Object[];
    header: HeaderSettings[];
    handleRowClick?: any;
    selectedRowId?: number;
    rowId: any;
    filtersItems?: any;
}
class DataTableBody extends React.PureComponent<Props> {

    formatCellTypeValue = (fieldValue: any, header: HeaderSettings, row: any, id?: number): any => {
        let value = isUndefined(fieldValue) ? '' : fieldValue;

        const onClickFunction = this.props.handleRowClick
            ? this.props.handleRowClick
            : (data: any) => { return data; };
        switch (header.cellType) {
            case CellTypeChoices.ModalWithText:
                value = (
                    <Link to="#" onClick={() => onClickFunction(row)}>
                        <span className="input-label">{fieldValue}</span>
                    </Link>
                );
                break;
            case CellTypeChoices.SelectAction:
                const rowId = !isUndefined(id) ? id : -1;
                value = (
                    <Link to="#" onClick={() => { onClickFunction(rowId, row); }}>
                        <span className="input-label">Select</span>
                    </Link>
                );
                break;
            case CellTypeChoices.Link:
                const linkTemplate = header.linkTemplate ? header.linkTemplate : '';
                const route = replaceParam(linkTemplate, row);
                if (
                        (row.start_id === this.props.rowId && header.name === 'start_name') ||
                        (row.end_id === this.props.rowId && header.name === 'end_name')
                ) {
                    value = (
                        <span>This Entity</span>
                    );
                } else {
                    value = (
                        <NavLink to={route}><span className="input-label">{fieldValue}</span></NavLink>
                    );
                }
                break;
            case CellTypeChoices.Custom:
                value = header.transformer && header.transformer(row);
                break;
            case CellTypeChoices.FilterItems:
                value = (
                    <span className="input-label">{fieldValue}</span>
                );
                if (this.props.filtersItems) {
                    if (header.filterType === FiltersItems.Users && GlobalHelper.isUuidData(fieldValue)) {

                        value = (
                            <span className="input-label">
                                    {mapUsersByUuid(this.props.filtersItems, fieldValue)}
                                </span>
                        );
                    }
                }
                break;
            case CellTypeChoices.Text:
            default:
                value = (
                    <span className="input-label">{fieldValue}</span>
                );
                break;
        }

        return value;
    }

    render() {
        const { rows, header } = this.props;
        const selectedRow = this.props.selectedRowId;
        const filteredHeader = header.filter(item => item.active);

        let rowsHtml: JSX.Element | JSX.Element[] = (
            <tr><td colSpan={filteredHeader.length}>No data</td></tr>
        );

        if (rows.length) {
            rowsHtml = rows.map((row: any, idx) => {
                const rowColor = selectedRow === idx ? 'selected-row' : '';
                return (
                    <tr key={idx} className={rowColor}>
                        {
                            filteredHeader.map((item: HeaderSettings, i) => {
                                const rowValue = GlobalHelper.getValueFromObject(row, item.name);
                                const fieldValue = formatText(rowValue, item.valueType);
                                const cellValue = this.formatCellTypeValue(fieldValue, item, row, idx);
                                return (<td key={i}>{cellValue}</td>);
                            })
                        }
                    </tr>
                );
            });
        }

        return (
            <tbody>{rowsHtml}</tbody>
        );
    }
}

export default DataTableBody;