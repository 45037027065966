import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import ViewModal from '../../components/Rca/ViewModal';
import { getModalParams, isModalOpen } from '../../store/Modal/selectors';
import { AppState } from '../../store/types';
import { closeModal } from '../../store/Modal/actionCreators';

export const name = 'VIEW_RCA_MODAL';

const mapStateToProps = (state: AppState) => ({
    isOpened: isModalOpen(state, {name}),
    params: getModalParams(state)
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return bindActionCreators({toggle: closeModal}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewModal);