import * as React from 'react';
import { Async } from 'react-select';
import { debounce } from 'lodash';
import { ActionMeta, OptionsType, ValueType } from 'react-select/lib/types';

import { SelectItem } from 'store/types';
import { SEARCH_DEBOUNCE_INTERVAL } from 'store/constants';
import { FiltersState } from 'store/Filters/types';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../constants';

interface Props {
    placeholder: string;
    value: ValueType<SelectItem>;
    handleChange: (newValue: ValueType<SelectItem>, actionMeta?: ActionMeta) => void;
    clearable?: boolean;
    disabled?: boolean;
    loadOptions: (filter: FiltersState) => Promise<any>;
}

const mapFieldsForSelect = (label: string, value: string, data: any): OptionsType<SelectItem> => {
    let answer = [];
    if (data) {
        answer = data.map((item: any) => {
            return {
                value: item[value],
                label: item[label]
            };
        });
    }

    return answer;
};

class UiAsyncSelect extends React.PureComponent<Props> {

    static defaultProps = {
        clearable: true,
    };

    getDataAfterDebounce = debounce(
        (inputValue: string, callback: (options: OptionsType<SelectItem>) => void) => {
            this.getOptions(inputValue, callback);
        },
        SEARCH_DEBOUNCE_INTERVAL
    );

    private static isOptionSelected = (option: SelectItem, selectValue: SelectItem[]) => (
        !!option && !!selectValue.find(
            (item: SelectItem) => !!item && option.value === item.value
        )
    )

    getOptions = (inputValue: string, callback: (options: OptionsType<SelectItem>) => void) => {
        if (!inputValue) {
            return callback([]);
        }

        const searchPayload = {search: inputValue, limit: DEFAULT_ITEMS_PER_PAGE};
        this.props.loadOptions(searchPayload)
            .then((data: any) => {
                callback(mapFieldsForSelect('legal_name', 'id', data.results));
            })
            .catch(() => {
                callback([]);
            });
    }

    render() {
        const {
            value, placeholder, handleChange, clearable
        } = this.props;

        return (
            <Async
                value={value}
                getOptionLabel={this.getOptionLabel}
                getOptionValue={this.getOptionValue}
                isOptionSelected={UiAsyncSelect.isOptionSelected}
                onChange={handleChange}
                placeholder={placeholder}
                loadOptions={this.getDataAfterDebounce}
                isClearable={clearable}
            />
        );
    }

    private getOptionLabel = (option: SelectItem) => {
        return option && option.label || '';
    }

    private getOptionValue = (option: SelectItem) => {
        return option && option.value ||  '';
    }

}

export default UiAsyncSelect;
