import * as React from 'react';
import { DateRangePicker } from 'react-dates';
import * as moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../../../constants';
import { FilterType } from 'store/types';

interface Props {
    name: string;
    title: string;
    value: any;
    start?: any;
    end?: any;
    handleChange: (name: any, type: string, value: any) => void;
}

const styles = require('./DateFilter.scss');

class DateFilter extends React.PureComponent<Props> {

    state = {
        startDate: null,
        endDate: null,
        focusedInput: null
    };

    handleChange = (startDate?: any, endDate?: any ) => {
        const valueDate = {};
        if (startDate) {
            valueDate[`${this.props.name}_gte`] = startDate.format(DEFAULT_DATE_FORMAT);
        } else {
            valueDate[`${this.props.name}_gte`] = startDate;
        }

        if (endDate) {
            valueDate[`${this.props.name}_lte`] = endDate.format(DEFAULT_DATE_FORMAT);
        } else {
            valueDate[`${this.props.name}_lte`] = endDate;
        }

        this.setState({ startDate, endDate });

        this.props.handleChange(this.props.name, FilterType.Date, valueDate);
    }

    render() {
        return (
            <div className={styles.date_filter}>
                <DateRangePicker
                    displayFormat={DEFAULT_DATE_FORMAT}
                    startDatePlaceholderText={`${this.props.title} start`}
                    endDatePlaceholderText={`${this.props.title} end`}
                    showClearDates={true}
                    startDateId={`${this.props.name}-start`}
                    endDateId={`${this.props.name}-end`}
                    isOutsideRange={() => false}
                    startDate={this.props.start ? moment(this.props.start) : null}
                    endDate={this.props.end ? moment(this.props.end) : null}
                    onDatesChange={({ startDate, endDate }) => this.handleChange(startDate, endDate)}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    minimumNights={0}
                />
            </div>
        );
    }
}

export default DateFilter;