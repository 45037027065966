import * as React from 'react';
import * as classnames from 'classnames';

import { FilterType, HeaderSettings } from 'store/types';

interface Props {
    header: HeaderSettings;
    handleChange: (name: string, type: string, value: any) => void;
    ordering?: string;
    orderSign?: string;
}

class HeaderColumn extends React.PureComponent<Props> {

    get orderingName() {
        const {header} = this.props;
        return header.orderingName || header.name;
    }

    render() {
        const {handleChange, ordering, orderSign, header} = this.props;

        let thClasses: any = {
            'has-sorting': !header.excludeSorting,
        };
        if (!!orderSign) {
            thClasses[orderSign] = !header.excludeSorting && this.orderingName === ordering;
        }

        return (
            <th
                className={classnames(thClasses)}
                onClick={
                    !header.excludeSorting
                        ? () => handleChange('ordering', FilterType.Ordering, this.orderingName)
                        : undefined
                }
            >
                {header.title}
            </th>
        );
    }
}

export default HeaderColumn;