import { EntityState } from 'store/Erm/Entity/types';

import {
    SET_ENTITIES, SET_ENTITY_PROPERTIES, SET_ENTITY_AUDIT_TRAIL, CREATE_NEW_ENTITY, SET_ENTITY_RELATIONSHIPS,
    SetEntitiesAction, SetEntityPropertiesAction, SetEntityAuditTrailAction, SetEntityRelationshipsAction,
    CreateNewEntityAction, EntityAction,
} from './actions';

const initialState = {
    entities: [],
    count: 0,
};

const reducer = (state: EntityState = initialState, action: EntityAction) => {
    switch (action.type) {
        case CREATE_NEW_ENTITY:
            return {
                ...state,
                newEntity: (<CreateNewEntityAction> action).newEntity,
                dataSources: (<CreateNewEntityAction> action).dataSources,
            };
        case SET_ENTITY_PROPERTIES:
            return {
                ...state,
                item: (<SetEntityPropertiesAction> action).entityProperties
            };
        case SET_ENTITY_AUDIT_TRAIL:
            return {
                ...state,
                itemAuditTrail: (<SetEntityAuditTrailAction> action).payload.results
            };
        case SET_ENTITY_RELATIONSHIPS:
            return {
                ...state,
                itemRelationships: (<SetEntityRelationshipsAction> action).payload.results
            };
        case SET_ENTITIES:
            return {
                ...state,
                entities: (<SetEntitiesAction> action).entities,
                count: (<SetEntitiesAction> action).count,
            };
        default:
            return state;
    }
};

export default reducer;