import * as React from 'react';
import Modal from '../Shared/Modal/Modal';

interface Props {
    isOpened: boolean;
    toggle: () => any;
    apply: (id: number, closeModal: boolean) => any;
    params: any;
    title: string;
    body: string;
}

const ChangeStatusModal: React.FunctionComponent<Props> = ({body, title, isOpened, toggle, apply, params}) => {
    const buttons = [
        {color: 'success', title: 'Yes', onClick: () => apply(params.id, true)},
        {color: 'danger', title: 'No', onClick: toggle},
    ];

    return (
        <Modal
            isOpened={isOpened}
            toggle={toggle}
            title={title}
            body={body}
            buttons={buttons}
            size="md"
        />
    );
};

export default ChangeStatusModal;