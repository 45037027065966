import ApiHelper, { API_RCA_URL } from '../helpers/apiHelper';

import Rca from '../store/Rca/models/rca';
import * as rcaHelper from '../store/Rca/rcaHelper';
import { RcaFilter } from '../store/Rca/types';
import RcaRequest from '../store/Rca/models/rcaRequest';

export const getList = async (filter: RcaFilter) => {
    try {
        const response = await ApiHelper.doRequest(`${API_RCA_URL}/`, 'get', {...prepareFilter(filter)});

        return {
            ...response,
            results: response.results.map((rca: Rca) => rcaHelper.prepareForList(rca))
        };
    } catch (error) {
        return Promise.reject(error);
    }
};

export const approve = async (id: number) => {
    return await ApiHelper.doRequest(`${API_RCA_URL}/${id}/approve/`, 'post');
};

export const create = async (id: number) => {
    return await ApiHelper.doRequest(`${API_RCA_URL}/${id}/create/`, 'post');
};

export const deny = async (id: number) => {
    return await ApiHelper.doRequest(`${API_RCA_URL}/${id}/deny/`, 'post');
};

export const createNew = async (request: RcaRequest) => {
    return await ApiHelper.doRequest(`${API_RCA_URL}/`, 'post', {...request});
};

function prepareFilter(filter: RcaFilter) {
    const fields = filter.ordering.split(',');

    // For approver and submitter we should split one field with tull name to two field last name and first name
    // TODO: Better to add custom ordering filter on backend part, that will order by submitter_user and aprover_user
    return {
        ...filter,
        ordering: fields.map(field => {
            let userFields = ['submitter_user', 'approver_user'];
            userFields = userFields.concat(userFields.map(fld => `-${fld}`));
            if (userFields.indexOf(field) >= 0) {
                return `${field}__last_name,${field}__first_name`;
            }
            return field;
        }).join(',')
    };
}