import MergeEntity from './mergeEntity';
import { GET_MERGE_ENTITIES, SET_MERGE_ENTITIES, MERGE_ENTITIES, BULK_MERGE_ENTITIES } from './actions';
import { FiltersState } from 'store/Filters/types';

export const getMergeEntities = (filter: FiltersState) => ({
    type: GET_MERGE_ENTITIES,
    filter
});

export const setMergeEntities = (entities: MergeEntity[], count: number) => ({
    type: SET_MERGE_ENTITIES,
    entities,
    count,
});

export const mergeEntities = (mergingEntityRowId: string) => ({
    type: MERGE_ENTITIES,
    mergingEntityRowId,
});

export const bulkMergeEntities = (filter: FiltersState) => ({
    type: BULK_MERGE_ENTITIES,
    filter
});
