import { Action } from 'redux';
import { ModalParams } from './types';

export const OPEN_MODAL = 'store/modal/open';
export const CLOSE_MODAL = 'store/modal/close';

export interface OpenAction extends Action {
    name: string;
    params: ModalParams;
}

export interface CloseAction extends Action {

}

export type ModalsAction = OpenAction | CloseAction;
