import * as React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { HeaderSettings, TableHeader } from 'store/types';
import { colors } from '../../../scss/constants';

interface Props {
    headers: HeaderSettings[];
    handleChange?: (event: any) => void;
}

interface State {
    allHeaders: TableHeader[];
    activeHeaders: number[];
    waitForValues: boolean;
}

const styles = require('./ShowTablesPanel.scss');

class ShowTablesPanel extends React.PureComponent<Props, State> {
    state: State = {
        allHeaders: [],
        activeHeaders: [],
        waitForValues: true
    };

    componentWillReceiveProps() {
            const headers = this.props.headers;

            const allFields = headers.map(function(item: HeaderSettings, index: number) {
                return { index, active: item.active };
            });

            const activeFields = allFields.filter((item: any) => {
                return item.active;
            });

            const currentHeadersForState = activeFields.map((item: any) => {
                return item.index;
            });

            const allHeadersForState = headers.map((item: HeaderSettings, index: number) => {
                return {index: index, title: item.title, field: item.name};
            });

            if (this.state.waitForValues && currentHeadersForState.length > 0) {
                this.setState({
                    allHeaders: allHeadersForState,
                    activeHeaders: currentHeadersForState,
                    waitForValues: false
                });
            }
    }

    handleChange = (event: any, index: any, values: any) => {
        const localAllHeaders = this.state.allHeaders;
        this.setState({activeHeaders: values}, () => {
            if (this.props.handleChange && this.props.headers) {

                const headers = this.props.headers;

                const newHeaders = headers.map(function(header: any) {
                    let isActive = false;
                    const currentHeader = localAllHeaders.find((generalHeader: TableHeader) => {
                        return generalHeader.field === header.name;
                    });
                    if (currentHeader && values.indexOf(currentHeader.index) > -1) {
                        isActive = true;
                    }
                    header.active = isActive;
                    return header;
                });
                this.props.handleChange(newHeaders);
            }
        });
    }

    selectionRenderer = (values: any) => {
        switch (values.length) {
            case 0:
                return 'Show/hide columns';
            case 1:
                return 'Show/hide columns';
            default:
                return 'Show/hide columns';
        }
    }

    menuItems(items: any) {
        return items.map((item: any) => (
            <MenuItem
                key={item.index}
                insetChildren={true}
                checked={this.state.activeHeaders && this.state.activeHeaders.indexOf(item.index) > -1}
                value={item.index}
                primaryText={item.title}
            />
        ));
    }

    render () {
        return (
            <MuiThemeProvider>
                <div className={`col-md-3`}>
                    <SelectField
                        multiple={true}
                        hintText="Show/hide columns"
                        value={this.state.activeHeaders}
                        onChange={this.handleChange}
                        selectionRenderer={this.selectionRenderer}
                        style={
                            {
                                background: 'white',
                                marginTop: '15px',
                                height: '38px',
                                textAlign: 'center',
                                border: '1px solid #ced4da',
                                borderRadius: '0.25rem',
                                color: 'green',
                            }
                        }
                        labelStyle={
                            {
                                height: '40px',
                                lineHeight: '44px',
                                color: '#6b757d',
                            }
                        }
                        iconStyle={
                            {
                                marginTop: '-7px',
                                fill: '#999',
                            }
                        }
                        selectedMenuItemStyle={
                            {
                                color: colors.blue,
                            }
                        }
                        hintStyle={
                            {
                                marginLeft: '30px',
                                bottom: '6px',
                            }
                        }
                        underlineStyle={{display: 'none'}}
                    >
                        {this.menuItems(this.state.allHeaders)}
                    </SelectField>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default ShowTablesPanel;
