export const PAGE_SOURCE = {
    detail: 'detail',
    list: 'list'
};

export const DEFAULT_ITEMS_PER_PAGE = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE || '20', 10);
export const MAX_DISPLAY_PAGES = 5;
export const SEARCH_DEBOUNCE_INTERVAL = 500;

export const DEFAULT_FILTER = {
    status: '',
    search: '',
    ordering: '-created_at',
    created_at_lte: null,
    created_at_gte: null,
    updated_at_lte: null,
    updated_at_gte: null,
    created_at: null,
    updated_at: null,
    limit: DEFAULT_ITEMS_PER_PAGE,
    offset: 0,
};

export const RESET_OFFSET = {
    offset: 0,
};

export const defaultSortPagination = {
    currentPage: 0,
    ordering: 'created_at',
    orderSign: 'desc',
};

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const FULL_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const TABLE_COLUMN_DATE_FORMAT = 'DD MMM YYYY';
export const TABLE_COLUMN_DATETIME_FORMAT = 'DD MMM YYYY HH:mm:ss';

export const DefaultStateErmEntity = {
        ...defaultSortPagination,
        filter: {
            ...DEFAULT_FILTER,
            entity_type: ''
        },
        headerType: 'erm'
};

export const DefaultStateErmRelationship = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        entity_type: ''
    },
    headerType: 'ermRelationships'
};

export const DefaultStateErmAuditTrail = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        entity_type: ''
    },
    headerType: 'auditTrail'
};

export const DefaultStateErmFlaggedEntities = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        entity_type: ''
    },
    headerType: 'ermFlaggedEntities'
};

export const DefaultStateErmMergeEntities = {
    ...defaultSortPagination,
    filter: {
        main_entity: '',
        merging_entity: '',
        ordering: '-id',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
    },
    headerType: 'ermMergeEntities'
};

export const DefaultStateErmFlaggedRelationships = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        entity_type: ''
    },
    headerType: 'ermFlaggedRelationships'
};

export const DefaultEdgarArchivesState = {
    currentPage: 0,
    ordering: 'date',
    orderSign: 'desc',
    filter: {
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        ordering: '-date',
        year: '',
    },
    headerType: 'edgarArchives',
};

export const DefaultEdgarFilingEntityListState = {
    currentPage: 0,
    ordering: 'filing_date',
    orderSign: 'desc',
    filter: {
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        ordering: '-filing_date',
    },
    headerType: 'edgarFilingEntities',
};

export const ErmRelationshipRecordsActions = {
    create_new_relationship: 'CreateNewRelationship',
};

export const ErmRelationshipsActions = {
    edit: 'EditRelationship',
    'delete': 'DeleteRelationship',
};

export const ErmFlaggedEntitiesActions = {
    associate_to_existing: 'Associate to existing entity',
    create_new_entity: 'CreateNewEntity',
    reprocess_message: 'Reprocess message',
    delete_messages: 'DeleteFlaggedEntities',
    view_message: 'ViewFlaggedEntityMessage',
    assign_messages: 'AssignFlaggedEntities',
    reprocess_messages: 'ReprocessFlaggedEntities',
    bulk_reprocess_entities: 'BulkReprocessEntities'
};

export const ErmMergeEntitiesActions = {
    merge: 'Merge',
    force_merge: 'Force Merge'
};

export const ErmFlaggedRelationshipsActions = {
    view_message: 'ViewFlaggedRelationshipMessage',
    reprocess_message: 'ReprocessMessageFlaggedRelationships',
    modify_message: 'ModifyMessageFlaggedRelationships',
    assign_to: 'AssignToFlaggedRelationships',
    suppress_message: 'SuppressMessageFlaggedRelationships',
    delete_messages: 'DeleteFlaggedRelationships',
    reprocess_messages: 'ReprocessFlaggedRelationships',
    assign_messages: 'AssignFlaggedRelationships',
    bulk_reprocess_relationship: 'BulkReprocessRelationship'
};
