import * as React from 'react';

import { AuthState } from './Auth/types';
import { ActionsState } from './Actions/types';
import { RcaState } from './Rca/types';
import { HeadersState } from './VisibleHeaders/types';
import { ModalState } from './Modal/types';
import { FiltersState } from './Filters/types';
import { UsersState } from './Users/types';
import { GlobalModalState } from './GlobalModal/types';
import { EdgarState } from './Edgar/types';
import { ErmState } from './Erm/types';

export interface AppState {
    actions?: ActionsState;
    auth?: AuthState;
    headers?: HeadersState;
    modal?: ModalState;
    rca?: RcaState;
    filters?: FiltersState;
    users?: UsersState;
    globalModal?: GlobalModalState;
    erm?: ErmState;
    edgar?: EdgarState;
}

export interface NavigationButton {
    route: string;
    title: string;
    icon?: string;
    exact?: boolean;
    children?: NavigationButton[];
}

export type OptionValue = string;
export interface SelectItem {
    label: string;
    value: OptionValue;
}

export interface ApiDataProps {
    header: HeaderSettings[];
    getData: any;
    results: any[];
    count: number;
    fields: object[];
    setHeaders: any;
    filters: any[];

    [other: string]: any;
}

export interface PropsListComponent {
    id: any;
    header: HeaderSettings[];
    handlePageChange: any;
    handleChange: any;
    handleColumns: any;
    results: any[];
    itemRelationships: any;
    count: number;
    setHeaders: any;
    ordering: any;
    orderSign: any;
    currentPage: any;
    filter: any;
    filters: any[];
    currentFilter: any;
    setGlobalModal?: any;
    actions?: any;
    handleAction?: any;
    chooseRow?: any;
    chooseAll?: any;
    checkedIds?: any;
    openModalWithText: any;
    handleCloseModal: any;
    multiActions: any;
    getData: any;
    actionArray?: any;
    setFiltersItems?: any;
    getFiltersItems?: any;
    filtersItems?: any;
    match: {
        isExact?: boolean;
        params?: any;
        path?: any;
        url?: string;
    };
}

export interface HeaderSettings {
    name: string;
    title: string;
    active: boolean;
    valueType?: ValueTypeChoices;
    cellType?: CellTypeChoices;
    linkTemplate?: string;
    filterType?: string;
    transformer?: (row: any) => string | JSX.Element | JSX.Element[];
    excludeSorting?: boolean;
    orderingName?: string;
}

export enum FilterType {
    Input = 'INPUT',
    InputWithoutStoredValue = 'INPUT_WITHOUT_STORED_VALUE',
    InputWithCount = 'INPUT_WITH_COUNT',
    Select = 'SELECT',
    Date = 'DATE',
    CustomContent = 'CUSTOM_CONTENT',
    Ordering = 'ORDERING'
}

export interface FilterItem {
    name: string;
    type: FilterType;
    title?: string;
    choices?: SelectItem[];
    labelKey?: string;
    valueKey?: string;
    className?: string;
    content?: React.ReactNode;
    clearable?: boolean;
    valueDecorator?: (value: string) => string;
    placeholder?: string;
    disabled?: boolean;
    defaultValue?: string | SelectItem;
}

export interface TableHeader {
    index: number;
    title: string;
    field: string;
}

export interface Located {
    location: any;
}

export interface ApiDataPropertiesProps {
    location: any;
    item: any;
    header: object[];
    datasourceHeaders: any;
    itemAuditTrail: any;
    match: {
        isExact?: boolean;
        params?: any;
        path?: any;
        url?: string;
    };
    getDataProperties: any;
    updateEntityProperties: any;
    useOtherNameAsLegal: (entityId: number, otherNameId: number) => void;
    updateEntityOtherName: (entityId: number, otherNameId: number, updateData: object) => void;
    properties: any;
    getData: any;
    handleAction: any;
    propagateEntity: (entityId: string) => void;
}

export enum ValueTypeChoices {
    String = 'STRING',
    StringOrNone = 'STRING_OR_NONE',
    Boolean = 'BOOLEAN',
    DateTime = 'DATETIME',
    Date = 'DATE',
    ContainsUnderscore = 'CONTAINS_UNDERSCORE',
    ToUppercase = 'TO_UPPERCASE',
}

export enum CellTypeChoices {
    Text = 'TEXT',
    Link = 'LINK',
    ModalWithText = 'MODAL_WITH_TEXT',
    SelectAction = 'SELECT_ACTION',
    Custom = 'CUSTOM',
    FilterItems = 'FILTER_ITEMS'
}

export enum FiltersItems {
    Users = 'USERS'
}

export enum BooleanFieldText {
    Yes = 'Yes',
    No = 'No',
}

export const userRoles = {
    global_role: [
        'sysadmin', 'concierge'
    ]
};

export interface EntityOtherName {
    data_source?: string;
    valid_from?: string | null;
    valid_to?: string | null;
    other_name?: string;
    ignore_matching?: boolean;
    is_valid?: boolean;
}