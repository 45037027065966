import * as React from 'react';
import Alert from 'reactstrap/lib/Alert';

export const VALIDATION_TYPES = {
    required: 'required',
    min_len: 'min_len'
};

export const validation = {
    required: { message: 'The field is required', type: 'required'},
    minLen: { message: 'This filed is minimal', type: 'min_len'}
};

export default function Validation(WrappedComponent: any) {

    return class extends React.PureComponent<any> {

        state = {
            errors: [],
        };

        validator = async (fields: any, data: any) => {
            let errors: any = [];
            for (let field in fields) {
                if (fields[field]) {
                    fields[field].forEach((item: any) => {
                        if (item === 'required') {
                            if (data[field].length <= 0) {
                                errors.push({
                                    field: field,
                                    message: validation.required.message
                                });
                            }
                        }
                    });
                }
            }
            this.setState({ errors: errors});
        }

        render() {
            let showErrors: any = '';

            if (this.state.errors.length > 0) {
                showErrors = this.state.errors.map((item: any, idx) => {
                    return (<Alert color="danger" key={idx}><b>{item.field}:</b> {item.message}</Alert>);
                });
            }
            return (
                <div>
                    <WrappedComponent
                        {...this.state}
                        {...this.props}
                        validator={this.validator}
                    />
                    <div className="col-md-12">
                        {showErrors}
                    </div>
                </div>
            );
        }

    };
}