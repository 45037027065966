import * as React from 'react';
import { Pagination as ReactPagination, PaginationItem, PaginationLink } from 'reactstrap';
import { DEFAULT_ITEMS_PER_PAGE, MAX_DISPLAY_PAGES } from '../../../constants';

interface PaginationProps {
    count?: number;
    perPage?: number;
    maxDisplayPages?: number;
    handleClick: (limit: number, offset: number) => void;
    currentPage?: number;
}

interface State {
    currentPage: number;
}

interface DefaultProps {
    count: number;
    perPage: number;
    maxDisplayPages: number;
    currentPage: number;
}

type PropsWithDefaults = PaginationProps & DefaultProps;

class Pagination extends React.PureComponent<PaginationProps, State> {
    public static defaultProps: DefaultProps = {
        perPage: DEFAULT_ITEMS_PER_PAGE,
        maxDisplayPages: MAX_DISPLAY_PAGES,
        currentPage: 1,
        count: 0,
    };

    constructor(props: PaginationProps) {
        super(props);
        this.state = {
            currentPage: 1,
        };
    }

    componentWillReceiveProps(nextProps: PaginationProps) {
        const currentPage = this.props.currentPage;
        const nextCurrentPage = nextProps.currentPage;
        if (nextCurrentPage !== currentPage && nextCurrentPage) {
            this.setState({currentPage: nextCurrentPage});
        }
    }

    choosePage(page: number) {
        this.setState({currentPage: page}, () => {
            this.processPagination();
        });
    }

    processPagination() {
        const { handleClick, perPage } = this.props as PropsWithDefaults;
        const limit = perPage;
        const offset = perPage * (this.state.currentPage - 1);
        if (handleClick) {
            handleClick(limit, offset);
        }
    }

    nextButtonClick = () => {
        const { count, perPage } = this.props as PropsWithDefaults;
        const totalPages = Math.ceil(count / perPage) || 1;
        if (this.state.currentPage < totalPages) {
            this.setState({currentPage: this.state.currentPage + 1}, () => {
                    this.processPagination();
                }
            );
        }
    }

    previousButtonClick = () => {
        if (this.state.currentPage > 1) {
            this.setState({currentPage: this.state.currentPage - 1}, () => {
                this.processPagination();
            });
        }
    }

    firstButtonClick = () => {
        this.setState({currentPage: 1}, () => {
            this.processPagination();
        });
    }

    lastButtonClick = () => {
        const { count, perPage } = this.props as PropsWithDefaults;
        const totalPages = Math.ceil(count / perPage);
        this.setState({currentPage: totalPages}, () => {
            this.processPagination();
        });
    }

    render() {
        const { maxDisplayPages, count, perPage } = this.props as PropsWithDefaults;
        const totalPages = Math.ceil(count / perPage);
        const maxPageButtons = Math.min(maxDisplayPages, totalPages);
        const pageSet = Math.ceil(this.state.currentPage / maxPageButtons);
        const maxPageSet = Math.ceil(totalPages / maxPageButtons);
        const start = pageSet === maxPageSet
            ? (totalPages - maxPageButtons) + 1
            : ((pageSet * maxPageButtons) - (maxPageButtons - 1));
        let pages = [];
        for (let i = start; i < maxPageButtons + start; i++) {
            pages.push(i);
        }

        return (
            <div>
            {totalPages > 1 &&
                <ReactPagination>
                    <PaginationItem disabled={this.state.currentPage === 1}>
                        <PaginationLink href="#" onClick={this.firstButtonClick}>
                            &lt;&lt;
                        </PaginationLink>
                    </PaginationItem>

                    <PaginationItem disabled={this.state.currentPage === 1}>
                        <PaginationLink href="#" onClick={this.previousButtonClick}>
                            &lt;
                        </PaginationLink>
                    </PaginationItem>

                    {pages.map((row, idx) => (
                        <PaginationItem key={idx} active={row === this.state.currentPage}>
                            <PaginationLink href="#" onClick={() => this.choosePage(row)}>
                                {row}
                            </PaginationLink>
                        </PaginationItem>
                    ))}

                    <PaginationItem disabled={this.state.currentPage === totalPages}>
                        <PaginationLink next={true} href="#" onClick={this.nextButtonClick}>
                            &gt;
                        </PaginationLink>
                    </PaginationItem>

                    <PaginationItem disabled={this.state.currentPage === totalPages}>
                        <PaginationLink next={true} href="#" onClick={this.lastButtonClick}>
                            &gt;&gt;
                        </PaginationLink>
                    </PaginationItem>
                </ReactPagination>
            }
            </div>
        );
    }
}

export default Pagination;
