import { connect } from 'react-redux';

import ConflictAndAmbiguousActionsWrapper
    from 'components/Erm/Actions/FlaggedEntitiesActions/ConflictAndAmbiguousActionsWrapper';
import { getErmEntities, getErmEntitiesCount } from 'store/Erm/selectors';
import { getEntities, setEntities } from 'store/Erm/Entity/actionCreators';
import { setCloseGlobalModal } from 'store/GlobalModal/actionCreators';
import { associateToExistingEntity, resolveConflictEntity } from 'store/Erm/FlaggedEntity/actionCreators';
import { getGlobalModalPayload } from 'store/GlobalModal/selectors';
import { getHeaders } from 'store/VisibleHeaders/selectors';
import { AppState } from 'store/types';

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {
        results: getErmEntities(state),
        count: getErmEntitiesCount(state),
        header: getHeaders(state, {name: 'associateToExisting'}),
        payload: getGlobalModalPayload(state)
    };

};

const mapDispatchToProps = {
    getEntities,
    setEntities,
    closeModal: setCloseGlobalModal,
    associateAndResolveEntity: associateToExistingEntity,
    resolveConflicts: resolveConflictEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConflictAndAmbiguousActionsWrapper);
