import { put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import * as rcaApi from '../../services/rca';
import {
    APPROVE_RCA, ApproveAction, CREATE_RCA, CREATE_NEW_RCA, CreateNewAction, DENY_RCA, GET_RCA_ITEMS,
    GetItemsAction
} from './actions';
import { doneActionFail, doneActionSuccess, initAction } from '../Actions/actionCreators';
import { refreshData, setItems } from './actionCreators';
import { messages } from './constants';
import { closeModal } from '../Modal/actionCreators';

function* getItems(action: GetItemsAction) {
    try {
        yield put(initAction(action.type));
        const items = yield rcaApi.getList(action.filter);
        yield put(setItems(items));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* approve(action: ApproveAction) {
    try {
        yield put(initAction(action.type));
        yield rcaApi.approve(action.id);
        yield put(doneActionSuccess(action.type, messages.MESSAGE_RCA_APPROVE_SUCCESS));

        yield postStatusChange(action.closeModal);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.MESSAGE_RCA_APPROVE_FAILED));
    }
}

function* deny(action: ApproveAction) {
    try {
        yield put(initAction(action.type));
        yield rcaApi.deny(action.id);
        yield put(doneActionSuccess(action.type, messages.MESSAGE_RCA_DENY_SUCCESS));

        yield postStatusChange(action.closeModal);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.MESSAGE_RCA_DENY_FAILED));
    }
}

function* create(action: ApproveAction) {
    try {
        yield put(initAction(action.type));
        yield rcaApi.create(action.id);
        yield put(doneActionSuccess(action.type, messages.MESSAGE_RCA_CREATE_SUCCESS));

        yield postStatusChange(action.closeModal);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.MESSAGE_RCA_CREATE_FAILED));
    }
}

function* createNew(action: CreateNewAction) {
    try {
        yield put(initAction(action.type));
        yield rcaApi.createNew(action.request);
        yield put(doneActionSuccess(action.type, messages.MESSAGE_RCA_CREATE_NEW_SUCCESS));

        yield postStatusChange(action.closeModal);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.MESSAGE_RCA_CREATE_NEW_FAILED));
    }
}

function* watchGetItems() {
    yield takeLatest(GET_RCA_ITEMS, getItems);
}

function* watchApprove() {
    yield takeEvery(APPROVE_RCA, approve);
}

function* watchDeny() {
    yield takeEvery(DENY_RCA, deny);
}

function* watchCreate() {
    yield takeEvery(CREATE_RCA, create);
}

function* watchCreateNew() {
    yield takeEvery(CREATE_NEW_RCA, createNew);
}

export default function* root() {
    yield all([
        watchGetItems(), watchApprove(), watchDeny(), watchCreate(), watchCreateNew()
    ]);
}

function* postStatusChange(close: boolean) {
    yield put(refreshData());
    if (close) {
        yield put(closeModal());
    }
}