import * as React from 'react';
import { Card, CardHeader, CardText, MenuItem, SelectField } from 'material-ui';
import Modal from '../Shared/Modal/Modal';
import { SelectItem } from '../../store/types';
import { UpdateRequest } from '../../store/Users/models/updateRequest';

interface Props {
    isOpened: boolean;
    toggle: () => any;
    request: UpdateRequest;
    apply: () => any;
    change: (field: string, value: string) => void;
    globalRoles: SelectItem[];
}

const styles = require('./EditModal.scss');

const EditModal: React.FunctionComponent<Props> = ({isOpened, toggle, apply, change, globalRoles, request}) => {
    const buttons = [
        {color: 'success', title: 'Yes', onClick: apply},
        {color: 'danger', title: 'No', onClick: toggle},
    ];

    const body = (
        <div>
            <Card className={styles.card}>
                <CardHeader className={styles.cardHeader} title="User" />
                <CardText>
                    <SelectField
                        className={styles.input}
                        floatingLabelText="Global Role"
                        fullWidth={true}
                        value={request.global_role}
                        onChange={(event, index, value) => change('global_role', value)}
                    >
                        {globalRoles.map((type: SelectItem, idx: number) => (
                            <MenuItem key={idx} value={type.value} primaryText={type.label} />
                        ))}
                    </SelectField>
                </CardText>
            </Card>
        </div>
    );

    return (
        <Modal
            isOpened={isOpened}
            toggle={toggle}
            title="Edit user"
            body={body}
            buttons={buttons}
            size="md"
        />
    );
};

export default EditModal;