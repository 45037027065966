import { takeLatest, takeEvery, all, put } from 'redux-saga/effects';

import * as userApi from '../../services/user';
import {
    GET_USERS, GetItemsAction, LOCK_USER, LockAction, RESET_USER_ATTEMPTS, ResetAttemptsAction,
    UNLOCK_USER, UnlockAction, UPDATE_USER, UpdateAction
} from './actions';
import { doneActionFail, doneActionSuccess, initAction } from '../Actions/actionCreators';
import { refreshData, setItems } from './actionCreators';
import { closeModal } from '../Modal/actionCreators';
import { messages } from './constants';

function* getItems(action: GetItemsAction) {
    try {
        yield put(initAction(action.type));
        const items = yield userApi.getList(action.filter);
        yield put(setItems(items));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* update(action: UpdateAction) {
    try {
        yield put(initAction(action.type));
        yield userApi.update(action.id, action.request);
        yield put(doneActionSuccess(action.type, messages.MESSAGE_USERS_UPDATE_SUCCESS));

        yield postStatusChange(action.closeModal);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.MESSAGE_USERS_UPDATE_FAILED));
    }
}

function* lockUnlockResetAttempts(action: LockAction | UnlockAction | ResetAttemptsAction) {
    try {
        yield put(initAction(action.type));
        if (action.type === LOCK_USER) {
            yield userApi.lock(action.id);
        } else if (action.type === UNLOCK_USER) {
            yield userApi.unlock(action.id);
        } else if (action.type === RESET_USER_ATTEMPTS) {
            yield userApi.resetAttempts(action.id);
        }
        yield put(doneActionSuccess(action.type, messages.MESSAGE_USERS_UPDATE_SUCCESS));

        yield postStatusChange(action.closeModal);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.MESSAGE_USERS_UPDATE_FAILED));
    }
}

function* watchGetItems() {
    yield takeLatest(GET_USERS, getItems);
}

function* watchUpdate() {
    yield takeEvery(UPDATE_USER, update);
}

function* watchLock() {
    yield takeEvery(LOCK_USER, lockUnlockResetAttempts);
}

function* watchUnlock() {
    yield takeEvery(UNLOCK_USER, lockUnlockResetAttempts);
}

function* watchResetAttempts() {
    yield takeEvery(RESET_USER_ATTEMPTS, lockUnlockResetAttempts);
}

export default function* root() {
    yield all([
        watchGetItems(), watchUpdate(), watchLock(), watchUnlock(), watchResetAttempts()
    ]);
}

function* postStatusChange(close: boolean) {
    yield put(refreshData());
    if (close) {
        yield put(closeModal());
    }
}