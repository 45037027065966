import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ViewMessage from '../../../../components/Erm/Actions/FlaggedEntitiesActions/ViewMessage';

const mapStateToProps = (state: any, ownProps: any) => {
    const modal = state.globalModal;
    return {
        payload: modal && modal.payload ? modal.payload : {},
    };

};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {},
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewMessage);
