import * as React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import { colors } from 'scss/constants';
import { ModalDeleteAction, SetCloseGlobalModalAction } from 'store/GlobalModal/actions';
import { ModalPayload } from 'store/GlobalModal/types';

interface Props {
    modalPayload: ModalPayload;
    closeModal: () => SetCloseGlobalModalAction;
    deleteAction: (modalPayload: ModalPayload) => ModalDeleteAction;
    text: string;
}

const styles = require('./DeleteModal.scss');

class DeleteModal extends React.PureComponent<Props> {

    handleCancel = () => {
        this.props.closeModal();
    }

    handleDelete = () => {
        this.props.deleteAction(this.props.modalPayload);
    }

    render() {
        return (
            <div>
                <h4 className={styles.text}>
                    {this.props.text}
                </h4>
                <MuiThemeProvider>
                    <div>
                        <RaisedButton
                            label="Yes"
                            buttonStyle={{backgroundColor: colors.blue}}
                            labelColor={colors.white}
                            onClick={this.handleDelete}
                        />
                        <RaisedButton
                            label="No"
                            className={styles['cancel-button']}
                            onClick={this.handleCancel}
                        />
                    </div>
                </MuiThemeProvider>
            </div>
        );
    }
}

export default DeleteModal;
