import { Action } from 'redux';
import { Archive, DFormData, FilingEntity, NCENFormData } from './types';
import { FiltersState } from 'store/Filters/types';

export const GET_ARCHIVE_LIST = 'store/edgar/archives/getList';
export const SET_ARCHIVE_LIST = 'store/edgar/archives/setList';
export const INIT_REDOWNLOADING_THE_ARCHIVE = 'store/edgar/archives/initRedownloading';

export const GET_FILING_ENTITY_LIST = 'store/edgar/filingEntities/getList';
export const SET_FILING_ENTITY_LIST = 'store/edgar/filingEntities/setList';

export const GET_NCEN_FROM_DATA = 'store/edgar/filingEntities/ncen/getFormData';
export const SET_NCEN_FROM_DATA = 'store/edgar/filingEntities/ncen/setFormData';
export const EXPORT_FORMS = 'store/edgar/filingEntities/forms/export';

export const GET_D_FROM_DATA = 'store/edgar/filingEntities/d/getFormData';
export const SET_D_FROM_DATA = 'store/edgar/filingEntities/d/setFormData';

export interface GetArchiveListAction extends Action {
    filter: FiltersState;
}

export interface SetArchiveListAction extends Action {
    archives: Archive[];
    count: number;
}

export interface InitRedownloadingAction extends Action {
    archiveId: number;
}

export interface GetFilingEntityListAction extends Action {
    filter: FiltersState;
}

export interface SetFilingEntityListAction extends Action {
    filingEntities: FilingEntity[];
    count: number;
}

export interface ExportFormsAction extends Action {
    filter: FiltersState;
}

export interface GetNCENFormDataAction extends Action {
    id: string;
}

export interface SetNCENFormDataAction extends Action {
    filingEntityInfo: Partial<FilingEntity> | null;
    ncenForm: NCENFormData | null;
}

export interface GetDFormDataAction extends Action {
    id: string;
}

export interface SetDFormDataAction extends Action {
    filingEntityInfo: Partial<FilingEntity> | null;
    dForm: DFormData | null;
}

export type EdgarAction =
    SetArchiveListAction |
    SetFilingEntityListAction |
    SetNCENFormDataAction |
    SetDFormDataAction
    ;