import { FULL_DATE_FORMAT } from '../constants';
import * as moment from 'moment';

class FormHelper {
        static setFormatDateTime(field?: any) {
            return  field ? field.format(FULL_DATE_FORMAT) : null;
        }

        static getDataFromSelect (field?: any) {
            return typeof field === 'string' ? field : field.value;
        }

        static setMomentOrNull (field: any) {
            return field ? moment(field) : null;
        }
}

export default FormHelper;