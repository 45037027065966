import * as React from 'react';
import Select from 'components/Shared/ui/Select';
import { FilterType, SelectItem } from 'store/types';
import { FilterDecoratorChange } from 'decorators/FilterDecorator';

interface Props {
    name: string;
    placeholder?: string;
    value: any;
    options?: SelectItem[];
    valueKey?: string;
    labelKey?: string;
    disabled?: boolean;
    defaultValue?: any;
    label?: string;
    material?: boolean;
    handleChange: FilterDecoratorChange;
    clearable?: boolean;
}

const styles = require('./SelectFilter.scss');
const SelectFilter: React.FC<Props> = ({
   name, value, placeholder, disabled, defaultValue, label, options, handleChange, clearable, valueKey, labelKey
}) => (
    <div className={styles.select_filter}>
        {label && <h5 className={styles.label}>{label}</h5>}
        <Select
            placeholder={placeholder || ''}
            value={value}
            disabled={disabled}
            defaultValue={defaultValue}
            handleChange={(values: any) => {
                handleChange(name, FilterType.Select, values ? valueKey ? values[valueKey] : values.value : '');
            }}
            options={options || []}
            clearable={clearable}
            valueKey={valueKey}
            labelKey={labelKey}
        />
    </div>
);

export default SelectFilter;
