import * as React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { colors } from '../../../../scss/constants';

interface Props {
    payload: any;
    closeModal: any;
    setTitle: (title: string) => void;
    deleteEntities: any;
}

const styles = require('./SuppressEntities.scss');

class SuppressEntities extends React.PureComponent<Props> {
    render() {
        let message;
        if (this.props.payload.payload.messageIds.length > 1) {
            message = `${this.props.payload.payload.messageIds.length} messages`;
        } else {
            message = 'this message';
        }
        return (
            <div>
                <h4 className={styles.suppress_entities}>Are you sure that you're going to suppress {message}?</h4>
                <MuiThemeProvider>
                    <div>
                        <RaisedButton
                            label="Yes"
                            buttonStyle={{backgroundColor: colors.blue}}
                            labelColor="#ffffff"
                            onClick={() => this.props.deleteEntities({...this.props.payload.payload})}
                        />
                        <RaisedButton
                            label="No"
                            style={{float: 'right'}}
                            onClick={() => this.props.closeModal()}
                        />
                    </div>
                </MuiThemeProvider>
            </div>
        );
    }
}

export default SuppressEntities;
