import { Relationship } from './types';
import {
    GET_RELATIONSHIPS, GET_RELATIONSHIP, SET_RELATIONSHIPS, SET_RELATIONSHIP,
    CREATE_RELATIONSHIP, EDIT_RELATIONSHIP, DELETE_RELATIONSHIP
} from './actions';
import { DefaultStateErmRelationship } from '../../../constants';
import { ModalPayload } from '../../GlobalModal/types';

export const getRelationships = (filter: any = DefaultStateErmRelationship.filter) => ({
    type: GET_RELATIONSHIPS,
    filter,
});

export const getRelationship = (id: any) => ({
    type: GET_RELATIONSHIP,
    id
});

export const setRelationship = (relationship: any) => ({
    type: SET_RELATIONSHIP,
    relationship
});

export const setRelationships = (relationship: Relationship) => ({
    type: SET_RELATIONSHIPS,
    relationship
});

export const createRelationship = (payload: any, entityId?: string) => ({
    type: CREATE_RELATIONSHIP,
    payload,
    entityId,
});

export const editRelationship = (payload: any) => ({
    type: EDIT_RELATIONSHIP,
    payload
});

export const deleteRelationship = (modalPayload: ModalPayload) => ({
    type: DELETE_RELATIONSHIP,
    modalPayload,
});