import { AuditTrail } from './entity';
import { GET_AUDIT_TRAIL, SET_AUDIT_TRAIL } from './actions';

export const getAuditTrail = (filter: any) => ({
    type: GET_AUDIT_TRAIL,
    filter
});

export const setAuditTrail = (auditTrail: AuditTrail) => ({
    type: SET_AUDIT_TRAIL,
    auditTrail
});
