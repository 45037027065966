import { AppState } from '../types';

export const getFilters = (state: AppState, filterName: string): any => {
    if (state.filters && state.filters[filterName]) {
        return state.filters[filterName];
    }
    return {};
};
export const getFiltersItemsSelector = (state: AppState, filterItemName: string): any => {
    if (state.filters && state.filters.filter_items && state.filters.filter_items[filterItemName]) {
        return state.filters.filter_items[filterItemName];
    }
    return {};
};