import * as React from 'react';
import PrettifiedJSON from '../../../Shared/ModalContent/PrettifiedJSON';

interface Props {
    payload: any;
    closeModal: any;
    setTitle: (title: string) => void;
}

class ViewMessage extends React.PureComponent<Props> {

    componentDidMount() {
        this.props.setTitle('Message Content');
    }

    render() {
        const payload = this.props.payload.row ? this.props.payload.row : {};
        const eventMessage = payload && payload.event_message ? payload.event_message : {};
        return (
            <div>
                <PrettifiedJSON text={eventMessage} />
            </div>
        );
    }
}

export default ViewMessage;
