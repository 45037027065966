import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Relationship from 'components/Erm/Actions/RelationshipsActions/Relationship';
import { setGlobalModal } from 'store/GlobalModal/actionCreators';
import { editRelationship } from 'store/Erm/Relationship/actionCreators';
import { getGlobalModalPayload } from 'store/GlobalModal/selectors';

const mapStateToProps = (state: any) => {

    const modalPayload = getGlobalModalPayload(state);
    const { payload } = modalPayload;

    return {
        item: payload.row,
        pageSource: payload.pageSource,
        id: payload.id
    };

};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
    return bindActionCreators(
        {
            setGlobalModal,
            editRelationship
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Relationship);