import { put, all, takeEvery, select } from 'redux-saga/effects';

import { doneActionFail, doneActionSuccess, initAction } from '../Actions/actionCreators';
import { CALL_APPLY_FILTERS, CallApplyFiltersAction, GET_FILTERS_ITEMS, SetFiltersItemsAction } from './actions';
import * as ermApi from '../../services/erm';
import { setFiltersItems } from './actionCreators';
import { RESET_OFFSET } from '../../constants';

function* callApplyFilters(action: CallApplyFiltersAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        const filter = store.filters[action.currentFilter];
        yield put({ type: action.actionType, filter: { ...filter, ...RESET_OFFSET, ...action.otherFilter}});
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getFilterItems(action: SetFiltersItemsAction) {
    try {
        yield put(initAction(action.type));
        const items = yield ermApi.getAssignToItems(action.payload);

        yield put(setFiltersItems(action.filterItemName, items.results));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchCallApplyFilters() {
    yield takeEvery(CALL_APPLY_FILTERS, callApplyFilters);
}

function* watchSetFilterItems() {
    yield takeEvery(GET_FILTERS_ITEMS, getFilterItems);
}

export default function* root() {
    yield all(
        [
            watchCallApplyFilters(),
            watchSetFilterItems()
        ]
    );
}