import { Action } from 'redux';
import { GetListResponse, UsersFilter } from './types';
import { UpdateRequest } from './models/updateRequest';

export const GET_USERS = 'store/users/getItems';
export const SET_USERS = 'store/users/setItems';
export const REFRESH_USERS_DATA = 'store/users/refreshData';
export const UPDATE_USER = 'store/users/update';
export const LOCK_USER = 'store/users/lock';
export const UNLOCK_USER = 'store/users/unlock';
export const RESET_USER_ATTEMPTS = 'store/users/resetAttempts';

export interface RefreshDataAction extends Action {
}

export interface GetItemsAction extends Action {
    filter: UsersFilter;
}

export interface SetItemsAction extends Action {
    list: GetListResponse;
}

export interface UpdateAction extends Action {
    id: number;
    request: UpdateRequest;
    closeModal: boolean;
}

export interface LockAction extends Action {
    id: number;
    closeModal: boolean;
}

export interface UnlockAction extends Action {
    id: number;
    closeModal: boolean;
}

export interface ResetAttemptsAction extends Action {
    id: number;
    closeModal: boolean;
}

export type UsersAction = RefreshDataAction | GetItemsAction | SetItemsAction | UpdateAction |
    LockAction | UnlockAction | ResetAttemptsAction;