import * as React from 'react';

interface Props {
    count?: number;
}

interface DefaultProps {
    count: number;
}

const CountData: React.FunctionComponent<Props> = ({ count }) => (
    <div className="d-inline-block">
        {count}
    </div>
);

const defaultProps: DefaultProps = {
    count: 0,
};

CountData.defaultProps = defaultProps;

export default CountData;