import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalWithText from '../../components/Modal/ModalWithText';
import { closeModal } from '../../store/Modal/actionCreators';
import { getModalParams, isModalOpen } from '../../store/Modal/selectors';

const modalName: string = 'ModalWithText';
const title: string = 'Event Data';

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        opened: isModalOpen(state, {name: modalName}),
        body: getModalParams(state),
        title,
    };

};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            handleCloseModal: closeModal,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalWithText);