import * as React from 'react';
import Select from 'react-select';
import { OptionValue, SelectItem } from 'store/types';
import { ActionMeta, ValueType } from 'react-select/lib/types';
import { Styles } from 'react-select/lib/styles';
import { CSSProperties } from 'react';

interface Props {
    placeholder: string;
    value: ValueType<OptionValue>;
    options: OptionItem[];
    handleChange: (newValue: ValueType<OptionItem>, actionMeta?: ActionMeta) => void;
    clearable?: boolean;
    disabled?: boolean;
    defaultValue?: OptionItem;
    multi?: boolean;
    valueKey?: string;
    labelKey?: string;
}

export type OptionItem = SelectItem | null;

class UiSelect extends React.PureComponent<Props> {

    static defaultProps = {
        multi: false,
        clearable: true,
        valueKey: 'value',
        labelKey: 'label',
    };

    render() {
        const {
            value, placeholder, options, handleChange, clearable, disabled, multi, defaultValue, valueKey
        } = this.props;

        const customStyles: Partial<Styles> = {
            placeholder: (provided: CSSProperties) => ({
                ...provided,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            })
        };

        return (
            <Select
                value={
                    !defaultValue &&
                    options.filter((option: SelectItem | null) => {
                        if (option) {
                            if (Array.isArray(value) && !!valueKey) {
                                return value.indexOf(option[valueKey]) !== -1;
                            } else if (!!valueKey) {
                                return option[valueKey] === value;
                            }
                        }
                        return option;
                    }) ||
                    undefined
                }
                getOptionLabel={this.getOptionLabel}
                getOptionValue={this.getOptionValue}
                isOptionSelected={this.isOptionSelected}
                onChange={handleChange}
                options={options}
                isMulti={multi}
                placeholder={placeholder}
                isDisabled={disabled}
                isClearable={clearable}
                delimiter=";"
                defaultValue={defaultValue}
                styles={customStyles || {}}
            />
        );
    }

    private getOptionLabel = (option: OptionItem) => {
        const { labelKey } = this.props;
        return option && !!labelKey && option[labelKey]
            || !!labelKey && this.props.defaultValue && this.props.defaultValue[labelKey] || '';
    }

    private getOptionValue = (option: OptionItem) => {
        const { valueKey } = this.props;
        return option && !!valueKey && option[valueKey]
            || !!valueKey && this.props.defaultValue && this.props.defaultValue[valueKey] || '';
    }

    private isOptionSelected = (option: OptionItem, selectValue: OptionItem[]) => {
        const { valueKey } = this.props;
        return !!option && !!selectValue.find(
            (item: OptionItem) => !!item && !!valueKey && option[valueKey] === item[valueKey]
        );
    }

}

export default UiSelect;

export const ReactSelectAdapter = ({ input, ...rest }: any) => {
    const { onChange, value, ...restInput} = input;
    const resultValue = value && value.value || value;
    return (
        <UiSelect {...restInput} {...rest} searchable={true} handleChange={onChange} value={resultValue}/>
    );
};