import { connect } from 'react-redux';

import { deleteDatasource } from 'store/Erm/Entity/actionCreators';
import { setCloseGlobalModal } from 'store/GlobalModal/actionCreators';
import DeleteModal from 'components/Erm/Actions/DeleteModal';
import { AppState } from 'store/types';

const mapStateToProps = (state: AppState) => {
    const modal = state.globalModal;
    return {
        modalPayload: modal && modal.payload ? modal.payload : {},
        text: `Are you sure that you're going to delete this datasource?`,
    };

};

const mapDispatchToProps = {
    deleteAction: deleteDatasource,
    closeModal: setCloseGlobalModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
