import { MergeEntityState } from './MergeEntity/types';

export enum DataSourceTypeEnum {
    ADV = 'adv',
    LEI = 'lei',
    EDGAR = 'edgar',
}

export enum DataSourceKeyTypeEnum {
    SEC = 'sec',
    CRD = 'crd',
    LEI = 'lei',
    TICKER = 'ticker',
    CIK = 'cik',
    SERIES_ID = 'series_id',
    CLASS_ID = 'class_id',
}

export interface ErmState {
    // TODO: use particular types for erm state
    entity: any;
    relationship: any;
    flaggedEntity: any;
    flaggedRelationship: any;
    auditTrail: any;
    mergeEntity: MergeEntityState;
}
