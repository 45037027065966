import * as dotenv from 'dotenv';
dotenv.config();

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// import registerServiceWorker from './registerServiceWorker';
import 'normalize.css/normalize.css?raw';
import 'bootstrap/dist/css/bootstrap.css?raw';
import 'react-dates/initialize?raw';
import 'react-dates/lib/css/_datepicker.css?raw';
import 'react-toastify/dist/ReactToastify.css?raw';

import './index.scss';
import App from './containers/App';

import configureStore from './store/configureStore';
const store = configureStore({});

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root') as HTMLElement
);
// registerServiceWorker();
