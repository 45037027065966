import { AuditTrailState } from './types';

import {
    GET_AUDIT_TRAIL, SET_AUDIT_TRAIL, AuditTrailAction, SetAuditTrailAction
} from './actions';

const initialState = {};

const reducer = (state: AuditTrailState = initialState, action: AuditTrailAction) => {
    switch (action.type) {
        case GET_AUDIT_TRAIL:
            return state;
        case SET_AUDIT_TRAIL:
            return {
                ...state,
                items: (<SetAuditTrailAction> action).auditTrail
            };
        default:
            return state;
    }
};

export default reducer;