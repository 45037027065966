import {
    GET_ARCHIVE_LIST, GET_FILING_ENTITY_LIST, EXPORT_FORMS, GET_NCEN_FROM_DATA, GET_D_FROM_DATA
} from 'store/Edgar/actions';
import {
    ADD_DATASOURCE, CREATE_DATA_SOURCES, CREATE_NEW_ENTITY, DELETE_DATASOURCE_BY_ID, EDIT_DATASOURCE,
    GET_ENTITIES, GET_ENTITY_PROPERTIES, UPDATE_ENTITY_PROPERTIES
} from 'store/Erm/Entity/actions';
import { GET_AUDIT_TRAIL } from 'store/Erm/AuditTrail/actions';
import {
    ASSIGN_FLAGGED_ENTITIES, ASSOCIATE_ENTITY, BULK_REPROCESS_FLAGGED_ENTITY, DELETE_FLAGGED_ENTITIES,
    GET_FLAGGED_ENTITIES, REPROCESS_FLAGGED_ENTITIES, RESOLVE_CONFLICT_ENTITY
} from 'store/Erm/FlaggedEntity/actions';
import {
    CREATE_RELATIONSHIP, DELETE_RELATIONSHIP, EDIT_RELATIONSHIP, GET_RELATIONSHIP, GET_RELATIONSHIPS
} from 'store/Erm/Relationship/actions';
import {
    ASSIGN_FLAGGED_RELATIONSHIPS, BULK_REPROCESS_FLAGGED_RELATIONSHIPS, DELETE_FLAGGED_RELATIONSHIPS,
    GET_FLAGGED_RELATIONSHIPS, MODIFY_FLAGGED_RELATIONSHIP, REPROCESS_FLAGGED_RELATIONSHIPS
} from 'store/Erm/FlaggedRelationship/actions';
import {
    GET_USERS, LOCK_USER, REFRESH_USERS_DATA, RESET_USER_ATTEMPTS, UNLOCK_USER,
    UPDATE_USER
} from 'store/Users/actions';
import { APPROVE_RCA, CREATE_NEW_RCA, CREATE_RCA, DENY_RCA, GET_RCA_ITEMS, REFRESH_RCA_DATA } from 'store/Rca/actions';

export const initialState = {};

export const actionsThatNeedLoader: string[] = [
    // EDGAR Actions
    GET_ARCHIVE_LIST,
    GET_FILING_ENTITY_LIST,
    EXPORT_FORMS,
    GET_NCEN_FROM_DATA,
    GET_D_FROM_DATA,

    // ERM Actions
    // ERM Entity Actions
    GET_ENTITIES,
    GET_ENTITY_PROPERTIES,
    CREATE_NEW_ENTITY,
    UPDATE_ENTITY_PROPERTIES,
    DELETE_DATASOURCE_BY_ID,
    EDIT_DATASOURCE,
    ADD_DATASOURCE,
    CREATE_DATA_SOURCES,

    // ERM Audit Trail Actions
    GET_AUDIT_TRAIL,

    // ERM Flagged Entity Actions
    GET_FLAGGED_ENTITIES,
    DELETE_FLAGGED_ENTITIES,
    ASSOCIATE_ENTITY,
    RESOLVE_CONFLICT_ENTITY,
    ASSIGN_FLAGGED_ENTITIES,
    REPROCESS_FLAGGED_ENTITIES,
    BULK_REPROCESS_FLAGGED_ENTITY,

    // ERM Relationship Actions
    GET_RELATIONSHIPS,
    GET_RELATIONSHIP,
    CREATE_RELATIONSHIP,
    EDIT_RELATIONSHIP,
    DELETE_RELATIONSHIP,

    // ERM Flagged Relationship Actions
    GET_FLAGGED_RELATIONSHIPS,
    DELETE_FLAGGED_RELATIONSHIPS,
    ASSIGN_FLAGGED_RELATIONSHIPS,
    BULK_REPROCESS_FLAGGED_RELATIONSHIPS,
    REPROCESS_FLAGGED_RELATIONSHIPS,
    MODIFY_FLAGGED_RELATIONSHIP,

    // User Actions
    GET_USERS,
    REFRESH_USERS_DATA,
    UPDATE_USER,
    LOCK_USER,
    UNLOCK_USER,
    RESET_USER_ATTEMPTS,

    // RCA Actions
    GET_RCA_ITEMS,
    REFRESH_RCA_DATA,
    APPROVE_RCA,
    CREATE_RCA,
    DENY_RCA,
    CREATE_NEW_RCA,
];
