import * as React from 'react';
import PrettifiedJSON from '../Shared/ModalContent/PrettifiedJSON';
import Modal from '../Shared/Modal/Modal';

interface Props {
    body: any;
    handleCloseModal: any;
    opened: boolean;
    title: string;
}

class ModalWithText extends React.PureComponent<Props> {

    handleOk() {
        this.props.handleCloseModal();
    }

    render() {
        const content = (
            <PrettifiedJSON text={this.props.body} />
        );

        const buttons = [
            {title: 'OK', onClick: () => this.handleOk(), color: 'primary'},
        ];

        return (
            <Modal
                toggle={this.props.handleCloseModal}
                title={this.props.title}
                body={content}
                buttons={buttons}
                isOpened={this.props.opened}
            />
        );
    }
}

export default ModalWithText;
