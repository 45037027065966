import { connect } from 'react-redux';

import GlobalModal from 'components/Shared/Modal/GlobalModal';
import { setCloseGlobalModal } from 'store/GlobalModal/actionCreators';
import { AppState } from 'store/types';
import { getGlobalModalState } from 'store/GlobalModal/selectors';

const mapStateToProps = (state: AppState) => {
    const globalModal = getGlobalModalState(state);

    return {
        ...globalModal
    };

};

const mapDispatchToProps = {
    setCloseGlobalModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalModal);