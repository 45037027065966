import { defaultSortPagination, DEFAULT_FILTER } from '../../constants';
import { SelectItem } from 'store/types';
import { UserGlobalRole } from './types';

export const messages = {
    MESSAGE_USERS_UPDATE_SUCCESS: 'User was updated successfully',
    MESSAGE_USERS_UPDATE_FAILED: 'User updating failed',

    QUESTION_LOCK: 'Are you sure you want lock user?',
    QUESTION_UNLOCK: 'Are you sure you want unlock user?',
    QUESTION_RESET_ATTEMPTS: 'Are you sure you want reset unsuccessful login attempts?',
};

export const initialState = {
    list: {
        count: 0,
        results: [],
        next: '',
        previous: ''
    },
    refreshStep: 0
};

export const defaultFilter = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        entity_type: ''
    },
    headerType: 'users'
};

export const USER_GLOBAL_ROLES_SELECT: SelectItem[] = [
    {label: 'None', value: UserGlobalRole.None},
    {label: 'Concierge', value: UserGlobalRole.Concierge},
    {label: 'Sysadmin', value: UserGlobalRole.Sysadmin},
];