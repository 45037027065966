import * as React from 'react';
import { Link } from 'react-router-dom';

import { name as viewModalName } from 'containers/Rca/ViewModal';
import { name as approveModalName } from 'containers/Rca/ApproveModal';
import { name as createModalName } from 'containers/Rca/CreateModal';
import { name as denyModalName } from 'containers/Rca/DenyModal';

import Rca from 'store/Rca/models/rca';
import { isApproved, isAwaiting, isCreated } from 'store/Rca/rcaHelper';
import { OpenAction } from 'store/Modal/actions';
import { ModalParams } from 'store/Modal/types';

interface Props {
    rca: Rca;
    openModal: (name: string, params: ModalParams) => OpenAction;
}

class RcaActionsCell extends React.PureComponent<Props> {

    view = () => this.props.openModal(viewModalName, this.props.rca);
    create = () => this.props.openModal(createModalName, this.props.rca);
    approve = () => this.props.openModal(approveModalName, this.props.rca);
    deny = () => this.props.openModal(denyModalName, this.props.rca);

    render() {
        const {rca} = this.props;
        return (
            <div>
                {isCreated(rca) &&
                <Link to="#" onClick={this.view}>
                    <span className="input-label">View</span>
                </Link>
                }
                {isApproved(rca) &&
                <Link to="#" onClick={this.create}>
                    <span className="input-label">Create</span>
                </Link>
                }
                {isAwaiting(rca) && (
                    <>
                    <Link to="#" onClick={this.approve}>
                        <span className="input-label">Approve</span>
                    </Link>
                    {' '}
                    <Link to="#" onClick={this.deny}>
                        <span className="input-label">Deny</span>
                    </Link>
                    </>
                )}
            </div>
        );
    }

}

export default RcaActionsCell;