import { Action } from 'redux';

export const GET_HEADERS = 'store/headers/GetHeaders';
export const SET_HEADERS = 'store/headers/SetHeaders';

export interface GetHeadersAction extends Action {
    block: string;
    headers: string[];
}
export interface SetHeadersAction extends Action {
    block: string;
    headers: string[];
}

export type VisibleHeadersAction = GetHeadersAction | SetHeadersAction;
