import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { AppState } from '../../store/types';

import { getUser, isAuthenticated, isInitialized } from '../../store/Auth/selectors';
import { login, logout } from '../../store/Auth/actionCreators';
import Header from '../../components/Shared/Header/Header';

const mapStateToProps = (state: AppState) => {
    return {
        isAuthenticated: isAuthenticated(state),
        isInitialized: isInitialized(state),
        user: getUser(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return bindActionCreators({login, logout}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);