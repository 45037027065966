import { GetListResponse, RcaFilter } from './types';
import { Action } from 'redux';
import RcaRequest from './models/rcaRequest';

export const GET_RCA_ITEMS = 'store/rca/getItems';
export const SET_RCA_ITEMS = 'store/rca/setItems';

export const REFRESH_RCA_DATA = 'store/rca/refreshData';
export const APPROVE_RCA = 'store/rca/approve';
export const CREATE_RCA = 'store/rca/create';
export const DENY_RCA = 'store/rca/deny';
export const CREATE_NEW_RCA = 'store/rca/createNew';

export interface RefreshDataAction extends Action {
}

export interface GetItemsAction extends Action {
    filter: RcaFilter;
}

export interface SetItemsAction extends Action {
    list: GetListResponse;
}

export interface ApproveAction extends Action {
    id: number;
    closeModal: boolean;
}

export interface CreateAction extends Action {
    id: number;
    closeModal: boolean;
}

export interface DenyAction extends Action {
    id: number;
    closeModal: boolean;
}

export interface CreateNewAction extends Action {
    request: RcaRequest;
    closeModal: boolean;
}

export type RcaAction = RefreshDataAction | GetItemsAction | SetItemsAction |
    ApproveAction | CreateAction | DenyAction |
    CreateNewAction;