import { UsersState } from './types';

import { initialState } from './constants';
import { SET_USERS, REFRESH_USERS_DATA, UsersAction, SetItemsAction } from './actions';

export const usersReducer = (state: UsersState = initialState, action: UsersAction) => {
    switch (action.type) {
        case REFRESH_USERS_DATA:
            return {
                ...state,
                refreshStep: state.refreshStep + 1
            };
        case SET_USERS:
            return {
                ...state,
                list: (<SetItemsAction> action).list
            };
        default:
            return state;
    }
};