import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { DateRangePicker } from 'react-dates';

import { DATA_SOURCE_TYPE_OPTIONS, DATA_SOURCE_KEY_TYPE_OPTIONS } from 'containers/Erm/constants';
import { DEFAULT_DATE_FORMAT } from '../../../../constants';
import FormHelper from 'helpers/formHelper';
import ToggleAdapter from 'components/Shared/ui/ToggleAdapter';
import { ReactSelectAdapter } from 'components/Shared/ui/Select';

const styles = require('./AddDatasource.scss');

class AddDatasource extends React.PureComponent<any> {

    state = {
        validFrom: null,
        validTo: null,
        focusedInput: null
    };

    handleChange = (validFrom?: any, validTo?: any ) => {
        this.setState({ validFrom, validTo });
    }

    handleSubmitForm = (values: any) => {
        let editObject = {
            ...values,
            data_source: FormHelper.getDataFromSelect(values.data_source),
            data_source_key_type: FormHelper.getDataFromSelect(values.data_source_key_type),
            valid_from: FormHelper.setFormatDateTime(this.state.validFrom),
            valid_to: FormHelper.setFormatDateTime(this.state.validTo)
        };

        const toSend = {
            data: this.props.payload,
            newObj: editObject,
        };

        this.props.addDatasource(toSend);
    }

    render() {
        return (
            <div className={styles.edit_form}>
                <Form
                    onSubmit={this.handleSubmitForm}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div className="form-group">
                                    <label htmlFor="">
                                        Source
                                    </label>
                                    <Field
                                        name="data_source"
                                        component={ReactSelectAdapter}
                                        options={DATA_SOURCE_TYPE_OPTIONS}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">
                                        Key Type
                                    </label>
                                    <Field
                                        name="data_source_key_type"
                                        component={ReactSelectAdapter}
                                        options={DATA_SOURCE_KEY_TYPE_OPTIONS}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">
                                        Key
                                    </label>
                                    <Field
                                        className="form-control"
                                        name="data_source_key"
                                        component="input"
                                        type="text"
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="">Valid From</label>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="">Valid To</label>
                                        </div>
                                    </div>
                                    <DateRangePicker
                                        displayFormat={DEFAULT_DATE_FORMAT}
                                        startDatePlaceholderText="valid from"
                                        endDatePlaceholderText="valid to"
                                        showClearDates={true}
                                        startDateId="valid_from"
                                        endDateId="valid_to"
                                        isOutsideRange={() => false}
                                        startDate={this.state.validFrom}
                                        endDate={this.state.validTo}
                                        onDatesChange={({ startDate, endDate }) =>
                                            this.handleChange(startDate, endDate)}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="">
                                        Is Valid?
                                    </label>
                                    <Field
                                        name="is_valid"
                                        component={ToggleAdapter}
                                        defaultToggled={true}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className="buttons">
                                <button type="submit" className="btn btn-primary">
                                    Add
                                </button>
                            </div>
                        </form>
                    )}
                />
            </div>
        );
    }
}

export default AddDatasource;
