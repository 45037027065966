import {
    APPROVE_RCA, CREATE_RCA, CREATE_NEW_RCA, DENY_RCA, GET_RCA_ITEMS, REFRESH_RCA_DATA, SET_RCA_ITEMS
} from './actions';
import { GetListResponse, RcaFilter } from './types';
import RcaRequest from './models/rcaRequest';

export const refreshData = () => ({
    type: REFRESH_RCA_DATA
});

export const getItems = (filter: RcaFilter) => ({
    type: GET_RCA_ITEMS,
    filter
});

export const setItems = (list: GetListResponse) => ({
    type: SET_RCA_ITEMS,
    list
});

export const approve = (id: number, closeModal = false) => ({
    type: APPROVE_RCA,
    id, closeModal
});

export const create = (id: number, closeModal = false) => ({
    type: CREATE_RCA,
    id, closeModal
});

export const deny = (id: number, closeModal = false) => ({
    type: DENY_RCA,
    id, closeModal
});

export const createNew = (request: RcaRequest, closeModal = false) => ({
    type: CREATE_NEW_RCA,
    request, closeModal
});