import { AppState } from '../types';
import { GlobalModalState } from './types';
import { initialState } from './constants';
import { createSelector } from 'reselect';

export const getGlobalModalState = (state: AppState): GlobalModalState => state.globalModal || initialState;

export const getGlobalModalPayload = createSelector(
    getGlobalModalState,
    globalModalState => globalModalState.payload
);