import {
    EdgarAction, SET_ARCHIVE_LIST, SET_D_FROM_DATA, SET_FILING_ENTITY_LIST, SET_NCEN_FROM_DATA, SetArchiveListAction,
    SetDFormDataAction, SetFilingEntityListAction, SetNCENFormDataAction
} from './actions';
import { initialState } from './constants';
import { EdgarState } from './types';

export const edgarReducer = (state: EdgarState = initialState, action: EdgarAction) => {
    switch (action.type) {
        case SET_ARCHIVE_LIST:
            return {
                ...state,
                archives: (<SetArchiveListAction> action).archives,
                archivesCount: (<SetArchiveListAction> action).count,
            };
        case SET_FILING_ENTITY_LIST:
            return {
                ...state,
                filingEntities: (<SetFilingEntityListAction> action).filingEntities,
                filingEntitiesCount: (<SetFilingEntityListAction> action).count,
            };
        case SET_NCEN_FROM_DATA:
            return {
                ...state,
                filingEntityInfo: (<SetNCENFormDataAction> action).filingEntityInfo,
                ncenFormData: (<SetNCENFormDataAction> action).ncenForm,
            };
        case SET_D_FROM_DATA:
            return {
                ...state,
                filingEntityInfo: (<SetDFormDataAction> action).filingEntityInfo,
                dFormData: (<SetDFormDataAction> action).dForm,

            };
        default:
            return state;
    }
};