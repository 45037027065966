import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SuppressEntities from '../../../../components/Erm/Actions/FlaggedEntitiesActions/SuppressEntities';
import { deleteFlaggedEntities } from '../../../../store/Erm/FlaggedEntity/actionCreators';
import { setCloseGlobalModal } from '../../../../store/GlobalModal/actionCreators';

const mapStateToProps = (state: any, ownProps: any) => {
    const modal = state.globalModal;
    return {
        payload: modal && modal.payload ? modal.payload : {},
    };

};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            deleteEntities: (payload) => {
                return deleteFlaggedEntities(payload);
            },
            closeModal: () => {
                return setCloseGlobalModal();
            },
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SuppressEntities);
