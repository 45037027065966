import * as React from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { TextField } from 'material-ui';

import {
    DATA_SOURCE_KEY_TYPE_LABELS,
    DATA_SOURCE_TYPE_LABELS,
    ENTITY_TYPES_SELECT_WITHOUT_UNKNOWN
} from 'containers/Erm/constants';
import { CheckboxOption, DataSourceObject } from 'store/Erm/FlaggedEntity/types';
import FormGroupWithCheckboxGroup from './FormGroupWithCheckboxGroup';
import {
    getCreateNewEntityDataSourceFromMessage, getCreateNewEntityDataSourceToSend,
} from 'helpers/dataSourceHelper';
import { SelectItem } from 'store/types';
import { DataSourceKeyTypeEnum, DataSourceTypeEnum } from 'store/Erm/types';
import UiSelect from 'components/Shared/ui/Select';

const styles = require('./CreateNewEntity.scss');
const addIcon = require(`../../../../assets/img/sharedIcons/add.svg`);
const trashIcon = require(`../../../../assets/img/sharedIcons/trash.svg`);

const defaultOtherDataSource: DataSourceObject = {
    dataSource: undefined,
    dataSourceKeyType: undefined,
    dataSourceKey: '',
};

interface Props {
    payload: any;
    newEntity: any;
    dataSources: any[];
    createNewEntity: (newEntity: any, dataSources: any[]) => any;
}

interface Config {
    entityTypes: any[];
    dataSourceTypes: any[];
    adv: SelectItem[];
    lei: SelectItem[];
    edgar: SelectItem[];
}

interface State {
    entityName: string;
    entityType: string | undefined;
    messageDataSourceType: string;
    // from message
    dataSources: CheckboxOption[];
    // manually added
    otherDataSources: DataSourceObject[];
}

class CreateNewEntity extends React.Component<Props> {

    config: Config = {
        entityTypes: ENTITY_TYPES_SELECT_WITHOUT_UNKNOWN,
        adv: [
            { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.SEC], value: DataSourceKeyTypeEnum.SEC },
            { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.CRD], value: DataSourceKeyTypeEnum.CRD },
            { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.LEI], value: DataSourceKeyTypeEnum.LEI },
        ],
        lei: [
            { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.LEI], value: DataSourceKeyTypeEnum.LEI },
        ],
        edgar: [
            { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.TICKER], value: DataSourceKeyTypeEnum.TICKER },
            { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.CIK], value: DataSourceKeyTypeEnum.CIK },
            {
                label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.SERIES_ID],
                value: DataSourceKeyTypeEnum.SERIES_ID
            },
            {
                label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.CLASS_ID],
                value: DataSourceKeyTypeEnum.CLASS_ID
            },
            { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.SEC], value: DataSourceKeyTypeEnum.SEC },
            { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.LEI], value: DataSourceKeyTypeEnum.LEI },
        ],
        dataSourceTypes: [
            { label: DATA_SOURCE_TYPE_LABELS[DataSourceTypeEnum.ADV], value: DataSourceTypeEnum.ADV },
            { label: DATA_SOURCE_TYPE_LABELS[DataSourceTypeEnum.LEI], value: DataSourceTypeEnum.LEI },
            { label: DATA_SOURCE_TYPE_LABELS[DataSourceTypeEnum.EDGAR], value: DataSourceTypeEnum.EDGAR },
        ],
    };

    state: State = {
        entityName: '',
        entityType: undefined,
        // from message
        messageDataSourceType: '',
        dataSources: [],
        // manually added
        otherDataSources: [defaultOtherDataSource],
    };

    componentDidMount() {
        if (this.props.payload.row) {
            const entityName = this.props.payload.row.entity_name;
            let entityType = this.props.payload.row.event_message.entity_type;
            const dataSource = this.props.payload.row.event_message.data_source_id;
            const dataSources = getCreateNewEntityDataSourceFromMessage(this.props.payload.row.event_message, [
                DataSourceKeyTypeEnum.SEC,
                DataSourceKeyTypeEnum.CRD,
                DataSourceKeyTypeEnum.LEI
            ]);

            entityType = entityType === 'unknown' ? undefined : entityType;
            this.setState({
                entityType, entityName, dataSources,
                messageDataSourceType: dataSource
            });
        }
    }

    handleSubmit = (event: any) => {
        event.preventDefault();

        let newEntity: any = {
            legal_name: this.state.entityName,
            entity_type: this.state.entityType,
        };
        if (this.props.payload.row && !this.props.payload.row.entity) {
            newEntity = {...newEntity, flagged_entity_message_id: this.props.payload.row.id};
        }
        const dataSource = getCreateNewEntityDataSourceToSend(
            this.state.messageDataSourceType, this.state.dataSources, this.state.otherDataSources
        );

        this.props.createNewEntity(newEntity, [...dataSource]);
    }

    handleSelectChange = (selectItem: any) => {
        const currentValue = selectItem ? selectItem.value : selectItem;
        this.setState({entityType: currentValue});
    }

    handleInputChange = (event: any) => {
        const newInputVar = {};
        newInputVar[event.target.name] = event.target.value;
        this.setState({...newInputVar});
    }

    handleDataSourceChange = (event: any, index: number) => {
        event.preventDefault();
        const checked = this.state.dataSources[index].checked;
        if (this.state.dataSources) {
            this.setState({
                dataSources: this.state.dataSources.map(
                    (dataSource, id) => id === index
                        ? { ...dataSource, checked: !checked }
                        : dataSource)
                }
            );
        }
    }

    handleOtherDataSourceInputChange = (event: any, index: number) => {
        event.preventDefault();
        if (this.state.otherDataSources) {
            this.setState({
                otherDataSources: this.state.otherDataSources.map(
                (otherDataSource, id) => id === index
                    ? { ...otherDataSource, dataSourceKey: event.target.value }
                    : otherDataSource)
                }
            );
        }
    }

    addNewDataSourceRow = (event: any) => {
        event.preventDefault();
        if (this.state.otherDataSources) {
            this.setState(
                { otherDataSources: [...this.state.otherDataSources, defaultOtherDataSource] },
            );
        }
    }

    deleteDataSourceInput = (event: any, index: number) => {
        event.preventDefault();
        let dataSourceKeyArray = [...this.state.otherDataSources];
        dataSourceKeyArray.splice(index, 1);
        if (this.state.dataSources) {
            this.setState(
                { otherDataSources: dataSourceKeyArray},
            );
        }
    }

    handleSelectDataSourceType = (selectedItem: any, index: number) => {
        const dataSource = selectedItem ? selectedItem.value : selectedItem;
        if (this.state.otherDataSources) {
            this.setState({
                otherDataSources: this.state.otherDataSources.map(
                    (otherDataSource: DataSourceObject, id) => id === index
                        ? {...otherDataSource, dataSource, dataSourceKeyType: undefined }
                        : otherDataSource
                ),
            });
        }
    }

    handleSelectDataSourceKeyType = (selectedItem: any, index: number) => {
        const dataSourceKeyType = selectedItem ? selectedItem.value : selectedItem;
        if (this.state.otherDataSources) {
            this.setState({
                otherDataSources: this.state.otherDataSources.map(
                    (otherDataSource: DataSourceObject, id) => id === index
                        ? {...otherDataSource, dataSourceKeyType }
                        : otherDataSource
                ),
            });
        }
    }

    render() {
        const createButtonDisabled = !this.state.entityName || !this.state.entityType;
        return (
            <div className={styles.create_new_entity}>
                <Form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <FormGroup className="col-md-8">
                            <Label for="entityName" className="mr-sm-2">Entity</Label>
                            <Input
                                type="text"
                                value={this.state.entityName}
                                onChange={this.handleInputChange}
                                name="entityName"
                                id="entityName"
                            />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <Label for="entityType">Entity type</Label>
                            <UiSelect
                                placeholder="Choose Entity Type"
                                value={this.state.entityType}
                                handleChange={this.handleSelectChange}
                                options={this.config.entityTypes}
                            />
                        </FormGroup>
                    </div>
                    <FormGroup className="col-md-12">
                        <div className="row">
                            <h5>Select the correct ID Mapping:</h5>
                        </div>
                    </FormGroup>
                    {
                        <FormGroupWithCheckboxGroup
                            handleCheckboxChange={this.handleDataSourceChange}
                            checkboxOptions={this.state.dataSources}
                        >
                            {
                                this.state.otherDataSources &&
                                this.state.otherDataSources.map((otherDataSource: DataSourceObject, idx: number) => {
                                    const currentDataSourceKeyTypes = otherDataSource.dataSource
                                        ? this.config[otherDataSource.dataSource]
                                        : this.config.adv;
                                    return (
                                        <div className="row" key={idx}>
                                            <div className="col-md-3">
                                                <UiSelect
                                                    placeholder="ADV / LEI / Edgar"
                                                    value={otherDataSource.dataSource}
                                                    handleChange={event => this.handleSelectDataSourceType(event, idx)}
                                                    options={this.config.dataSourceTypes}
                                                />
                                            </div>
                                            <div className="col-md-3 noPadding">
                                                <UiSelect
                                                    placeholder="CRD / LEI / SEC / Other..."
                                                    value={otherDataSource.dataSourceKeyType}
                                                    handleChange={event =>
                                                        this.handleSelectDataSourceKeyType(event, idx)
                                                    }
                                                    options={currentDataSourceKeyTypes}
                                                    disabled={!otherDataSource.dataSource}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    type="text"
                                                    value={otherDataSource.dataSourceKey}
                                                    onChange={
                                                        (event) => this.handleOtherDataSourceInputChange(event, idx)
                                                    }
                                                    name={'otherDataSource' + idx}
                                                    id={'otherDataSource' + idx}
                                                    hintText={'Enter Data Source Value'}
                                                    className={styles.inputText}
                                                />
                                                <img
                                                    className={styles.removeDataSource}
                                                    src={trashIcon}
                                                    alt="Remove Other Data Source"
                                                    onClick={
                                                        (event: any) => this.deleteDataSourceInput(event, idx)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <div key="addButton" className="col-md-12">
                                <img
                                    className={styles.addNewDataSource}
                                    src={addIcon}
                                    alt="Add other Data Source"
                                    onClick={this.addNewDataSourceRow}
                                />
                            </div>
                        </FormGroupWithCheckboxGroup>
                    }
                    <FormGroup className="col-md-6">
                        <div className="row">
                            <button className="btn btn-primary" disabled={createButtonDisabled}>Create</button>
                        </div>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

export default CreateNewEntity;