import * as React from 'react';
import { debounce } from 'lodash';

import { HeaderSettings } from 'store/types';
import AssociateAndResolveFirstStepContent
    from 'components/Erm/Actions/FlaggedEntitiesActions/AssociateAndResolveFirstStepContent';
import AssociateAndResolveSecondStepContent
    from 'components/Erm/Actions/FlaggedEntitiesActions/AssociateAndResolveSecondStepContent';
import { FiltersState } from 'store/Filters/types';
import { Entity } from 'store/Erm/Entity/entity';
import { GetEntitiesAction, SetEntitiesAction } from 'store/Erm/Entity/actions';
import { AssociateAndResolveEntityAction } from 'store/Erm/FlaggedEntity/actions';
import { ModalPayload } from 'store/GlobalModal/types';
import { AssociateAndResolveBody } from 'store/Erm/FlaggedEntity/types';

import { DEFAULT_ITEMS_PER_PAGE, SEARCH_DEBOUNCE_INTERVAL } from '../../../../constants';

interface Props {
    header: HeaderSettings[];
    results: Entity[];
    count: number;
    getEntities: (filter: FiltersState) => GetEntitiesAction;
    flaggedEntityMessage: ModalPayload;
    associateAndResolveEntity: (
        flaggedMessageId: number,
        body: AssociateAndResolveBody,
        flaggedEntityName?: string,
        associatedEntityName?: string
    ) => AssociateAndResolveEntityAction;
    handleCancel: () => void;
    setEntities: (entities: Entity[], count: number) => SetEntitiesAction;
}

interface State {
    filter: FiltersState;
    headerType: string;
    clickedRow: Entity | null;
    selectedRowId: number;
    step: string;
}

class AssociateAndResolveModal extends React.PureComponent<Props, State> {

    state = {
        filter: {
            limit: DEFAULT_ITEMS_PER_PAGE,
            offset: 0,
            search: this.legalName,
        },
        headerType: 'associateToExisting',
        clickedRow: null,
        selectedRowId: -1,
        step: 'first',
    };

    getDataAfterDebounce = debounce(
        (filter: FiltersState) => { this.props.getEntities(filter); },
        SEARCH_DEBOUNCE_INTERVAL
    );

    componentDidMount() {
        this.props.getEntities(this.state.filter);
    }

    componentWillUnmount() {
        this.props.setEntities([], 0);
    }

    render() {
        const {results, flaggedEntityMessage, header, handleCancel, count} = this.props;
        const {filter, selectedRowId} = this.state;

        switch (this.state.step) {
            case 'first': {
                return (
                    <AssociateAndResolveFirstStepContent
                        results={results}
                        count={count}
                        flaggedEntityMessage={flaggedEntityMessage}
                        header={header}
                        handleChange={this.handleChange}
                        filter={filter}
                        handleCancel={handleCancel}
                        handleRowClick={this.handleRowClick}
                        selectedRowId={selectedRowId}
                    />
                );
            }
            case 'second': {
                return (
                    <AssociateAndResolveSecondStepContent
                        chosenEntity={this.state.clickedRow}
                        flaggedEntityMessage={flaggedEntityMessage}
                        handleBackClick={this.handleBackClick}
                        associateAndResolveEntity={this.props.associateAndResolveEntity}
                    />
                );
            }
            default: return null;
        }
    }

    get legalName() {
        const payload = this.props.flaggedEntityMessage;
        return payload && payload.event_message && payload.event_message.legal_name
            ? payload.event_message.legal_name
            : '';
    }

    handleRowClick = (rowId: number, rowData: any) => {
        if (this.state.selectedRowId === rowId) {
            rowId = -1;
            rowData = false;
        }
        this.setState({selectedRowId: rowId, clickedRow: rowData, step: 'second'});
    }

    handleBackClick = () => {
        this.setState({selectedRowId: -1, clickedRow: null, step: 'first'});
    }

    handleChange = (name: string, type: string, value: any): void => {
        let filterParam = {};
        if (name === 'search') {
            filterParam[name] = value;
        }

        this.setState((prevState) => ({
            filter: {...prevState.filter, ...filterParam},
            clickedRow: null,
            selectedRowId: -1
        }),           () => {
            this.getDataAfterDebounce(this.state.filter);
        });
    }
}

export default AssociateAndResolveModal;