import * as React from 'react';
import { DateRangePicker } from 'react-dates';

import { DEFAULT_DATE_FORMAT } from '../../../constants';

interface Props {
    startDate: any;
    endDate: any;
    startTitle: any;
    endTitle: any;
    handleDateChange: (start: any, end: any) => void;
}

const styles = require('./DateRangePicker.scss');

class UiDateRangePicker extends React.PureComponent<Props> {

    state = {
        focusedInput: null
    };

    render() {
        return (
            <div className={styles.date_range_picker}>
                <DateRangePicker
                    displayFormat={DEFAULT_DATE_FORMAT}
                    showClearDates={true}
                    startDatePlaceholderText={this.props.startTitle}
                    endDatePlaceholderText={this.props.endTitle}
                    startDateId={this.props.startTitle}
                    endDateId={this.props.endTitle}
                    isOutsideRange={() => false}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    onDatesChange={({ startDate, endDate }) => this.props.handleDateChange(startDate, endDate)}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                />
            </div>
        );
    }
}

export default UiDateRangePicker;