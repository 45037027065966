import { AppState } from '../types';
import { createSelector } from 'reselect';
import { ModalState } from './types';

export const getModalsState = (state: AppState) => state.modal;

export const isModalOpen = (state: AppState, props: {name: string}) => {
    const modalState = getModalsState(state) || {};
    return !!modalState.name && modalState.name === props.name;
};

export const getModalName = (state: AppState) => {
    const modalState = getModalsState(state) || {};
    return modalState.name;
};

export const getModalParams = createSelector(
    getModalsState,
    (state: ModalState) => state.params || {}
);