import * as React from 'react';
import { Button } from 'reactstrap';

import { CellTypeChoices, ValueTypeChoices } from 'store/types';
import DataTable from 'components/Shared/DataTable/DataTable';
import FilterPanel from 'components/Shared/Filters/FilterPanel';
import ShowTablesPanel from 'components/Shared/Filters/ShowTablesPanel';
import RcaActionsCell from 'containers/Rca/RcaActionsCell';
import Rca from 'store/Rca/models/rca';
import { RcaRequestsProps } from 'containers/Rca/RcaRequests';

const styles = require('../Shared/Filters/FilterPanel.scss');

export const tableHeaders = [
    { name: 'id', title: '#', active: false, },
    { name: 'account_name', title: 'Name', active: true, },
    { name: 'account_type', title: 'Acc Type', active: true, valueType: ValueTypeChoices.ContainsUnderscore },
    { name: 'status', title: 'Status', active: true, valueType: ValueTypeChoices.ContainsUnderscore },
    { name: 'initial_admin_email', title: 'Initial Admin', active: true, },

    { name: 'submitter_user', title: 'Submitter', active: true, },
    { name: 'approver_user', title: 'Approver', active: true, },
    { name: 'created_at', title: 'Created At', active: true, valueType: ValueTypeChoices.Date },
    {
        name: 'actions',
        title: 'Actions',
        active: true,
        cellType: CellTypeChoices.Custom,
        transformer: (rca: Rca) => (
            <RcaActionsCell rca={rca}/>
        ),
    },
];

class RcaRequests extends React.PureComponent<RcaRequestsProps> {
    render() {
        const items = this.props.results;
        return (
            <div>
                <div>
                    {this.renderFilters()}
                </div>
                {items && this.renderTable()}
            </div>
        );
    }

    renderTable () {
        const items = this.props.results;
        return (
            <DataTable
                header={this.props.header}
                rows={items}
                count={this.props.count}
                handlePageChange={this.props.handlePageChange}
                handleChange={this.props.handleChange}
                ordering={this.props.ordering}
                orderSign={this.props.orderSign}
                currentPage={this.props.currentPage}
            />
        );
    }

    renderFilters () {
        const headersToShow = this.props.header ? this.props.header : [];
        return (
            <div>
                <FilterPanel
                    count={this.props.count}
                    filters={this.props.filters}
                    currentFilter={this.props.filter}
                    handleChange={this.props.handleChange}
                    countLabel="RCA"
                >
                    <ShowTablesPanel
                        headers={headersToShow}
                        handleChange={this.props.handleColumns}
                    />
                    <div className={styles['filter-panel-button-container']}>
                        <div className={styles['action-button']}>
                            <Button color="success" onClick={this.props.createNew} style={{marginTop: '15px'}}>
                                Create new
                            </Button>
                        </div>
                    </div>
                </FilterPanel>
            </div>
        );
    }
}

export default RcaRequests;