import { FlaggedRelationshipState } from './types';

import {
    GET_FLAGGED_RELATIONSHIPS,
    SET_FLAGGED_RELATIONSHIPS,
    DELETE_FLAGGED_RELATIONSHIPS,
    ASSIGN_FLAGGED_RELATIONSHIPS,
    REPROCESS_FLAGGED_RELATIONSHIPS,
    FlaggedRelationshipAction,
    SetFlaggedRelationshipAction,
} from './actions';

const initialState = {};

const reducer = (state: FlaggedRelationshipState = initialState, action: FlaggedRelationshipAction) => {
    switch (action.type) {
        case GET_FLAGGED_RELATIONSHIPS:
            return state;
        case SET_FLAGGED_RELATIONSHIPS:
            return {
                ...state,
                items: (<SetFlaggedRelationshipAction> action).flaggedRelationship
            };
        case DELETE_FLAGGED_RELATIONSHIPS:
            return state;
        case ASSIGN_FLAGGED_RELATIONSHIPS:
            return state;
        case REPROCESS_FLAGGED_RELATIONSHIPS:
            return state;
        default:
            return state;
    }
};

export default reducer;