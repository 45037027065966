import * as React from 'react';

import { HeaderSettings } from 'store/types';
import AssociateAndResolveModal from 'components/Erm/Actions/FlaggedEntitiesActions/AssociateAndResolveModal';
import ResolveConflictsModal from 'components/Erm/Actions/FlaggedEntitiesActions/ResolveConflictsModal';
import { ERROR_TYPE_CONFLICT, ERROR_TYPE_AMBIGUOUS, ACTION_LABEL_RESOLVE_CONFLICTS } from 'containers/Erm/constants';
import { Entity } from 'store/Erm/Entity/entity';
import { FiltersState } from 'store/Filters/types';
import { ModalPayload } from 'store/GlobalModal/types';
import { SetCloseGlobalModalAction } from 'store/GlobalModal/actions';
import { AssociateAndResolveEntityAction, ResolveConflictEntityAction } from 'store/Erm/FlaggedEntity/actions';
import { GetEntitiesAction, SetEntitiesAction } from 'store/Erm/Entity/actions';
import { AssociateAndResolveBody, ResolveConflictBody } from 'store/Erm/FlaggedEntity/types';

interface Props {
    header: HeaderSettings[];
    results: Entity[];
    count: number;
    getEntities: (filter: FiltersState) => GetEntitiesAction;
    payload: ModalPayload;
    closeModal: () => SetCloseGlobalModalAction;
    associateAndResolveEntity: (
        flaggedMessageId: number,
        body: AssociateAndResolveBody,
        flaggedEntityName?: string,
        associatedEntityName?: string
    ) => AssociateAndResolveEntityAction;
    resolveConflicts: (
        flaggedMessageId: number,
        body: ResolveConflictBody,
        flaggedEntityName?: string
    ) => ResolveConflictEntityAction;
    setTitle: (title: string) => void;
    setEntities: (entities: Entity[], count: number) => SetEntitiesAction;
}

class ConflictAndAmbiguousActionsWrapper extends React.PureComponent<Props> {

    componentDidMount() {
        const payload = this.props.payload.row ? this.props.payload.row : {};
        const errorType = payload && payload.error_type ? payload.error_type : '';
        if (errorType === ERROR_TYPE_CONFLICT) {
            this.props.setTitle(ACTION_LABEL_RESOLVE_CONFLICTS);
        } else if (errorType !== ERROR_TYPE_AMBIGUOUS) {
            this.props.setTitle('Unknown Error Type');
        }
    }

    render() {
        const payload = this.props.payload.row ? this.props.payload.row : {};
        const errorType = payload && payload.error_type ? payload.error_type : '';
        if (errorType === ERROR_TYPE_CONFLICT) {
            return (
                <ResolveConflictsModal
                    resolveConflicts={this.props.resolveConflicts}
                    flaggedEntityMessage={payload}
                    handleCancel={this.handleCancel}
                />
            );
        } else if (errorType === ERROR_TYPE_AMBIGUOUS) {
            return (
                <AssociateAndResolveModal
                    header={this.props.header}
                    results={this.props.results}
                    count={this.props.count}
                    getEntities={this.props.getEntities}
                    associateAndResolveEntity={this.props.associateAndResolveEntity}
                    flaggedEntityMessage={payload}
                    handleCancel={this.handleCancel}
                    setEntities={this.props.setEntities}
                />
            );
        } else {
            return (
                <div>For current Error Type it's not clear how to resolve conflict</div>
            );
        }
    }

    handleCancel = () => {
        this.props.closeModal();
    }
}

export default ConflictAndAmbiguousActionsWrapper;