import { ModalState } from './types';
import { OPEN_MODAL, CLOSE_MODAL, ModalsAction } from './actions';
import { initialState } from './constants';

const reducer = (state: ModalState = initialState, action: ModalsAction) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                ...action
            };
        case CLOSE_MODAL:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
