import * as React from 'react';

import { CellTypeChoices, ValueTypeChoices } from 'store/types';
import DataTable from 'components/Shared/DataTable/DataTable';
import FilterPanel from 'components/Shared/Filters/FilterPanel';
import ShowTablesPanel from 'components/Shared/Filters/ShowTablesPanel';
import { User } from 'store/Users/models/user';
import ActionsCell from 'containers/Users/ActionsCell';
import { UserListProps } from 'containers/Users/List';

export const tableHeaders = [
    { name: 'id', title: '#', active: false, },
    { name: 'email', title: 'Email', active: true, },
    { name: 'last_name', title: 'Last name', active: true, },
    { name: 'first_name', title: 'First name', active: true, },
    { name: 'type', title: 'Type', active: true, },
    { name: 'status', title: 'Status', active: true, },
    { name: 'global_role', title: 'Global role', active: true, },
    { name: 'is_locked', title: 'Is locked', active: true, valueType: ValueTypeChoices.Boolean },
    { name: 'locked_at', title: 'Locked At', active: true, valueType: ValueTypeChoices.Date },
    { name: 'created_at', title: 'Created At', active: true, valueType: ValueTypeChoices.Date },
    {
        name: 'actions',
        title: 'Actions',
        active: true,
        cellType: CellTypeChoices.Custom,
        transformer: (user: User) => (
            <ActionsCell user={user}/>
        ),
    },
];

class List extends React.PureComponent<UserListProps> {
    render() {
        const items = this.props.results;
        return (
            <div>
                {this.renderFilters()}
                {items && this.renderTable()}
            </div>
        );
    }

    renderTable () {
        const items = this.props.results;
        return (
            <DataTable
                header={this.props.header}
                rows={items}
                count={this.props.count}
                handlePageChange={this.props.handlePageChange}
                handleChange={this.props.handleChange}
                ordering={this.props.ordering}
                orderSign={this.props.orderSign}
                currentPage={this.props.currentPage}
            />
        );
    }

    renderFilters () {
        const headersToShow = this.props.header ? this.props.header : [];
        return (
            <div>
                <FilterPanel
                    count={this.props.count}
                    filters={this.props.filters}
                    currentFilter={this.props.filter}
                    handleChange={this.props.handleChange}
                    countLabel="Users"
                >
                    <div style={{marginLeft: 'auto'}}>
                        <ShowTablesPanel
                            headers={headersToShow}
                            handleChange={this.props.handleColumns}
                        />
                    </div>
                </FilterPanel>
            </div>
        );
    }
}

export default List;