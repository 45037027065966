import { createSelector } from 'reselect';

import { retrieveRelationships, retrieveAuditTrail } from 'helpers/selectorHelper';
import { AppState } from 'store/types';
import { ErmState } from 'store/Erm/types';
import { EntityState } from 'store/Erm/Entity/types';

const initialState = {
    entity: {},
    relationship: {},
    flaggedEntity: {},
    flaggedRelationship: {},
    auditTrail: {},
    mergeEntity: {}
};

export const getErmState = (state: AppState) => state.erm || initialState;

export const getErmEntityState = createSelector(
    getErmState,
    (ermState: ErmState) => ermState.entity
);

export const getErmEntities = createSelector(
    getErmEntityState,
    (entityState: EntityState) => entityState.entities
);

export const getErmEntitiesCount = createSelector(
    getErmEntityState,
    (entityState: EntityState) => entityState.count
);

export const getEntitySelector = (state: any, ownProps: any) => {
    let item: any = [];
    if (state.erm.entity.item) {
        item = state.erm.entity.item;
    }

    return {
        item
    };
};

export const getRelationshipsSelector = (state: any, ownProps: any) => {
    let results: any = [];
    if (state.erm.relationship.items && state.erm.relationship.items.results) {
        results = retrieveRelationships(state.erm.relationship.items.results);
    }

    return {
        ...state.erm.relationship.items,
        results
    };
};

export const getEntityRelationshipsSelector = (state: any, ownProps: any) => {
    let itemRelationships: any = [];
    if (state.erm.entity.itemRelationships) {
        itemRelationships = retrieveRelationships(state.erm.entity.itemRelationships);
    }

    return {
        ...state.erm.entity.itemRelationships,
        itemRelationships
    };
};

export const getFlaggedEntitiesSelector = (state: any, ownProps: any) => {
    const flaggedState = state.erm.flaggedEntity.items;
    let results: any = [];
    let fields: any = [];
    if (flaggedState) {
        let prefields: any = [];
        if (flaggedState[0] && flaggedState.results) {
            prefields = Object.keys(flaggedState.results[0]);
        }

        fields = prefields.map((field: string, key: number) => {
            return {value: key, title: field};
        });
        results = flaggedState.results;
    }
    return {
        ...flaggedState,
        results,
        fields
    };
};

export const getFlaggedRelationshipsSelector = (state: any, ownProps: any) => {
    const flaggedState = state.erm.flaggedRelationship.items;
    let results: any = [];
    let fields: any = [];
    if (flaggedState) {
        let prefields: any = [];
        if (flaggedState[0] && flaggedState.results) {
            prefields = Object.keys(flaggedState.results[0]);
        }

        fields = prefields.map((field: string, key: number) => {
            return {value: key, title: field};
        });
        results = flaggedState.results;
    }
    return {
        ...flaggedState,
        results,
        fields
    };
};

export const getAuditTrailSelector = (state: any, ownProps: any) => {
    let results: any = [];
    if (state.erm.auditTrail.items && state.erm.auditTrail.items.results) {
        results = retrieveAuditTrail(state.erm.auditTrail.items.results);
    }

    return {
        ...state.erm.auditTrail.items,
        results
    };
};
