import { put, all, takeEvery, takeLatest } from 'redux-saga/effects';

import * as ermApi from '../../../services/erm';
import NotificationHelper from '../../../helpers/notificationHelper';
import {
    GET_FLAGGED_RELATIONSHIPS,
    DELETE_FLAGGED_RELATIONSHIPS,
    ASSIGN_FLAGGED_RELATIONSHIPS,
    REPROCESS_FLAGGED_RELATIONSHIPS, MODIFY_FLAGGED_RELATIONSHIP, BULK_REPROCESS_FLAGGED_RELATIONSHIPS,
} from './actions';
import { doneActionFail, doneActionSuccess, initAction } from '../../Actions/actionCreators';
import { setFlaggedRelationships } from './actionCreators';
import { setCloseGlobalModal } from '../../GlobalModal/actionCreators';
import { callApplyFilters } from '../../Filters/actionCreators';
import { FilterNames } from '../../Filters/types';

function* getFlaggedRelationship(action: any) {

    try {
        yield put(initAction(action.type));
        const flaggedRelationship = yield ermApi.getFlaggedRelationships(action.filter);
        yield put(setFlaggedRelationships(flaggedRelationship));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchGetFlagged_relationship() {
    yield takeEvery(GET_FLAGGED_RELATIONSHIPS, getFlaggedRelationship);
}

function* deleteFlaggedRelationship(action: any) {
    try {
        yield put(initAction(action.type));
        yield ermApi.deleteFlaggedRelationships(action.payload.messageIds);
        yield put(doneActionSuccess(action.type));
        yield put(callApplyFilters(FilterNames.ErmFlaggedRelationships, GET_FLAGGED_RELATIONSHIPS));
        yield put(setCloseGlobalModal());
        NotificationHelper.success(`${action.payload.messageIds.length} relationships have been successfully deleted!`);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchDeleteFlaggedRelationship() {
    yield takeLatest(DELETE_FLAGGED_RELATIONSHIPS, deleteFlaggedRelationship);
}

function* assignFlaggedRelationship(action: any) {
    try {
        yield put(initAction(action.type));
        yield ermApi.assignFlaggedRelationships(action.payload.messageIds, action.payload.userId);
        yield put(doneActionSuccess(action.type));
        yield put(callApplyFilters(FilterNames.ErmFlaggedRelationships, GET_FLAGGED_RELATIONSHIPS));
        NotificationHelper.success(
            `${action.payload.messageIds.length} relationships have been successfully assigned!`
        );
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchAssignFlaggedRelationship() {
    yield takeLatest(ASSIGN_FLAGGED_RELATIONSHIPS, assignFlaggedRelationship);
}

function* reprocessFlaggedRelationship(action: any) {
    try {
        yield put(initAction(action.type));

        yield ermApi.reprocessFlaggedRelationships(action.payload.messageIds);
        yield put(doneActionSuccess(action.type));
        yield put(callApplyFilters(FilterNames.ErmFlaggedRelationships, GET_FLAGGED_RELATIONSHIPS));
        NotificationHelper.success(
            `${action.payload.messageIds.length} relationships have been successfully reprocessed!`
        );
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchReprocessFlaggedRelationship() {
    yield takeLatest(REPROCESS_FLAGGED_RELATIONSHIPS, reprocessFlaggedRelationship);
}

function* modifyFlaggedRelationship(action: any) {
    try {
        yield put(initAction(action.type));
        yield ermApi.modifyFlaggedRelationships(action.id, action.payload);
        yield put(doneActionSuccess(action.type));
        NotificationHelper.success(
            `This message has been changed`
        );
        yield put(callApplyFilters(FilterNames.ErmFlaggedRelationships, GET_FLAGGED_RELATIONSHIPS));
        yield put(setCloseGlobalModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchModifyFlaggedRelationship() {
    yield takeLatest(MODIFY_FLAGGED_RELATIONSHIP, modifyFlaggedRelationship);
}

function* bulkReprocessFlaggedRelationship(action: any) {
    try {
        yield put(initAction(action.type));
        yield ermApi.bulkReprocessFlaggedRelationships();
        yield put(doneActionSuccess(action.type));
        NotificationHelper.success(
            `relationships have been successfully reprocessed!`
        );
        yield put(callApplyFilters(FilterNames.ErmFlaggedRelationships, GET_FLAGGED_RELATIONSHIPS));
        yield put(setCloseGlobalModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchBulkReprocessFlaggedRelationship() {
    yield takeLatest(BULK_REPROCESS_FLAGGED_RELATIONSHIPS, bulkReprocessFlaggedRelationship);
}

export default function* root() {
    yield all([
        watchGetFlagged_relationship(),
        watchDeleteFlaggedRelationship(),
        watchAssignFlaggedRelationship(),
        watchReprocessFlaggedRelationship(),
        watchModifyFlaggedRelationship(),
        watchBulkReprocessFlaggedRelationship()
    ]);
}