import {
    DataSource,
    DataSourceAPI,
    Entity,
    EventMessage,
    FlaggedEntityMessage,
    RadioOption,
    ResolveConflictBody,
    DataSourcesConfig,
    DataSourceConfig,
    CheckboxOption,
    DataSourceObject,
    DataSourceObjectAPI,
    DataSourceResolveConflict,
    AssociateAndResolveBody,
    DataSourceAssociateAndResolve,
} from 'store/Erm/FlaggedEntity/types';
import {
    RADIO_BUTTONS_CONFIG,
    FROM_ENTITY_LABEL,
    FROM_MESSAGE_LABEL,
    OTHER_LABEL,
    ENTITY_VALUE,
    MESSAGE_VALUE,
    OTHER_VALUE,
} from 'containers/Erm/constants';

import { formatText } from 'helpers/tableHelper';
import { ValueTypeChoices } from 'store/types';
import { uniq } from 'lodash';
import { ResolveConflictsState } from 'components/Erm/Actions/FlaggedEntitiesActions/ResolveConflictsModal';
import { AssociateAndResolveConflictsState }
    from 'components/Erm/Actions/FlaggedEntitiesActions/AssociateAndResolveSecondStepContent';

export const setLabelsToResolveConflictLegalNames = (
    flaggedEntityMessage: FlaggedEntityMessage,
    radioButtonsConfig = RADIO_BUTTONS_CONFIG
): RadioOption[] => {
    return radioButtonsConfig.legalName.map((item: RadioOption) => {
        let newItem: RadioOption = {label: '', value: item.value};
        switch (item.value) {
            case MESSAGE_VALUE:
                newItem.label = `${flaggedEntityMessage.entityName} ${FROM_MESSAGE_LABEL}`;
                break;
            case OTHER_VALUE:
                newItem.label = OTHER_LABEL;
                break;
            case ENTITY_VALUE:
            default:
                newItem.label = `${formatText(flaggedEntityMessage.entity.legalName)} ${FROM_ENTITY_LABEL}`;
                break;
        }
        return newItem;
    });
};

export const setLabelsToResolveConflictEntityTypes = (
    flaggedEntityMessage: FlaggedEntityMessage,
    radioButtonsConfig = RADIO_BUTTONS_CONFIG
): RadioOption[] => {
    return radioButtonsConfig.entityType.map((item: RadioOption) => {
        let newItem: RadioOption = {label: '', value: item.value};
        switch (item.value) {
            case MESSAGE_VALUE:
                newItem.label = `${formatText(
                    flaggedEntityMessage.eventMessage.entityType, ValueTypeChoices.ContainsUnderscore
                )} ${FROM_MESSAGE_LABEL}`;
                break;
            case OTHER_VALUE:
                newItem.label = OTHER_LABEL;
                break;
            case ENTITY_VALUE:
            default:
                newItem.label = `${formatText(
                    flaggedEntityMessage.entity.entityType, ValueTypeChoices.ContainsUnderscore
                )} ${FROM_ENTITY_LABEL}`;
                break;
        }
        return newItem;
    });
};

export const setLabelsToResolveConflictDataSources = (
    flaggedEntityMessage: FlaggedEntityMessage,
    showAllDatasources = false
): DataSourcesConfig => {
    const dataSourcesConfig: DataSourceConfig = {
        lei: [],
        crd: [],
        sec: [],
        cik: [],
        series_id: [],
        class_id: [],
        ticker: [],
    };
    const messageDataSources = {
        lei: flaggedEntityMessage.eventMessage.lei || '',
        crd: flaggedEntityMessage.eventMessage.crd || '',
        sec: flaggedEntityMessage.eventMessage.sec || '',
        cik: flaggedEntityMessage.eventMessage.cik || '',
        series_id: flaggedEntityMessage.eventMessage.series_id || '',
        class_id: flaggedEntityMessage.eventMessage.class_id || '',
        ticker: flaggedEntityMessage.eventMessage.ticker || '',
    };
    const entityDataSources = flaggedEntityMessage.entity.dataSources;
    entityDataSources.forEach((entityDataSource: DataSource) => {
        const entityDataSourceType = entityDataSource.dataSource;
        const entityDataSourceKeyType = entityDataSource.dataSourceKeyType;
        if (entityDataSourceType === flaggedEntityMessage.dataSource && messageDataSources[entityDataSourceKeyType]
            || showAllDatasources
        ) {
            const entityDataSourceKey: string = entityDataSource.dataSourceKey;
            const sourceTypeLabel = formatText(entityDataSourceType, ValueTypeChoices.ToUppercase);
            const label = `${entityDataSourceKey} (${sourceTypeLabel}) ${FROM_ENTITY_LABEL}`;
            dataSourcesConfig[entityDataSourceKeyType].push({
                label: label,
                checked: true,
                value: entityDataSourceKey,
                additionalInfo: {source: ENTITY_VALUE, dataSource: entityDataSourceType, id: entityDataSource.id}
            });
        }
    });

    for (let sourceType in messageDataSources) {
        if (messageDataSources.hasOwnProperty(sourceType) && messageDataSources[sourceType]) {
            const sourceTypeLabel = formatText(
                flaggedEntityMessage.eventMessage.dataSourceId,
                ValueTypeChoices.ToUppercase
            );
            const value = messageDataSources[sourceType];
            const label = `${value} (${sourceTypeLabel}) ${FROM_MESSAGE_LABEL}`;
            dataSourcesConfig[sourceType].push({
                label: label,
                checked: !(dataSourcesConfig[sourceType].length > 0),
                value,
                additionalInfo: {source: MESSAGE_VALUE, dataSource: flaggedEntityMessage.eventMessage.dataSourceId}
            });
        }
    }

    return dataSourcesConfig;
};

export const getResolveConflictBodyToSend = (state: ResolveConflictsState): ResolveConflictBody => {
    let legalName, entityType, dataSources: string[], otherDataSources: string[],
        dataSourcesMerged: DataSourceResolveConflict[] = [];
    let body: ResolveConflictBody = {entity_id: state.flaggedEntityMessage.entity.id};
    switch (state.legalNameRadioValue) {
        case MESSAGE_VALUE:
            legalName = state.flaggedEntityMessage.entityName;
            break;
        case OTHER_VALUE:
            legalName = state.otherLegalNameValue;
            break;
        case ENTITY_VALUE:
        default:
            legalName = state.flaggedEntityMessage.entity.legalName;
            break;
    }

    switch (state.entityTypeRadioValue) {
        case MESSAGE_VALUE:
            entityType = state.flaggedEntityMessage.eventMessage.entityType;
            break;
        case OTHER_VALUE:
            entityType = state.otherEntityTypeValue;
            break;
        case ENTITY_VALUE:
        default:
            entityType = state.flaggedEntityMessage.entity.entityType;
            break;
    }

    if (state.dataSources) {
        Object.keys(state.dataSources).map((key: string) => {
            if (state.dataSources.hasOwnProperty(key)) {
                dataSources = state.dataSources[key]
                    .filter((item: CheckboxOption) => item.checked)
                    .map((item: CheckboxOption) => String(item.value));
                dataSources = uniq(dataSources);

                if (state.otherDataSources && state.otherDataSources.hasOwnProperty(key)) {
                    otherDataSources = state.otherDataSources[key].filter((item: string) => item !== '');
                    dataSources = dataSources.concat(otherDataSources);
                    dataSources = uniq(dataSources);
                }

                if (dataSources.length > 0) {
                    dataSourcesMerged.push({
                        data_source: state.flaggedEntityMessage.dataSource,
                        data_source_key_type: key,
                        data_source_key: dataSources,
                    });
                }
            }
        });

    }

    if (legalName) {
        body.legal_name = legalName;
    }

    if (entityType) {
        body.entity_type = entityType;
    }

    if (dataSourcesMerged.length > 0) {
        body.data_sources = dataSourcesMerged;
    }

    return body;
};

export const convertDataSourcesFromApiToCamelCase = (dataSources: DataSourceAPI[]): DataSource[] => {
    return dataSources.map((item: DataSourceAPI) => {
        return {
            id: item.id,
            dataSource: item.data_source,
            dataSourceKeyType: item.data_source_key_type,
            dataSourceKey: item.data_source_key,
        };
    });
};

export const convertDataSourcesFromCamelCaseToApi = (dataSources: DataSource[]): DataSourceAPI[] => {
    return dataSources.map((item: DataSource) => {
        return {
            id: item.id,
            data_source: item.dataSource,
            data_source_key_type: item.dataSourceKeyType,
            data_source_key: item.dataSourceKey,
        };
    });
};

export const parseFlaggedEntityMessage = (flaggedEntity: any): FlaggedEntityMessage => {
    const message = flaggedEntity.event_message;
    const eventMessage: EventMessage = {
        crd: message.crd,
        lei: message.lei,
        sec: message.sec,
        cik: message.cik,
        series_id: message.series_id,
        class_id: message.class_id,
        ticker: message.ticker,
        eventType: message.event_type,
        legalName: message.legal_name,
        entityType: message.entity_type,
        filingDate: message.filing_date,
        dateCreated: message.date_created,
        businessName: message.business_name,
        dataSourceId: message.data_source_id,
        sourceFilingId: message.source_filing_id,
    };

    let dataSources;
    if (flaggedEntity.entity && flaggedEntity.entity.datasources) {
        dataSources = convertDataSourcesFromApiToCamelCase(flaggedEntity.entity.datasources);
    }

    const entity: Entity = {
        id: flaggedEntity.entity.id,
        legalName: flaggedEntity.entity.legal_name,
        entityType: flaggedEntity.entity.entity_type,
        dataSources: dataSources || [],
    };

    let extraData;
    if (flaggedEntity.extra_data) {
        extraData = {
            dataType: flaggedEntity.extra_data.data_type,
            eventDataSources: flaggedEntity.extra_data.event_datasources,
            entityDataSources: flaggedEntity.extra_data.entity_datasources,
        };
    }

    return {
        dataSource: flaggedEntity.data_source,
        entityName: flaggedEntity.entity_name,
        eventType: flaggedEntity.event_type,
        errorType: flaggedEntity.error_type,
        errorMessage: flaggedEntity.error_message,
        eventMessage: eventMessage,
        assignedTo: flaggedEntity.assigned_to,
        entity: entity,
        createdAt: flaggedEntity.created_at,
        extraData,
    };
};

export const getCreateNewEntityDataSourceFromMessage = (message: any, types: string[]) => {
    let messageDataSources: CheckboxOption[] = [];
    types.forEach((item: string) => {
        if (message[item]) {
            let sourceIdLabel = formatText(message.data_source_id, ValueTypeChoices.ToUppercase);
            let sourceKeyTypeLabel = formatText(item, ValueTypeChoices.ToUppercase);
            messageDataSources.push({
                label: sourceIdLabel + ` ${sourceKeyTypeLabel} ${message[item]}`,
                value: message[item],
                type: item,
                checked: true,
            });
        }
    });

    return messageDataSources;
};

const mergeWithExistingDataSources = (
    newElement: DataSourceObject,
    existingDataSources: DataSourceObjectAPI[]
): DataSourceObjectAPI[] => {
    const existDataSource = existingDataSources.find(elem =>
        elem.data_source === newElement.dataSource
        && elem.data_source_key_type === newElement.dataSourceKeyType
        && elem.data_source_key === newElement.dataSourceKey
    );
    if (!existDataSource && newElement.dataSourceKey && newElement.dataSource && newElement.dataSourceKeyType) {
        existingDataSources.push({
            data_source: newElement.dataSource,
            data_source_key_type: newElement.dataSourceKeyType,
            data_source_key: newElement.dataSourceKey,
        });
    }
    return existingDataSources;
};

export const getCreateNewEntityDataSourceToSend = (
    messageDataSourceType: string,
    dataSources: CheckboxOption[],
    otherDataSources: DataSourceObject[]
): DataSourceObjectAPI[] => {
    let dataSourcesMerged: DataSourceObjectAPI[] = [];
    dataSources = dataSources.filter(dataSource => dataSource.checked);
    dataSources.forEach((dataSource: CheckboxOption) => {
        const newElement = {
            dataSource: messageDataSourceType,
            dataSourceKeyType: dataSource.type,
            dataSourceKey: dataSource.value,
        };
        dataSourcesMerged = mergeWithExistingDataSources(newElement, dataSourcesMerged);
    });

    otherDataSources = otherDataSources.filter(dataSource =>
        dataSource.dataSourceKey && dataSource.dataSourceKeyType && dataSource.dataSource
    );
    otherDataSources.forEach((dataSource: DataSourceObject) => {
        const newElement = {
            dataSource: dataSource.dataSource,
            dataSourceKeyType: dataSource.dataSourceKeyType,
            dataSourceKey: dataSource.dataSourceKey,
        };
        dataSourcesMerged = mergeWithExistingDataSources(newElement, dataSourcesMerged);
    });

    return dataSourcesMerged;

};

export const getAssociateAndResolveEntityBody = (state: AssociateAndResolveConflictsState): AssociateAndResolveBody => {
    let dataSourcesMerged: DataSourceAssociateAndResolve[] = [];
    let body: AssociateAndResolveBody = {
        entity_id: state.flaggedEntityMessage.entity.id,
        legal_name: '',
        legal_name_source: '',
        save_other_name: state.saveOtherName,
    };

    if (state.legalNameRadioValue === MESSAGE_VALUE) {
        body.legal_name = state.flaggedEntityMessage.entityName;
        body.legal_name_source = MESSAGE_VALUE;
    } else {
        body.legal_name = state.flaggedEntityMessage.entity.legalName;
        body.legal_name_source = ENTITY_VALUE;
    }

    if (state.dataSources) {
        Object.keys(state.dataSources).map((key: string) => {
            if (state.dataSources.hasOwnProperty(key)) {
                state.dataSources[key]
                    .filter((item: CheckboxOption) => item.checked)
                    .forEach((item: CheckboxOption) => {
                        const source = item.additionalInfo ? String(item.additionalInfo.source) : '';
                        const itemValue = String(item.value);
                        let dataSourceItem: DataSourceAssociateAndResolve = {
                            source,
                            data_source: state.flaggedEntityMessage.dataSource,
                            data_source_key_type: key,
                            data_source_key: itemValue,
                        };
                        if (source === ENTITY_VALUE && item.additionalInfo
                            && item.additionalInfo.hasOwnProperty('id')
                        ) {
                            dataSourceItem.id = item.additionalInfo.id;
                        }
                        dataSourcesMerged.push(dataSourceItem);
                    });
            }
        });

    }

    if (dataSourcesMerged.length > 0) {
        body.data_sources = dataSourcesMerged;
    }

    if (state.useEntityType !== undefined) {
        body.use_entity_type = state.useEntityType;
    }

    return body;
};