import { GlobalModalState } from './types';
import {
    GlobalModalAction, SET_CLOSE_GLOBAL_MODAL, SetGlobalModalAction, GET_GLOBAL_MODAL, SET_GLOBAL_MODAL
} from './actions';
import { initialState } from './constants';

export const globalModalReducer = (state: GlobalModalState = initialState, action: GlobalModalAction) => {
    switch (action.type) {
        case GET_GLOBAL_MODAL:
            return {
                ...state,
            };
        case SET_CLOSE_GLOBAL_MODAL:
            return {
                ...initialState,
            };
        case SET_GLOBAL_MODAL:
            action = (<SetGlobalModalAction> action);
            return {
                ...state,
                ...(<SetGlobalModalAction> action).globalModal
            };
        default:
            return state;
    }
};