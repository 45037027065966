import { Action } from 'redux';

import FlaggedEntity from './flaggedEntity';
import { AssociateAndResolveBody, ResolveConflictBody } from './types';

export const GET_FLAGGED_ENTITIES = 'store/erm/flaggedEntity/get';
export const SET_FLAGGED_ENTITIES = 'store/erm/flaggedEntity/set';
export const DELETE_FLAGGED_ENTITIES = 'store/erm/flaggedEntity/delete';
export const ASSOCIATE_ENTITY = 'store/erm/flaggedEntity/associate';
export const RESOLVE_CONFLICT_ENTITY = 'store/erm/flaggedEntity/resolveConflict';
export const ASSIGN_FLAGGED_ENTITIES = 'store/erm/flaggedEntity/assign';
export const REPROCESS_FLAGGED_ENTITIES = 'store/erm/flaggedEntity/reprocess';
export const BULK_REPROCESS_FLAGGED_ENTITY = 'store/erm/flaggedEntity/reprocess/bulk';

export interface GetFlaggedEntityAction extends Action {
    filter: any;
}

export interface SetFlaggedEntityAction extends Action {
    flaggedEntity: FlaggedEntity;
}

export interface DeleteFlaggedEntityAction extends Action {
    payload: any;
}

export interface AssignFlaggedEntityAction extends Action {
    payload: any;
}

export interface ReprocessFlaggedEntityAction extends Action {
    payload: any;
}

export interface CreateFlaggedEntityAction extends Action {
    entityId: any;
    payload: any;
}

export interface AssociateAndResolveEntityAction extends Action {
    flaggedMessageId: number;
    body: AssociateAndResolveBody;
    flaggedEntityName?: string;
    associatedEntityName?: string;
}

export interface ResolveConflictEntityAction extends Action {
    flaggedMessageId: number;
    body: ResolveConflictBody;
    flaggedEntityName?: string;
}

export type FlaggedEntityAction = GetFlaggedEntityAction |
    SetFlaggedEntityAction |
    DeleteFlaggedEntityAction |
    AssignFlaggedEntityAction |
    ReprocessFlaggedEntityAction |
    AssociateAndResolveEntityAction |
    CreateFlaggedEntityAction |
    ResolveConflictEntityAction;
