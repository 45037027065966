import * as React from 'react';

import CountData from '../Count/CountData';
import { KeyboardEventHandler } from 'react';
import { FilterType } from 'store/types';

interface Props {
    name: string;
    title: string;
    count?: number;
    handleChange: (name: any, type: string, value: string) => void;
    countLabel?: string;
    withCount: boolean;
    className?: string;
}

const styles = require('./SelectFilter.scss');

const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.charCode === 13) {
        e.preventDefault();
    }
};

const InputFilterWithoutStoredValue: React.FunctionComponent<Props> = ({
   name, title, count, handleChange, countLabel, withCount, className
}) => (
    <div className={styles.select_filter}>
        <div className="row">
            <div className={className || 'col-md-10'}>
                <input
                    type="text"
                    className="form-control"
                    id={name}
                    name={name}
                    onChange={(event: any) => handleChange(name, FilterType.Input, event.target.value)}
                    placeholder={title}
                    onKeyPress={handleKeyPress}
                />
            </div>
            {
                withCount &&
                <div className="col-md-2">
                    <CountData count={count}/>
                    <span> {countLabel}</span>
                </div>
            }
        </div>
    </div>
);

export default InputFilterWithoutStoredValue;