import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { DateRangePicker } from 'react-dates';
import * as moment from 'moment';

import { DATA_SOURCE_TYPE_OPTIONS } from 'containers/Erm/constants';
import FormHelper from 'helpers/formHelper';
import ToggleAdapter from 'components/Shared/ui/ToggleAdapter';
import { EntityOtherName } from 'store/types';
import { ModalPayload } from 'store/GlobalModal/types';
import { ReactSelectAdapter } from 'components/Shared/ui/Select';
import { DEFAULT_DATE_FORMAT } from '../../../../constants';

const styles = require('./AddOtherName.scss');

interface PropsType {
    payload: ModalPayload;
    addOtherName: (entityId: string, newObj: EntityOtherName) => {
        type: string,
        entityId: string,
        newObj: EntityOtherName,
    };
    closeModal: () => {type: string};
}
interface StateType {
    validFrom?: moment.Moment | null;
    validTo?: moment.Moment | null;
    focusedInput?: any;
}

class AddOtherName extends React.PureComponent<PropsType, StateType> {

    state = {
        validFrom: null,
        validTo: null,
        focusedInput: null
    };

    handleChange = (validFrom: moment.Moment | null, validTo?: moment.Moment | null ) => {
        this.setState({validFrom, validTo});
    }

    handleSubmitForm = (values: any) => {
        let newObj = {
            ...values,
            data_source: FormHelper.getDataFromSelect(values.data_source),
            valid_from: FormHelper.setFormatDateTime(this.state.validFrom),
            valid_to: FormHelper.setFormatDateTime(this.state.validTo)
        };
        this.props.addOtherName(this.props.payload.payload.id, newObj);
    }

    render() {
        return (
            <div className={styles.add_form}>
                <Form
                    onSubmit={this.handleSubmitForm}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div className="form-group">
                                    <label htmlFor="">
                                        Source
                                    </label>
                                    <Field
                                        name="data_source"
                                        component={ReactSelectAdapter}
                                        options={DATA_SOURCE_TYPE_OPTIONS}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">
                                        Name
                                    </label>
                                    <Field
                                        className="form-control"
                                        name="other_name"
                                        component="input"
                                        type="text"
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="">Valid From</label>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="">Valid To</label>
                                        </div>
                                    </div>
                                    <DateRangePicker
                                        displayFormat={DEFAULT_DATE_FORMAT}
                                        startDatePlaceholderText="valid from"
                                        endDatePlaceholderText="valid to"
                                        showClearDates={true}
                                        startDateId="valid_from"
                                        endDateId="valid_to"
                                        isOutsideRange={() => false}
                                        startDate={this.state.validFrom}
                                        endDate={this.state.validTo}
                                        onDatesChange={({ startDate, endDate }) =>
                                            this.handleChange(startDate, endDate)}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label htmlFor="">
                                                Ignore for matching?
                                            </label>
                                            <Field
                                                name="ignore_matching"
                                                component={ToggleAdapter}
                                                defaultToggled={false}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="">
                                                Is Valid?
                                            </label>
                                            <Field
                                                name="is_valid"
                                                component={ToggleAdapter}
                                                defaultToggled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="buttons">
                                <button type="submit" className="btn btn-primary">
                                    Add
                                </button>
                            </div>
                        </form>
                    )}
                />
            </div>
        );
    }
}

export default AddOtherName;
