import { FlaggedEntityMessage } from 'store/Erm/FlaggedEntity/types';
import { DataSourceKeyTypeEnum, DataSourceTypeEnum } from 'store/Erm/types';

export enum RELATIONSHIP_TYPES {
    is_a_GP_of = 'is_a_GP_of',
    is_a_branch_of = 'is_a_branch_of',
    is_a_director_of = 'is_a_director_of',
    is_a_manager_of = 'is_a_manager_of',
    is_a_member_of = 'is_a_member_of',
    is_a_split_of = 'is_a_split_of',
    is_a_subsidiary_of = 'is_a_subsidiary_of',
    is_a_trustee_of = 'is_a_trustee_of',
    is_accountant_for = 'is_accountant_for',
    is_administrator_for = 'is_administrator_for',
    is_advised_by = 'is_advised_by',
    is_affiliated_with = 'is_affiliated_with',
    is_an_alias_of = 'is_an_alias_of',
    is_an_indirect_owner_of = 'is_an_indirect_owner_of',
    is_an_owner_of = 'is_an_owner_of',
    is_auditor_for = 'is_auditor_for',
    is_books_and_records_location_for = 'is_books_and_records_location_for',
    is_custodian_for = 'is_custodian_for',
    is_directly_consolidated_by = 'is_directly_consolidated_by',
    is_employed_as_indirect_officer_by = 'is_employed_as_indirect_officer_by',
    is_employed_as_officer_by = 'is_employed_as_officer_by',
    is_feeder_for = 'is_feeder_for',
    is_filing_signatory_for = 'is_filing_signatory_for',
    is_gp_manager_trustee_director_of = 'is_gp_manager_trustee_director_of',
    is_international_branch_of = 'is_international_branch_of',
    is_marketer_for = 'is_marketer_for',
    is_owner_officer_of = 'is_owner_officer_of',
    is_prime_broker_for = 'is_prime_broker_for',
    is_relying_adviser_of = 'is_relying_adviser_of',
    is_reporting_adviser_to = 'is_reporting_adviser_to',
    is_sub_advised_by = 'is_sub_advised_by',
    is_ultimately_consolidated_by = 'is_ultimately_consolidated_by',
    is_under_common_control_with = 'is_under_common_control_with',
    is_class_of = 'is_class_of',
    is_series_of = 'is_series_of',
    is_capital_account_of = 'is_capital_account_of',
    is_underwriter_for = 'is_underwriter_for',
    is_top_10_broker_for = 'is_top_10_broker_for',
    is_loc_provider_for = 'is_loc_provider_for',
    is_broker_dealer_for = 'is_broker_dealer_for',
    is_sec_lending_agent_for = 'is_sec_lending_agent_for',
    is_cash_collateral_manager_for = 'is_cash_collateral_manager_for',
    is_pricing_service_for = 'is_pricing_service_for',
    is_shareholder_servicing_agent_for = 'is_shareholder_servicing_agent_for',
    is_uit_sponsor_for = 'is_uit_sponsor_for',
}

export const RELATIONSHIP_TYPES_SELECT = [
    {label: 'Is a GP of', value: RELATIONSHIP_TYPES.is_a_GP_of},
    {label: 'Is a branch of', value: RELATIONSHIP_TYPES.is_a_branch_of},
    {label: 'Is a director of', value: RELATIONSHIP_TYPES.is_a_director_of},
    {label: 'Is a manager of', value: RELATIONSHIP_TYPES.is_a_manager_of},
    {label: 'Is a member of', value: RELATIONSHIP_TYPES.is_a_member_of},
    {label: 'Is a split of', value: RELATIONSHIP_TYPES.is_a_split_of},
    {label: 'Is a subsidiary of', value: RELATIONSHIP_TYPES.is_a_subsidiary_of},
    {label: 'Is a trustee of', value: RELATIONSHIP_TYPES.is_a_trustee_of},
    {label: 'Is accountant for', value: RELATIONSHIP_TYPES.is_accountant_for},
    {label: 'Is administrator for', value: RELATIONSHIP_TYPES.is_administrator_for},
    {label: 'Is advised by', value: RELATIONSHIP_TYPES.is_advised_by},
    {label: 'Is affiliated with', value: RELATIONSHIP_TYPES.is_affiliated_with},
    {label: 'Is an alias of', value: RELATIONSHIP_TYPES.is_an_alias_of},
    {label: 'Is an indirect owner of', value: RELATIONSHIP_TYPES.is_an_indirect_owner_of},
    {label: 'Is an owner of', value: RELATIONSHIP_TYPES.is_an_owner_of},
    {label: 'Is auditor for', value: RELATIONSHIP_TYPES.is_auditor_for},
    {label: 'Is books and records location for', value: RELATIONSHIP_TYPES.is_books_and_records_location_for},
    {label: 'Is custodian for', value: RELATIONSHIP_TYPES.is_custodian_for},
    {label: 'Is directly consolidated by', value: RELATIONSHIP_TYPES.is_directly_consolidated_by},
    {label: 'Is employed as indirect by', value: RELATIONSHIP_TYPES.is_employed_as_indirect_officer_by},
    {label: 'Is employed as officer by', value: RELATIONSHIP_TYPES.is_employed_as_officer_by},
    {label: 'Is feeder for', value: RELATIONSHIP_TYPES.is_feeder_for},
    {label: 'Is filing signatory for', value: RELATIONSHIP_TYPES.is_filing_signatory_for},
    {label: 'Is gp manager trustee director of', value: RELATIONSHIP_TYPES.is_gp_manager_trustee_director_of},
    {label: 'Is international branch of', value: RELATIONSHIP_TYPES.is_international_branch_of},
    {label: 'Is marketer for', value: RELATIONSHIP_TYPES.is_marketer_for},
    {label: 'Is owner officer of', value: RELATIONSHIP_TYPES.is_owner_officer_of},
    {label: 'Is prime broker of', value: RELATIONSHIP_TYPES.is_prime_broker_for},
    {label: 'Is relying adviser of', value: RELATIONSHIP_TYPES.is_relying_adviser_of},
    {label: 'Is reporting adviser to', value: RELATIONSHIP_TYPES.is_reporting_adviser_to},
    {label: 'Is sub advised by', value: RELATIONSHIP_TYPES.is_sub_advised_by},
    {label: 'Is ultimately consolidated by', value: RELATIONSHIP_TYPES.is_ultimately_consolidated_by},
    {label: 'Is under common control with', value: RELATIONSHIP_TYPES.is_under_common_control_with},
    {label: 'Is class of', value: RELATIONSHIP_TYPES.is_class_of},
    {label: 'Is series of', value: RELATIONSHIP_TYPES.is_series_of},
    {label: 'Is capital account of', value: RELATIONSHIP_TYPES.is_capital_account_of},
    {label: 'Is underwriter for', value: RELATIONSHIP_TYPES.is_underwriter_for},
    {label: 'Is top 10 broker for', value: RELATIONSHIP_TYPES.is_top_10_broker_for},
    {label: 'Is line of credit provider for', value: RELATIONSHIP_TYPES.is_loc_provider_for},
    {label: 'Is broker dealer for', value: RELATIONSHIP_TYPES.is_broker_dealer_for},
    {label: 'Is security leading agent for', value: RELATIONSHIP_TYPES.is_sec_lending_agent_for},
    {label: 'Is cash collateral manager for', value: RELATIONSHIP_TYPES.is_cash_collateral_manager_for},
    {label: 'Is pricing service for', value: RELATIONSHIP_TYPES.is_pricing_service_for},
    {label: 'Is shareholder servicing agent for', value: RELATIONSHIP_TYPES.is_shareholder_servicing_agent_for},
    {label: 'Is UIT sponsor for', value: RELATIONSHIP_TYPES.is_uit_sponsor_for},
];

export const AUDIT_TRAIL_EVENT_TYPES = [
    { label: 'Entity created', value: 'Entity_created' },
    { label: 'Entity deleted', value: 'Entity_deleted' },
    { label: 'Entity updated', value: 'Entity_updated' },
    { label: 'Entity start relationship created', value: 'Entity_start_relationship_created' },
    { label: 'Entity start relationship deleted', value: 'Entity_start_relationship_deleted' },
    { label: 'Entity start relationship updated', value: 'Entity_start_relationship_updated' },
    { label: 'Entity end relationship created', value: 'Entity_end_relationship_created' },
    { label: 'Entity end relationship deleted', value: 'Entity_end_relationship_deleted' },
    { label: 'Entity end relationship updated', value: 'Entity_end_relationship_updated' },
    { label: 'Entity data source created', value: 'Entity_data_source_created' },
    { label: 'Entity data source deleted', value: 'Entity_data_source_deleted' },
    { label: 'Entity data source updated', value: 'Entity_data_source_updated' },
    { label: 'Entity other names created', value: 'Entity_other_names_created' },
    { label: 'Entity other names deleted', value: 'Entity_other_names_deleted' },
    { label: 'Entity other names updated', value: 'Entity_other_names_updated' },
    { label: 'Consumer Entity added', value: 'consumer_Entity_added' },
    { label: 'Consumer Entity deleted', value: 'consumer_Entity_deleted' },
    { label: 'Consumer Entity updated', value: 'consumer_Entity_updated' },
    { label: 'Consumer Entity updated without changes', value: 'consumer_Entity_updated_without_changes' },
    { label: 'Consumer Entity start relationship added', value: 'consumer_Entity_start_relationship_added' },
    { label: 'Consumer Entity start relationship deleted', value: 'consumer_Entity_start_relationship_deleted' },
    { label: 'Consumer Entity start relationship updated', value: 'consumer_Entity_start_relationship_updated' },
    {
        label: 'Consumer Entity start relationship updated without changes',
        value: 'consumer_Entity_start_updated_without_changes'
    },
    { label: 'Consumer Entity end relationship added', value: 'consumer_Entity_end_relationship_added' },
    { label: 'Consumer Entity end relationship deleted', value: 'consumer_Entity_end_relationship_deleted' },
    { label: 'Consumer Entity end relationship updated', value: 'consumer_Entity_end_relationship_updated' },
    {
        label: 'Consumer Entity end relationship updated without changes',
        value: 'consumer_Entity_end_relationship_updated_without_changes'
    },
    { label: 'Consumer Entity data source added', value: 'consumer_Entity_data_source_added' },
    { label: 'Consumer Entity data source deleted', value: 'consumer_Entity_data_source_deleted' },
    { label: 'Consumer Entity data source updated', value: 'consumer_Entity_data_source_updated' },
    {
        label: 'Consumer Entity data source updated without changes',
        value: 'consumer_Entity_data_source_updated_without_changes'
    },
    { label: 'Consumer Entity other names added', value: 'consumer_Entity_other_names_added' },
    { label: 'Consumer Entity other names deleted', value: 'consumer_Entity_other_names_deleted' },
    { label: 'Consumer Entity other names updated', value: 'consumer_Entity_other_names_updated' },
    {
        label: 'Consumer Entity other names updated without changes',
        value: 'consumer_Entity_other_names_updated_without_changes'
    },
];

export const FLAGGED_ENTITIES_EVENT_TYPE = [
    {label: 'Entity added', value: 'entity_added'},
    {label: 'Entity updated', value: 'entity_updated'},
    {label: 'Entity removed', value: 'entity_removed'},
    {label: 'Entity withdrawn', value: 'entity_withdrawn'},
    {label: 'Relationship added', value: 'relationship_added'},
    {label: 'Relationship removed', value: 'relationship_removed'}
];

export enum ENTITY_TYPES {
    firm = 'firm',
    fund = 'fund',
    service_provider = 'service_provider',
    individual = 'individual',
    group = 'group',
    combined = 'combined',
    unknown = 'unknown',
}

export const ENTITY_TYPES_SELECT = [
    {label: 'Firm', value: ENTITY_TYPES.firm},
    {label: 'Fund', value: ENTITY_TYPES.fund},
    {label: 'Service provider', value: ENTITY_TYPES.service_provider},
    {label: 'Individual', value: ENTITY_TYPES.individual},
    {label: 'Group', value: ENTITY_TYPES.group},
    {label: 'Combined', value: ENTITY_TYPES.combined},
    {label: 'Unknown', value: ENTITY_TYPES.unknown},
];

export const ENTITY_TYPES_SELECT_WITHOUT_UNKNOWN = [
    {label: 'Firm', value: ENTITY_TYPES.firm},
    {label: 'Fund', value: ENTITY_TYPES.fund},
    {label: 'Service provider', value: ENTITY_TYPES.service_provider},
    {label: 'Group', value: ENTITY_TYPES.group},
    {label: 'Combined', value: ENTITY_TYPES.combined},
    {label: 'Individual', value: ENTITY_TYPES.individual},
];

export const ENTITY_STATUSES = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive'}
];

export const DATA_SOURCE_TYPE_LABELS = {
    [DataSourceTypeEnum.LEI]: 'LEI',
    [DataSourceTypeEnum.ADV]: 'ADV',
    [DataSourceTypeEnum.EDGAR]: 'Edgar',
};

export const DATA_SOURCE_TYPE_OPTIONS = [
    { label: 'Unknown', value: 'unknown' },
    { label: DATA_SOURCE_TYPE_LABELS[DataSourceTypeEnum.LEI], value: DataSourceTypeEnum.LEI },
    { label: DATA_SOURCE_TYPE_LABELS[DataSourceTypeEnum.ADV], value: DataSourceTypeEnum.ADV },
    { label: DATA_SOURCE_TYPE_LABELS[DataSourceTypeEnum.EDGAR], value: DataSourceTypeEnum.EDGAR },
];

export const DATA_SOURCE_KEY_TYPE_LABELS = {
    [DataSourceKeyTypeEnum.LEI]: 'LEI',
    [DataSourceKeyTypeEnum.SEC]: 'SEC',
    [DataSourceKeyTypeEnum.CRD]: 'CRD',
    [DataSourceKeyTypeEnum.TICKER]: 'Ticker',
    [DataSourceKeyTypeEnum.CIK]: 'CIK',
    [DataSourceKeyTypeEnum.SERIES_ID]: 'MF Series ID',
    [DataSourceKeyTypeEnum.CLASS_ID]: 'MF Class ID',
};

export const DATA_SOURCE_KEY_TYPE_OPTIONS = [
    { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.LEI], value: DataSourceKeyTypeEnum.LEI },
    { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.SEC], value: DataSourceKeyTypeEnum.SEC },
    { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.CRD], value: DataSourceKeyTypeEnum.CRD },
    { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.TICKER], value: DataSourceKeyTypeEnum.TICKER },
    { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.CIK], value: DataSourceKeyTypeEnum.CIK },
    { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.SERIES_ID], value: DataSourceKeyTypeEnum.SERIES_ID },
    { label: DATA_SOURCE_KEY_TYPE_LABELS[DataSourceKeyTypeEnum.CLASS_ID], value: DataSourceKeyTypeEnum.CLASS_ID },
];

export const ENTITY_VALUE: string = 'entity';
export const MESSAGE_VALUE: string = 'message';
export const OTHER_VALUE: string = 'other';

export const FROM_ENTITY_LABEL: string = '(Entity)';
export const FROM_MESSAGE_LABEL: string = '(Message)';
export const OTHER_LABEL: string = 'Other';

// from extra_data.data_type
export const DATA_TYPE_CONFLICT = 'conflict';
// from row.error_type
export const ERROR_TYPE_CONFLICT = 'conflict';
export const ERROR_TYPE_AMBIGUOUS = 'ambiguous';

export const ACTION_LABEL_RESOLVE_CONFLICTS = 'Resolve Conflicts';
export const ACTION_LABEL_ASSOCIATE_TO_EXISTING = 'Associate to existing entity';

export const DEFAULT_FLAGGED_ENTITY_MESSAGE: FlaggedEntityMessage = {
    dataSource: '',
    entityName: '',
    entity: {
        id: '',
        legalName: '',
        entityType: '',
        dataSources: [],
    },
    eventMessage: {
        crd: '',
        lei: '',
        sec: '',
        legalName: '',
        entityType: '',
        dataSourceId: '',
    },
};

export const RADIO_BUTTONS_CONFIG = {
    legalName: [{ label: '', value: ENTITY_VALUE }, { label: '', value: MESSAGE_VALUE},
        { label: 'Other: ', value: OTHER_VALUE},
    ],
    entityType: [{ label: '', value: ENTITY_VALUE }, { label: '', value: MESSAGE_VALUE},
        { label: 'Other: ', value: OTHER_VALUE },
    ],
};

export const RADIO_BUTTONS_CONFIG_WITHOUT_OTHER_VALUE = {
    legalName: [{ label: '', value: ENTITY_VALUE }, { label: '', value: MESSAGE_VALUE}],
    entityType: [{ label: '', value: ENTITY_VALUE }, { label: '', value: MESSAGE_VALUE}],
};