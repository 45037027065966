import { put, all, takeEvery } from 'redux-saga/effects';

import * as ermApi from '../../../services/erm';
import { GET_AUDIT_TRAIL } from './actions';
import { doneActionFail, doneActionSuccess, initAction } from '../../Actions/actionCreators';
import { setAuditTrail } from './actionCreators';

function* getAuditTrail(action: any) {

    try {
        yield put(initAction(action.type));
        const entity = yield ermApi.getAuditTrailEntities(action.filter);
        yield put(setAuditTrail(entity));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchGetEntity() {
    yield takeEvery(GET_AUDIT_TRAIL, getAuditTrail);
}

export default function* root() {
    yield all([
        watchGetEntity(),
    ]);
}