import * as React from 'react';
import Modal from './Modal';

interface Props {
    isOpened: boolean;
    toggle: () => any;
    no: () => any;
    yes: () => any;
    title: string;
    body: string;
}

const ConfirmModal: React.FunctionComponent<Props> = ({body, title, isOpened, toggle, no, yes}) => {
    const buttons = [
        {color: 'success', title: 'Yes', onClick: yes},
        {color: 'danger', title: 'No', onClick: no},
    ];

    return (
        <Modal
            isOpened={isOpened}
            toggle={toggle}
            title={title}
            body={body}
            buttons={buttons}
            size="md"
        />
    );
};

export default ConfirmModal;