import { combineReducers } from 'redux';

import { actionsReducer } from './Actions/reducer';
import { authReducer } from './Auth/reducer';
import  ermReducer  from './Erm/reducer';
import { headersReducer } from './VisibleHeaders/reducer';
import { filtersReducer } from './Filters/reducer';
import { globalModalReducer } from './GlobalModal/reducer';
import modalReducer from './Modal/reducer';
import { rcaReducer } from './Rca/reducer';
import { usersReducer } from './Users/reducer';
import { edgarReducer } from './Edgar/reducer';

export default combineReducers({
    actions: actionsReducer,
    auth: authReducer,
    erm: ermReducer,
    headers: headersReducer,
    filters: filtersReducer,
    globalModal: globalModalReducer,
    modal: modalReducer,
    rca: rcaReducer,
    users: usersReducer,
    edgar: edgarReducer,
});