import {
    EXPORT_FORMS, GET_ARCHIVE_LIST, GET_D_FROM_DATA, GET_FILING_ENTITY_LIST, GET_NCEN_FROM_DATA,
    INIT_REDOWNLOADING_THE_ARCHIVE, SET_ARCHIVE_LIST, SET_D_FROM_DATA, SET_FILING_ENTITY_LIST, SET_NCEN_FROM_DATA,
} from './actions';
import { Archive, DFormData, FilingEntity, NCENFormData } from './types';
import { FiltersState } from 'store/Filters/types';

export const getArchiveList = (filter: FiltersState) => ({
    type: GET_ARCHIVE_LIST,
    filter
});

export const setArchiveList = (archives: Archive[], count: number) => ({
    type: SET_ARCHIVE_LIST,
    archives,
    count,
});

export const initRedownloadingTheArchive = (archiveId: number) => ({
    type: INIT_REDOWNLOADING_THE_ARCHIVE,
    archiveId
});

export const getFilingEntityList = (filter: FiltersState) => ({
    type: GET_FILING_ENTITY_LIST,
    filter
});

export const setFilingEntityList = (filingEntities: FilingEntity[], count: number) => ({
    type: SET_FILING_ENTITY_LIST,
    filingEntities,
    count,
});

export const exportForms = (filter: FiltersState) => ({
    type: EXPORT_FORMS,
    filter,
});

export const getNCENFormData = (id: string) => ({
    type: GET_NCEN_FROM_DATA,
    id,
});

export const setNCENFormData = (filingEntityInfo: Partial<FilingEntity> | null, ncenForm: NCENFormData | null) => ({
    type: SET_NCEN_FROM_DATA,
    filingEntityInfo,
    ncenForm,
});

export const getDFormData = (id: string) => ({
    type: GET_D_FROM_DATA,
    id,
});

export const setDFormData = (filingEntityInfo: Partial<FilingEntity> | null, dForm: DFormData | null) => ({
    type: SET_D_FROM_DATA,
    filingEntityInfo,
    dForm,
});