import { Action } from 'redux';

import { Entity, EntityProperties } from './entity';
import { FiltersState } from 'store/Filters/types';
import { EntityOtherName } from 'store/types';

export const GET_ENTITIES = 'store/erm/entity/get';
export const GET_ENTITY_PROPERTIES = 'store/erm/entity/properties/get';
export const SET_ENTITIES = 'store/erm/entity/set';
export const CREATE_NEW_ENTITY = 'store/erm/entity/create';
export const SET_ENTITY_PROPERTIES = 'store/erm/entity/properties/set';
export const SET_ENTITY_AUDIT_TRAIL = 'store/erm/entity/audit/set';
export const SET_ENTITY_RELATIONSHIPS = 'store/erm/entity/relationships/set';
export const UPDATE_ENTITY_PROPERTIES = 'store/erm/entity/properties/update';
export const UPDATE_ENTITY_OTHER_NAME = 'store/erm/entity/other_name/update';
export const SWAP_LEGAL_AND_OTHER_NAME = 'store/erm/entity/other_name/swap/legal_name';
export const ADD_OTHER_NAME = 'store/erm/entity/othername/add';
export const PROPAGATE_ENTITY = 'store/erm/entity/entity/propagate';
export const DELETE_DATASOURCE_BY_ID = 'store/erm/entity/datasource/delete';
export const EDIT_DATASOURCE = 'store/erm/entity/datasource/edit';
export const ADD_DATASOURCE = 'store/erm/entity/datasource/add';
export const CREATE_DATA_SOURCES = 'store/erm/entity/datasources/create';

export interface GetEntitiesAction extends Action {
    filter: FiltersState;
}

export interface GetEntityPropertiesAction extends Action {
    id: any;
}

export interface CreateNewEntityAction extends Action {
    newEntity: any;
    dataSources: any[];
}

export interface SetEntitiesAction extends Action {
    entities: Entity[];
    count: number;
}

export interface SetEntityAuditTrailAction extends Action {
    payload: any;
}

export interface SetEntityRelationshipsAction extends Action {
    payload: any;
}

export interface UpdateEntityPropertiesAction extends Action {
    payload: any;
}

export interface SetEntityPropertiesAction extends Action {
    entityProperties: EntityProperties;
}

export interface EditDatasourceAction extends Action {
    payload: any;
}

export interface AddDatasourceAction extends Action {
    payload: any;
}

export interface CreateDataSourcesAction extends Action {
    entityId: string;
    dataSources: any[];
}

export interface AddEntityOtherNameAction extends Action {
    entityId: number;
    newObj: EntityOtherName;
}

export interface UpdateEntityOtherNameAction extends Action {
    entityId: number;
    otherNameId: number;
    updateData: object;
}

export interface SwapEntityLegalNameAndOtherNameAction extends Action {
    entityId: number;
    otherNameId: number;
}

export interface PropagateEntityAction extends Action {
    entityId: string;
}

export type EntityAction = SetEntitiesAction
    | SetEntityPropertiesAction
    | CreateNewEntityAction
    | EditDatasourceAction
    | AddDatasourceAction
    | CreateDataSourcesAction
    | UpdateEntityOtherNameAction
    | SwapEntityLegalNameAndOtherNameAction
    | AddEntityOtherNameAction
    | PropagateEntityAction;
