import * as React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import CreateNewEntity from 'containers/Erm/Actions/FlaggedEntitiesActions/CreateNewEntity';
import AssociateToExisting from 'containers/Erm/Actions/FlaggedEntitiesActions/AssociateToExisting';
import ViewMessage from 'containers/Erm/Actions/FlaggedEntitiesActions/ViewMessage';
import ViewMessageRelationships from 'containers/Erm/Actions/FlaggedRelationshipsActions/ViewMessage';
import ModifyMessage from 'containers/Erm/Actions/FlaggedRelationshipsActions/ModifyMessage';
import SuppressFlaggedEntities from 'containers/Erm/Actions/FlaggedEntitiesActions/SuppressFlaggedEntities';
import SuppressFlaggedRelationships
    from 'containers/Erm/Actions/FlaggedRelationshipsActions/SuppressFlaggedRelationships';
import Relationship from 'containers/Erm/Actions/RelationshipsActions/Relationship';
import DeleteDatasource from 'containers/Erm/Actions/Properties/DeleteDatasource';
import EditDatasource from 'containers/Erm/Actions/Properties/EditDatasource';
import AddDatasource from 'containers/Erm/Actions/Properties/AddDatasource';
import DeleteRelationship from 'containers/Erm/Actions/RelationshipsActions/DeleteRelationship';
import { SetCloseGlobalModalAction } from 'store/GlobalModal/actions';

import {
    ErmFlaggedEntitiesActions, ErmFlaggedRelationshipsActions, ErmRelationshipRecordsActions, ErmRelationshipsActions
} from '../../../constants';
import { ModalPayload } from 'store/GlobalModal/types';
import AddOtherName from 'containers/Erm/Actions/Properties/AddOtherName';

interface Props {
    isOpen: boolean;
    name: string;
    payload: ModalPayload;
    setCloseGlobalModal: () => SetCloseGlobalModalAction;
}
const styles = require('./GlobalModal.scss');
class GlobalModal extends React.PureComponent<Props> {

    state = {
        title: '',
    };

    setTitle = (title: string) => {
        this.setState({title});
    }

    handleToggle = () => {
        this.props.setCloseGlobalModal();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.name !== this.props.name) {
            let title: string = '';
            switch (nextProps.name) {
                case ErmRelationshipRecordsActions.create_new_relationship:
                    title = 'Create New Relationship';
                    break;
                case ErmFlaggedEntitiesActions.view_message:
                    title = ErmFlaggedEntitiesActions.view_message;
                    break;
                case ErmFlaggedEntitiesActions.associate_to_existing:
                    title = ErmFlaggedEntitiesActions.associate_to_existing;
                    break;
                case ErmFlaggedEntitiesActions.create_new_entity:
                    title = ErmFlaggedEntitiesActions.create_new_entity;
                    break;
                case ErmFlaggedRelationshipsActions.modify_message:
                    title = 'Modify Message Flagged Relationships';
                    break;
                case ErmFlaggedRelationshipsActions.view_message:
                    title = ErmFlaggedRelationshipsActions.view_message;
                    break;
                case ErmRelationshipsActions.edit:
                    title = 'Edit Relationship';
                    break;
                case ErmRelationshipsActions.delete:
                    title = 'Delete Relationship';
                    break;
                case ErmFlaggedEntitiesActions.delete_messages:
                    title = 'Suppress message';
                    break;
                case ErmFlaggedRelationshipsActions.delete_messages:
                    title = 'Suppress message';
                    break;
                case 'DeleteDatasource':
                    title = 'Delete datasource';
                    break;
                case 'EditDatasource':
                    title = 'Edit datasource';
                    break;
                case 'AddDatasource':
                    title = 'Add datasource';
                    break;
                case 'AddOtherName':
                    title = 'Add Entity Other Name';
                    break;
                default:
                    title = 'Not found Component';
            }
            this.setState({title});
        }
    }

    render() {
        let componentName = null;
        let title: string = '';
        let size = 'md';
        switch (this.props.name) {
            case ErmFlaggedEntitiesActions.view_message:
                title = ErmFlaggedEntitiesActions.view_message;
                componentName = <ViewMessage setTitle={this.setTitle}/>;
                break;
            case ErmRelationshipRecordsActions.create_new_relationship:
                title = ErmRelationshipRecordsActions.create_new_relationship;
                componentName = <ModifyMessage setTitle={this.setTitle}/>;
                break;
            case ErmFlaggedEntitiesActions.associate_to_existing:
                title = ErmFlaggedEntitiesActions.associate_to_existing;
                size = 'lg';
                componentName = <AssociateToExisting setTitle={this.setTitle}/>;
                break;
            case ErmFlaggedEntitiesActions.create_new_entity:
                title = ErmFlaggedEntitiesActions.create_new_entity;
                componentName = <CreateNewEntity/>;
                break;
            case ErmFlaggedRelationshipsActions.modify_message:
                title = ErmFlaggedRelationshipsActions.modify_message;
                componentName = <ModifyMessage setTitle={this.setTitle}/>;
                break;
            case ErmFlaggedRelationshipsActions.view_message:
                title = ErmFlaggedRelationshipsActions.view_message;
                componentName = <ViewMessageRelationships setTitle={this.setTitle}/>;
                break;
            case ErmFlaggedEntitiesActions.delete_messages:
                title = 'Suppress message';
                componentName = <SuppressFlaggedEntities setTitle={this.setTitle}/>;
                break;
            case ErmFlaggedRelationshipsActions.delete_messages:
                title = 'Suppress message';
                componentName = <SuppressFlaggedRelationships setTitle={this.setTitle}/>;
                break;
            case ErmRelationshipsActions.edit:
                componentName = <Relationship setTitle="Edit Relationship"/>;
                break;
            case ErmRelationshipsActions.delete:
                componentName = <DeleteRelationship />;
                break;
            case 'DeleteDatasource':
                componentName = <DeleteDatasource />;
                break;
            case 'EditDatasource':
                title = 'Edit datasource';
                componentName = <EditDatasource setTitle={this.setTitle}/>;
                break;
            case 'AddDatasource':
                title = 'Add datasource';
                componentName = <AddDatasource setTitle={this.setTitle}/>;
                break;
            case 'AddOtherName':
                title = 'Add Entity Other Name';
                componentName = <AddOtherName setTitle={this.setTitle}/>;
                break;
            default:
                title = 'Not found Component';
                componentName = 'Component is not found';
        }

        return (
            <div>
                <Modal
                    className={styles.global_modal}
                    size={size}
                    toggle={this.handleToggle}
                    isOpen={this.props.isOpen}
                >
                    <ModalHeader toggle={this.handleToggle}>{this.state.title}</ModalHeader>
                    <ModalBody>
                        {componentName}
                    </ModalBody>
                </Modal>
            </div>
        );
    }

}

export default GlobalModal;