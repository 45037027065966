import FlaggedEntity from 'store/Erm/FlaggedEntity/flaggedEntity';
import {
    GET_FLAGGED_ENTITIES,
    SET_FLAGGED_ENTITIES,
    DELETE_FLAGGED_ENTITIES,
    ASSIGN_FLAGGED_ENTITIES,
    REPROCESS_FLAGGED_ENTITIES,
    ASSOCIATE_ENTITY,
    RESOLVE_CONFLICT_ENTITY,
    BULK_REPROCESS_FLAGGED_ENTITY,
    ResolveConflictEntityAction,
    AssociateAndResolveEntityAction
} from 'store/Erm/FlaggedEntity/actions';
import { FiltersState } from 'store/Filters/types';
import { AssociateAndResolveBody, ResolveConflictBody } from './types';

export const getFlaggedEntities = (filter: FiltersState) => ({
    type: GET_FLAGGED_ENTITIES,
    filter
});

export const setFlaggedEntities = (flaggedEntity: FlaggedEntity) => ({
    type: SET_FLAGGED_ENTITIES,
    flaggedEntity
});

export const associateToExistingEntity = (
    flaggedMessageId: number, body: AssociateAndResolveBody, flaggedEntityName?: string, associatedEntityName?: string
): AssociateAndResolveEntityAction => ({
    type: ASSOCIATE_ENTITY,
    flaggedMessageId,
    body,
    flaggedEntityName,
    associatedEntityName,
});

export const assignFlaggedEntities = (payload: any) => ({
    type: ASSIGN_FLAGGED_ENTITIES,
    payload
});

export const reprocessFlaggedEntities = (payload: any) => ({
    type: REPROCESS_FLAGGED_ENTITIES,
    payload
});

export const resolveConflictEntity = (
    flaggedMessageId: number, body: ResolveConflictBody, flaggedEntityName?: string
): ResolveConflictEntityAction => ({
    type: RESOLVE_CONFLICT_ENTITY,
    flaggedMessageId,
    body,
    flaggedEntityName,
});

export const deleteFlaggedEntities = (payload: any) => ({
    type: DELETE_FLAGGED_ENTITIES,
    payload
});

export const bulkReprocessFlaggedEntity = () => ({
    type: BULK_REPROCESS_FLAGGED_ENTITY
});
