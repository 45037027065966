import Rca from './models/rca';
import { RcaStatus } from './types';

export const getSubmitterFullName = (rca: Rca) => {
    const submitter = rca.submitter_user;
    return submitter
        ? `${submitter.last_name} ${submitter.first_name}`
        : '';
};

export const getApproverFullName = (rca: Rca) => {
    const approver = rca.approver_user;
    return approver
        ? `${approver.last_name} ${approver.first_name}`
        : '';
};

/**
 * Adds new additional fields with submitter's and approver's full names
 */
export const prepareForList = (rca: Rca) => {
    return {
        ...rca,
        submitter_user: getSubmitterFullName(rca),
        approver_user: getApproverFullName(rca)
    };
};

export const isApproved = (rca: Rca) => {
    return rca.status === RcaStatus.Approved;
};

export const isDenied = (rca: Rca) => {
    return rca.status === RcaStatus.Denied;
};

export const isAwaiting = (rca: Rca) => {
    return rca.status === RcaStatus.AwaitingApproval;
};

export const isCreated = (rca: Rca) => {
    return rca.status === RcaStatus.AccountCreated;
};