import * as React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getModalParams, isModalOpen } from 'store/Modal/selectors';
import { AppState } from 'store/types';
import { closeModal } from 'store/Modal/actionCreators';
import { resetAttempts } from 'store/Users/actionCreators';
import ConfirmModal from 'components/Shared/Modal/ConfirmModal';
import { User } from 'store/Users/models/user';
import { messages } from 'store/Users/constants';

export const name = 'RESET_ATTEMPTS_USERS_MODAL';

interface Props {
    isOpened: boolean;
    params: User;
    toggle: () => any;
    resetAttempts: (id: number, closeModal: boolean) => any;
}

class ResetAttemptsModal extends React.PureComponent<Props> {
    handleYes = () => {
        const { params: user } = this.props;
        this.props.resetAttempts(user.id, true);
    }

    render() {
        const {isOpened, toggle} = this.props;
        return (
            <ConfirmModal
                isOpened={isOpened}
                toggle={toggle}
                no={toggle}
                yes={this.handleYes}
                title="Reset attempts"
                body={messages.QUESTION_RESET_ATTEMPTS}
            />
    );
    }
}

const mapStateToProps = (state: AppState) => ({
    isOpened: isModalOpen(state, {name}),
    params: getModalParams(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            toggle: closeModal,
            resetAttempts,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetAttemptsModal);
