import { OPEN_MODAL, CLOSE_MODAL } from './actions';
import { ModalParams } from './types';

export const openModal = (name: string, params: ModalParams = {} ) => ({
    type: OPEN_MODAL,
    name, params,
});

export const closeModal = () => ({
    type: CLOSE_MODAL,
});