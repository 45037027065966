import { RelationshipState } from './types';

import {
    GET_RELATIONSHIPS,
    SET_RELATIONSHIPS,
    GET_RELATIONSHIP,
    SET_RELATIONSHIP,
    CREATE_RELATIONSHIP,
    RelationshipAction,
    SetRelationshipAction,
    CreateRelationshipAction,
    SetRelationshipDetailAction,
} from './actions';

const initialState = {};

const reducer = (state: RelationshipState = initialState, action: RelationshipAction) => {
    switch (action.type) {
        case GET_RELATIONSHIPS:
            return state;
        case SET_RELATIONSHIPS:
            return {
                ...state,
                items: (<SetRelationshipAction> action).relationship
            };
        case GET_RELATIONSHIP:
            return state;
        case SET_RELATIONSHIP:
            return {
                ...state,
                itemDetail: (<SetRelationshipDetailAction> action).relationship
            };
        case CREATE_RELATIONSHIP:
            return state;
        default:
            return state;
    }
};

export default reducer;