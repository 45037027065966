import { connect } from 'react-redux';

import ChangeStatusModal from '../../components/Rca/ChangeStatusModal';
import { getModalParams, isModalOpen } from '../../store/Modal/selectors';
import { AppState } from '../../store/types';
import { closeModal } from '../../store/Modal/actionCreators';
import { approve } from '../../store/Rca/actionCreators';

export const name = 'APPROVE_RCA_MODAL';

const mapStateToProps = (state: AppState) => ({
    isOpened: isModalOpen(state, {name}),
    params: getModalParams(state),
    title: 'Approve RCA',
    body: 'Are you sure you want approve this RCA?',
});

const mapDispatchToProps = {
    toggle: closeModal,
    apply: approve
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatusModal);
