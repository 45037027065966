import * as React from 'react';
import UserButton from '../../Auth/UserButton';
import User from '../../../store/Auth/models/user';

const styles = require('./Header.scss');

interface Props {
    user: User;
    isAuthenticated: boolean;
    isInitialized: boolean;
    login: () => any;
    logout: () => any;
}

const Header: React.FunctionComponent<Props> = ({user, isAuthenticated, isInitialized, login, logout}) => (
    <div className={styles.header}>
        <UserButton
            user={user}
            isAuthenticated={isAuthenticated}
            isInitialized={isInitialized}
            login={login}
            logout={logout}
        />
    </div>
);

export default Header;