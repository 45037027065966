import * as React from 'react';
import { Link } from 'react-router-dom';

import { name as editModalName } from 'containers/Users/EditModal';
import { name as lockUnlockModalName } from 'containers/Users/LockUnlockModal';
import { name as resetAttemptsModalName } from 'containers/Users/ResetAttemptsModal';

import { User } from 'store/Users/models/user';
import * as userHelper from 'store/Users/userHelper';
import { OpenAction } from 'store/Modal/actions';
import { ModalParams } from 'store/Modal/types';

interface Props {
    user: User;
    openModal: (name: string, params: ModalParams) => OpenAction;
}

class ActionsCell extends React.PureComponent<Props> {

    locUnlockLink = (user: User) => userHelper.isLocked(user)
        ? (
            <Link to="#" onClick={this.unlock}>
                <span className="input-label">Unlock</span>
            </Link>
        ) : (
            <Link to="#" onClick={this.lock}>
                <span className="input-label">Lock</span>
            </Link>
        )

    edit = () => this.props.openModal(editModalName, this.props.user);
    lock = () => this.props.openModal(lockUnlockModalName, this.props.user);
    unlock = () => this.props.openModal(lockUnlockModalName, this.props.user);
    resetAttempts = () => this.props.openModal(resetAttemptsModalName, this.props.user);

    render() {
        return (
            <div>
                <Link to="#" onClick={this.edit}>
                    <span className="input-label">Edit</span>
                </Link>
                {' '}
                {this.locUnlockLink(this.props.user)}
                {' '}
                <Link to="#" onClick={this.resetAttempts}>
                    <span className="input-label">Reset attempts</span>
                </Link>
            </div>
        );
    }
}

export default ActionsCell;