import * as React from 'react';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui';

import Modal from '../Shared/Modal/Modal';
import { ValueTypeChoices } from 'store/types';
import { formatText } from 'helpers/tableHelper';

interface Props {
    isOpened: boolean;
    toggle: () => any;
    params: any;
}

const ViewModal: React.FunctionComponent<Props> = ({isOpened, toggle, params}) => {
    const buttons = [
        {color: 'primary', title: 'Close', onClick: toggle},
    ];
/*
            submitter_user: User | null;
            approver_user: User | null;

 */
    const fields = [
        {name: 'ap_id', title: 'Ap ID'},
        {name: 'account_name', title: 'Account name'},
        {name: 'account_status', title: 'Account status', valueType: ValueTypeChoices.ContainsUnderscore},
        {name: 'account_type', title: 'Account type', valueType: ValueTypeChoices.ContainsUnderscore},
        {name: 'initial_admin_last_name', title: 'Initial admin last name'},
        {name: 'initial_admin_first_name', title: 'Initial admin first name'},
        {name: 'initial_admin_email', title: 'Initial admin email'},
        {name: 'status', title: 'Status', valueType: ValueTypeChoices.ContainsUnderscore},
        {name: 'status_changed_at', title: 'Status changed at', valueType: ValueTypeChoices.DateTime},
        {name: 'created_at', title: 'Created at', valueType: ValueTypeChoices.DateTime}
    ];
    let body = (
        <Table>
            <TableBody displayRowCheckbox={false} showRowHover={true}>
                {fields.map((field, idx) => (
                    <TableRow key={idx}>
                        <TableRowColumn>
                            {field.title}
                        </TableRowColumn>
                        <TableRowColumn>
                            {field.hasOwnProperty('valueType')
                                ? formatText(params[field.name], field.valueType)
                                : params[field.name]
                            }
                        </TableRowColumn>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );

    return (
        <Modal
            isOpened={isOpened}
            toggle={toggle}
            title="View RCA"
            body={body}
            buttons={buttons}
            size="lg"
        />
    );
};

export default ViewModal;