import { createStore, applyMiddleware, Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducer';
import { AppState } from './types';

import { composeWithDevTools } from 'redux-devtools-extension';

import ActionsSagas from './Actions/sagas';
import AuthSagas from './Auth/sagas';

import EntitySagas from './Erm/Entity/sagas';
import RelationshipSagas from './Erm/Relationship/sagas';
import FlaggedEntitySagas from './Erm/FlaggedEntity/sagas';
import FlaggedRelationshipSagas from './Erm/FlaggedRelationship/sagas';
import AuditTrailSagas from './Erm/AuditTrail/sagas';
import MergeEntitySagas from './Erm/MergeEntity/sagas';
import RcaSagas from './Rca/sagas';
import UsersSagas from './Users/sagas';
import FiltersSagas from './Filters/sagas';
import EdgarSagas from './Edgar/sagas';

const isProduction = process.env.NODE_ENV === 'production';
const middleware: Middleware[] = [];

const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

export default function configureStore(initialState: AppState) {
    const enhancer = isProduction
        ? applyMiddleware(...middleware)
        : composeWithDevTools(applyMiddleware(...middleware));

    const store = createStore(rootReducer, initialState as any, enhancer);

    sagaMiddleware.run(ActionsSagas);
    sagaMiddleware.run(AuthSagas);
    sagaMiddleware.run(EntitySagas);
    sagaMiddleware.run(RelationshipSagas);
    sagaMiddleware.run(FlaggedEntitySagas);
    sagaMiddleware.run(FlaggedRelationshipSagas);
    sagaMiddleware.run(AuditTrailSagas);
    sagaMiddleware.run(MergeEntitySagas);
    sagaMiddleware.run(RcaSagas);
    sagaMiddleware.run(UsersSagas);
    sagaMiddleware.run(FiltersSagas);
    sagaMiddleware.run(EdgarSagas);

    return store;
}