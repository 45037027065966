import { combineReducers } from 'redux';

import entityReducer from './Entity/reducer';
import relationshipReducer from './Relationship/reducer';
import flaggedEntityReducer from './FlaggedEntity/reducer';
import flaggedRelationshipReducer from './FlaggedRelationship/reducer';
import auditTrailReducer from './AuditTrail/reducer';
import mergeEntityReducer from './MergeEntity/reducer';

export default combineReducers({
    entity: entityReducer,
    relationship: relationshipReducer,
    flaggedEntity: flaggedEntityReducer,
    flaggedRelationship: flaggedRelationshipReducer,
    auditTrail: auditTrailReducer,
    mergeEntity: mergeEntityReducer,
});