import * as React from 'react';

interface Props {
    text: string;
}

const PrettifiedJSON: React.FunctionComponent<Props> = ({text}) => (
    <div><pre>{JSON.stringify(text, null, 2)}</pre></div>
);

export default PrettifiedJSON;