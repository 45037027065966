import { Action } from 'redux';

import MergeEntity from './mergeEntity';
import { FiltersState } from 'store/Filters/types';

export const GET_MERGE_ENTITIES = 'store/erm/mergeEntities/get';
export const SET_MERGE_ENTITIES = 'store/erm/mergeEntities/set';
export const MERGE_ENTITIES = 'store/erm/mergeEntities/merge';
export const BULK_MERGE_ENTITIES = 'store/erm/mergeEntities/merge/bulk';

export interface GetMergeEntitiesAction extends Action {
    filter: FiltersState;
}

export interface SetMergeEntitiesAction extends Action {
    entities: MergeEntity[];
    count: number;
}

export interface MergeEntityAction extends Action {
    mergingEntityRowId: string;
}

export interface BulkMergeEntityAction extends Action {
    filter: FiltersState;
}

export type MergeEntityActions = SetMergeEntitiesAction;
