import * as React from 'react';
import { connect } from 'react-redux';

import { openModal } from 'store/Modal/actionCreators';
import ActionsCell from 'components/Users/ActionsCell';

const mapDispatchToProps = {
    openModal
};

export default connect(null, mapDispatchToProps)(ActionsCell);
