import MockAdapter from 'axios-mock-adapter';

export default (mock: MockAdapter) => {
    mock.onGet(/\/users\/info/).reply(200, {
        last_name: 'Last name',
        first_name: 'First name',
        token: 'DFSGDGWTEYRU45642DFGDFG'
    });

    // mock.onGet(/\/users\/info/).reply(500, {
    //     errors: ['error1', 'error2']
    // });
    // mock.onGet(/\/users\/info/).reply(500, 'String error response');
};