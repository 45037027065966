import * as React from 'react';
import Toggle from 'material-ui/Toggle';
import { colors } from '../../../scss/constants';

const ToggleAdapter = ({ input: { onChange }, label, ...rest }: any) => (
    <Toggle
        label={label}
        thumbSwitchedStyle={{backgroundColor: colors.blue}}
        trackSwitchedStyle={{backgroundColor: colors.blue}}
        labelStyle={{color: colors.superLightBlue}}
        thumbStyle={{backgroundColor: colors.blue }}
        trackStyle={{backgroundColor: colors.superLightBlue }}
        style={{width: 'auto'}}
        onToggle={(event, isInputChecked) => onChange(isInputChecked)}
        {...rest}
    />
);

export default ToggleAdapter;