import { toast } from 'react-toastify';

class NotificationHelper {
        static success (message?: string) {
            const notification = message || 'Success!';
            toast.success(notification, {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
            });
        }

        static error (message?: string) {
            const notification = message || 'Error';
            toast.error(notification, {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
            });
        }
}

export default NotificationHelper;