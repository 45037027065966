export const colors: any = {
    'blue': '#4A90E2',
    'white': '#FFFFFF',
    'lightBlue': '#5C9BE4',
    'superLightBlue': '#c7dbec',
    'super-blue-light': '#F0F8FF',
    'dark-blue': '#3379CC',
    'delimiter-gray': '#E0E0E0',
    'dirty-gray': '#F4F8F9',
};
