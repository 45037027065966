import * as React from 'react';
import { Form, Field } from 'react-final-form';

import { RELATIONSHIP_TYPES_SELECT } from 'containers/Erm/constants';
import FormHelper from 'helpers/formHelper';
import UiDateRangePicker from 'components/Shared/ui/UiDateRangePicker';
import ToggleAdapter from 'components/Shared/ui/ToggleAdapter';
import { ReactSelectAdapter } from 'components/Shared/ui/Select';

const styles = require('./Relationship.scss');

class Relationship extends React.PureComponent<any> {

    state = {
        validFrom: null,
        validTo: null,
        focusedInput: null
    };

    componentDidMount () {
        this.setState({
            validFrom: FormHelper.setMomentOrNull(this.props.item.valid_from),
            validTo: FormHelper.setMomentOrNull(this.props.item.valid_to)
        });

    }

    handleChange = (validFrom?: any, validTo?: any ) => {
        this.setState({ validFrom, validTo });
    }

    handleSubmitForm = (values: any) => {
        const item = this.props.item;
        let editObject = {
            ...values,
            start_entity: item.start_id,
            end_entity: item.end_id,
            relationship_type: FormHelper.getDataFromSelect(values.relationship_type),
            valid_from: FormHelper.setFormatDateTime(this.state.validFrom),
            valid_to: FormHelper.setFormatDateTime(this.state.validTo)
        };

        const payload = {
            entityId: this.props.id || item.start_id,
            id: item.id,
            editableObject: editObject,
            pageSource: this.props.pageSource
        };

        this.props.editRelationship(payload);
    }

    render() {
        let answer: any = '';
        const item = this.props.item;
        if (item ) {
            answer = (
                <div className={styles.relationship_form}>
                    <Form
                        onSubmit={this.handleSubmitForm}
                        initialValues={{ firstName: item.id,
                                         relationship_type: item.relationship_type,
                                         valid_from: item.valid_from,
                                         valid_to: item.valid_to,
                                         is_valid: item.is_valid
                        }}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="">
                                            Relationship type
                                        </label>
                                    <Field
                                        name="relationship_type"
                                        component={ReactSelectAdapter}
                                        options={RELATIONSHIP_TYPES_SELECT}
                                    />
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="">Valid From</label>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="">Valid To</label>
                                            </div>
                                        </div>
                                        <UiDateRangePicker
                                            startDate={this.state.validFrom}
                                            endDate={this.state.validTo}
                                            startTitle="valid from"
                                            endTitle="valid to"
                                            handleDateChange={this.handleChange}
                                        />
                                    </div>

                                </div>
                                <div>
                                    <label htmlFor="">
                                        Is Valid?
                                    </label>
                                    <Field
                                        name="is_valid"
                                        component={ToggleAdapter}
                                        defaultToggled={item.is_valid}
                                    />
                                </div>
                                <br/>
                                <div className="buttons">
                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        )}
                    />
                    <br/>
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td>id</td>
                                <td>{item.id}</td>
                            </tr>
                            <tr>
                                <td>start id</td>
                                <td>{item.start_id}</td>
                            </tr>
                            <tr>
                                <td>start name</td>
                                <td>{item.start_name}</td>
                            </tr>
                            <tr>
                                <td>start type</td>
                                <td>{item.start_type}</td>
                            </tr>
                            <tr>
                                <td>data source</td>
                                <td>{item.source}</td>
                            </tr>
                            <tr>
                                <td>ending id</td>
                                <td>{item.end_id}</td>
                            </tr>
                            <tr>
                                <td>end name</td>
                                <td>{item.end_name}</td>
                            </tr>
                            <tr>
                                <td>end type</td>
                                <td>{item.end_type}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        return answer;
    }
}

export default Relationship;