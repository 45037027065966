import * as React from 'react';
import { Table } from 'reactstrap';

import DataTableHeader from './DataTableHeader';
import DataTableBody from './DataTableBody';
import DataPagination from '../Pagination/Pagination';
import { HeaderSettings } from 'store/types';
import { FilterDecoratorChange } from 'decorators/FilterDecorator';

interface Props {
    count?: number;
    header: HeaderSettings[];
    rows: Object[];
    handlePageChange?: (limit: number, offset: number) => void;
    currentPage?: number;
    handleChange: FilterDecoratorChange;
    ordering?: string;
    orderSign?: string;
    handleRowClick?: any;
    selectedRowId?: number;
    rowId?: any;
    filtersItems?: any;
    paginator?: any;
}

const styles = require('./DataTable.scss');

const DataTable: React.FunctionComponent<Props> = (
    {
        rowId, count, header, rows,
        handlePageChange, currentPage, handleChange,
        ordering, orderSign, handleRowClick, selectedRowId, filtersItems, paginator = DataPagination
    }
) => {
    const Paginator = paginator;
    return (
        <div>
            <div className={styles.datatable}>
                <div className="table-responsive">
                    <Table className="table table-bordered table-striped table-hover">
                        <DataTableHeader
                            header={header}
                            handleChange={handleChange}
                            ordering={ordering}
                            orderSign={orderSign}
                        />
                        <DataTableBody
                            filtersItems={filtersItems}
                            rows={rows}
                            header={header}
                            handleRowClick={handleRowClick}
                            selectedRowId={selectedRowId}
                            rowId={rowId}
                        />
                    </Table>
                </div>
            </div>
            {
                handlePageChange
                && <Paginator
                    count={count}
                    handleClick={handlePageChange}
                    currentPage={currentPage}
                />
            }
        </div>
    );
};

export default DataTable;
