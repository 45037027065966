import ApiHelper, { API_EDGAR_URL } from 'helpers/apiHelper';
import { FiltersState } from 'store/Filters/types';
import { ArchiveStatus } from 'store/Edgar/types';

export const getArchives = async (filter: FiltersState) => {
    return ApiHelper.doRequest(
        `${API_EDGAR_URL}/daily-archives/`,
        'get',
        {...filter}
    );
};

export const initRedownloadingArchive = async (archiveId: number) => {
    return ApiHelper.doRequest(
        `${API_EDGAR_URL}/daily-archives/${archiveId}/`,
        'put',
        {
            status: ArchiveStatus.QueuedDownloading
        }
    );
};

export const getFilingEntities = async (filter: FiltersState) => {
    return ApiHelper.doRequest(
        `${API_EDGAR_URL}/filing-entities/`,
        'get',
        {...filter}
    );
};

export const exportForms = async (filter: FiltersState) => {
    const {limit, offset, ...restFilters} = filter;
    return await ApiHelper.doRequest(
        `${API_EDGAR_URL}/filing-entities/export/`,
        'get',
        {...restFilters}
    );
};

export const getNCENForm = async (filingEntityId: string) => {
    return ApiHelper.doRequest(
        `${API_EDGAR_URL}/filing-entities/${filingEntityId}/ncen-form/`,
        'get',
    );
};

export const getDForm = async (filingEntityId: string) => {
    return ApiHelper.doRequest(
        `${API_EDGAR_URL}/filing-entities/${filingEntityId}/d-form/`,
        'get',
    );
};