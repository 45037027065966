import { SET_FILTERS, CALL_APPLY_FILTERS, SET_FILTERS_ITEMS, GET_FILTERS_ITEMS } from './actions';
import { FiltersState } from './types';

export const setFilters = (filterName: string, payload: FiltersState) => ({
    type: SET_FILTERS,
    filterName, payload
});

export const callApplyFilters = (currentFilter: string, actionType: string, otherFilter: FiltersState = {}) => ({
    type: CALL_APPLY_FILTERS,
    currentFilter,
    actionType,
    otherFilter,
});

export const getFiltersItems = (filterItemName: string, payload: any) => ({
    type: GET_FILTERS_ITEMS,
    filterItemName, payload
});

export const setFiltersItems = (filterItemName: string, payload: any) => ({
    type: SET_FILTERS_ITEMS,
    filterItemName, payload
});
