import * as React from 'react';
import { connect } from 'react-redux';

import EditModal from 'components/Users/EditModal';
import { getModalParams, isModalOpen } from 'store/Modal/selectors';
import { AppState, SelectItem } from 'store/types';
import { closeModal } from 'store/Modal/actionCreators';
import { update } from 'store/Users/actionCreators';
import { UpdateRequest } from 'store/Users/models/updateRequest';
import { USER_GLOBAL_ROLES_SELECT } from 'store/Users/constants';

export const name = 'UPDATE_USER_MODAL';

interface Props {
    isOpened: boolean;
    globalRoles: SelectItem[];
    params: {
        id: number;
        global_role: string;
    };
    toggle: () => any;
    update: (id: number, request: UpdateRequest, closeModal: boolean) => any;
}

class EditModalContainer extends React.PureComponent<Props, UpdateRequest> {
    constructor(props: Props) {
        super(props);

        this.state = {
            global_role: props.params.global_role
        };
    }

    componentWillReceiveProps(newProps: Props) {
        if (!newProps.isOpened) {
            this.cleanState();
        } else if (!this.props.isOpened) {
            this.setState({
                global_role: newProps.params.global_role
            });
        }
    }

    handleChange = (field: keyof UpdateRequest, value: string) => {
        this.setState({
            [field]: value
        });
    }

    handleUpdate = () => {
        const { id } = this.props.params;
        this.props.update(id, {...this.state}, true);

    }

    render() {
        return (
            <EditModal
                {...this.props}
                apply={this.handleUpdate}
                change={this.handleChange}
                request={this.state}
            />
        );
    }

    private cleanState() {
        let emptyState = {...this.state};
        Object.keys(emptyState).forEach(key => emptyState[key] = '');
        this.setState(emptyState);
    }
}

const mapStateToProps = (state: AppState) => ({
    isOpened: isModalOpen(state, {name}),
    globalRoles: USER_GLOBAL_ROLES_SELECT,
    params: getModalParams(state),
});

const mapDispatchToProps = {
    toggle: closeModal,
    update
};

export default connect(mapStateToProps, mapDispatchToProps)(EditModalContainer);
