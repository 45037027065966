import { Entity, EntityProperties } from 'store/Erm/Entity/entity';
import {
    GET_ENTITIES,
    GET_ENTITY_PROPERTIES,
    SET_ENTITIES,
    CREATE_NEW_ENTITY,
    SET_ENTITY_PROPERTIES,
    SET_ENTITY_AUDIT_TRAIL,
    SET_ENTITY_RELATIONSHIPS,
    UPDATE_ENTITY_PROPERTIES,
    DELETE_DATASOURCE_BY_ID,
    EDIT_DATASOURCE,
    ADD_DATASOURCE,
    CREATE_DATA_SOURCES,
    UPDATE_ENTITY_OTHER_NAME,
    SWAP_LEGAL_AND_OTHER_NAME,
    ADD_OTHER_NAME,
    PROPAGATE_ENTITY,
} from 'store/Erm/Entity/actions';
import { ModalPayload } from 'store/GlobalModal/types';
import { FiltersState } from 'store/Filters/types';
import { EntityOtherName } from 'store/types';
import { DefaultStateErmEntity } from '../../../constants';

export const getEntities = (filter: FiltersState = DefaultStateErmEntity.filter) => ({
    type: GET_ENTITIES,
    filter
});

export const getEntityProperties = (id: any) => ({
    type: GET_ENTITY_PROPERTIES,
    id
});

export const createNewEntity = (newEntity: any, dataSources: any[]) => ({
    type: CREATE_NEW_ENTITY,
    newEntity,
    dataSources,
});

export const setEntities = (entities: Entity[], count: number) => ({
    type: SET_ENTITIES,
    entities,
    count
});

export const setEntityProperties = (entityProperties: EntityProperties) => ({
    type: SET_ENTITY_PROPERTIES,
    entityProperties
});

export const setEntityAuditTrail = (payload: any) => ({
    type: SET_ENTITY_AUDIT_TRAIL,
    payload
});

export const setEntityRelationships = (payload: any) => ({
    type: SET_ENTITY_RELATIONSHIPS,
    payload
});

export const updateEntityProperties = (payload: any) => ({
    type: UPDATE_ENTITY_PROPERTIES,
    payload
});

export const deleteDatasource = (modalPayload: ModalPayload) => ({
    type: DELETE_DATASOURCE_BY_ID,
    modalPayload
});

export const editDatasource = (payload: any) => ({
    type: EDIT_DATASOURCE,
    payload
});

export const addDatasource = (payload: any) => ({
    type: ADD_DATASOURCE,
    payload
});

export const createDataSources = (entityId: any, dataSources: any[]) => ({
    type: CREATE_DATA_SOURCES,
    entityId,
    dataSources,
});

export const useOtherNameAsLegal = (entityId: number, otherNameId: number) => ({
    type: SWAP_LEGAL_AND_OTHER_NAME,
    entityId,
    otherNameId,
});

export const updateEntityOtherName = (entityId: number, otherNameId: number, updateData: EntityOtherName) => ({
    type: UPDATE_ENTITY_OTHER_NAME,
    entityId,
    otherNameId,
    updateData,
});

export const addOtherName = (entityId: string, newObj: EntityOtherName) => ({
    type: ADD_OTHER_NAME,
    entityId,
    newObj,
});

export const propagateEntity = (entityId: string) => ({
    type: PROPAGATE_ENTITY,
    entityId,
});