import * as React from 'react';
import { Form } from 'reactstrap';

import SelectFilter from './SelectFilter';
import InputFilter from './InputFilter';
import DateFilter from './DateFilter';
import InputFilterWithoutStoredValue from './InputFilterWithoutStoredValue';
import { FilterItem, FilterType } from 'store/types';
import { FiltersState } from 'store/Filters/types';
import { FilterDecoratorChange } from 'decorators/FilterDecorator';

interface Props {
    filters: FilterItem[];
    currentFilter: FiltersState;
    handleChange: FilterDecoratorChange;
    count?: number;
    countLabel?: string;
    clearable?: boolean;
}

const styles = require('./FilterPanel.scss');

const FilterPanel: React.FunctionComponent<Props> = ({
   children, filters, currentFilter, handleChange, count, countLabel
}) => (
    <div className={styles.filter_panel}>
        <Form>
            <div className="row">
                {
                filters.map((filter: FilterItem, idx: number) => {
                    let itemClass = 'filter--item ';
                    itemClass += filter.className ? filter.className : 'col-md-2';
                    let input = null;

                    switch (filter.type) {
                        case FilterType.Select:
                            input = (
                                <SelectFilter
                                    name={filter.name}
                                    labelKey={filter.labelKey}
                                    valueKey={filter.valueKey}
                                    placeholder={filter.title}
                                    disabled={filter.disabled}
                                    defaultValue={filter.defaultValue}
                                    value={currentFilter[filter.name]}
                                    options={filter.choices}
                                    handleChange={handleChange}
                                    clearable={filter.clearable}
                                />
                            );
                            break;
                        case FilterType.InputWithCount:
                            input = (
                                <InputFilter
                                    title={filter.title || ''}
                                    name={filter.name}
                                    handleChange={handleChange}
                                    value={currentFilter[filter.name]}
                                    count={count}
                                    countLabel={countLabel}
                                    withCount={true}
                                    className="col-md-10"
                                />
                            );
                            break;
                        case FilterType.InputWithoutStoredValue:
                            input = (
                                <InputFilterWithoutStoredValue
                                    title={filter.title || ''}
                                    name={filter.name}
                                    handleChange={handleChange}
                                    count={count}
                                    countLabel={countLabel}
                                    withCount={false}
                                    className="col-md-12"
                                />
                            );
                            break;
                        case FilterType.Date:
                            input = (
                                <DateFilter
                                    title={filter.title || ''}
                                    name={filter.name}
                                    value={currentFilter[filter.name]}
                                    start={currentFilter[`${filter.name}_gte`]}
                                    end={currentFilter[`${filter.name}_lte`]}
                                    handleChange={handleChange}
                                />
                            );
                            break;
                        case FilterType.CustomContent:
                            input = filter.content || null;
                            break;
                        case FilterType.Input:
                        default:
                            input = (
                                <InputFilter
                                    title={filter.title || ''}
                                    name={filter.name}
                                    handleChange={handleChange}
                                    value={currentFilter[filter.name]}
                                    count={count}
                                    countLabel={countLabel}
                                    withCount={false}
                                    className="col-md-12"
                                    valueDecorator={filter.valueDecorator}
                                />
                            );
                    }
                    return <div key={idx} className={itemClass + ' form-group'}>{input}</div>;
                })
            }
                {children}
            </div>
        </Form>
    </div>

);

export default FilterPanel;