import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreateNewEntity from '../../../../components/Erm/Actions/FlaggedEntitiesActions/CreateNewEntity';
import { createNewEntity } from '../../../../store/Erm/Entity/actionCreators';

const mapStateToProps = (state: any) => {
    const payload = state.globalModal.payload;
    const newEntity = state.erm.entity.newEntity;
    const dataSources = state.erm.entity.dataSources;
    return {
        payload,
        newEntity,
        dataSources,
    };

};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            createNewEntity,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewEntity);