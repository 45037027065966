import { connect } from 'react-redux';

import ChangeStatusModal from '../../components/Rca/ChangeStatusModal';
import { getModalParams, isModalOpen } from '../../store/Modal/selectors';
import { AppState } from '../../store/types';
import { closeModal } from '../../store/Modal/actionCreators';
import { create } from '../../store/Rca/actionCreators';

export const name = 'CREATE_RCA_MODAL';

const mapStateToProps = (state: AppState) => ({
    isOpened: isModalOpen(state, {name}),
    params: getModalParams(state),
    title: 'Create account',
    body: 'Are you sure you want to create an account based of this RCA?',
});

const mapDispatchToProps = {
    toggle: closeModal,
    apply: create
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatusModal);
