import * as React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import { GetUserAction } from '../store/Auth/actions';
import { getUser } from  '../store/Auth/actionCreators';
import ProtectedRoute from './Shared/ProtectedRoute';

import LayoutWithPanel from './Layouts/LayoutWithPanel';

interface Props {
    getUser: () => GetUserAction;
}

class App extends React.Component<Props> {
    componentDidMount() {
        this.props.getUser();
    }

    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        <ProtectedRoute path="/" component={LayoutWithPanel}/>
                        <Redirect from="*" to="/dashboard" />
                    </Switch>
                </Router>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getUser,
};

export default connect(null, mapDispatchToProps)(App);
