import * as React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { RadioButton, RadioButtonGroup } from 'material-ui';
import { RadioOption } from '../../../../store/Erm/FlaggedEntity/types';
import { FormGroup } from 'reactstrap';

const styles = {
    block: {
        width: '100%',
        marginBottom: -10,
    },
    radioButton: {},
};

interface Props {
    label: string;
    settingsKey?: string;
    radioGroupName: string;
    defaultSelectedRadio: string;
    handleRadioChange: (event: any, key?: string) => void;
    radioOptions: RadioOption[];
}

const FormGroupWithRadioButtonGroup: React.FunctionComponent<Props> = ({
    label, settingsKey, radioGroupName, defaultSelectedRadio, handleRadioChange, radioOptions, children
}) => (
    <div className="row">
        <FormGroup className="col-md-3">
            <span>{label}</span>
        </FormGroup>
        <MuiThemeProvider>
            <div className="col-md-9">
                <RadioButtonGroup
                    name={radioGroupName}
                    defaultSelected={defaultSelectedRadio}
                    style={styles.block}
                >
                {
                    radioOptions.map((item, idx) => {
                        return (
                            <RadioButton
                                key={idx}
                                onClick={(event: any) => handleRadioChange(event, settingsKey)}
                                value={item.value}
                                label={item.label}
                                style={styles.radioButton}
                            />
                        );
                    })
                }
                </RadioButtonGroup>
                {children && children}
            </div>
        </MuiThemeProvider>
    </div>
);

export default FormGroupWithRadioButtonGroup;
