export interface Archive {
    id: number;
    name: string;
    status: ArchiveStatus;
    date: string;
    year: number;
    quarter?: string;
    created_at?: string;
    updated_at?: string;
    last_modified?: string;
}

export interface FilingMetadata {
    id: number;
    accession_number: string;
    submission_type: string;
    public_doc_count: number;
    filing_date: string;
    period: string | null;
    is_deleted: boolean;
}

export interface Address {
    address_type: AddressType;
    street1: string | null;
    street2?: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    phone: string | null;
}

export interface ExtendedAddress {
    street1: string | null;
    street2?: string | null;
    city: string | null;
    state: string | null;
    zip_code: string | null;
    country: string | null;
    phone_number: string | null;
}

export interface LocationBookRecord {
    name: string | null;
    street1: string | null;
    street2: string | null;
    city: string | null;
    zip_code: string | null;
    country: string | null;
    state: string | null;
    phone_number: string | null;
    desc: string | null;
}

export interface FilingEntity {
    id: number;
    entity_type: FilingEntityType;
    conformed_name: string;
    cik: string;
    sic: string | null;
    irs: string | null;
    incorporation_state: string | null;
    fiscal_year_end: string | null;

    form_type: string | null;
    act: string | null;
    file_number: string | null;
    film_number: string | null;

    metadata: FilingMetadata;
    addresses: Address[];

    is_deleted: boolean;
    is_values_deleted: boolean;

    filing_document_status: FilingDocumentStatus;
    is_correction: boolean;
}

export interface RegistrantInfo {
    full_name: string;
    investment_comp_file_no: string;
    cik: string;
    lei: string;
    address: ExtendedAddress | null;
    websites: string[];
    location_book_records: LocationBookRecord[] | null;
    is_registrant_first_filing: boolean;
    is_registrant_last_filing: boolean;
    is_registrant_family_inv_comp: boolean;
    family_inv_comp_full_name: string | null;
    registrant_classification_type: InvestmentCompanyType | null;
    total_series: number | null;
    terminated_series: TerminatedSeries[] | null;

    is_securities_act_registration: boolean;
    security_matter_series?: Series[] | null;
    is_registrant_submitted_matter: boolean;
    legal_proceeding_series?: Series[] | null;
    is_previous_legal_proceeding: boolean;
    previous_proceeding_terminated?: Series[] | null;
    is_previous_proceeding_terminated: boolean;
    is_claim_filed?: boolean | null;
    total_claim_amount?: number | null;
    is_covered_by_insurance_policy?: boolean | null;
    is_claim_filed_during_period?: boolean | null;
    financial_support_series?: Series[] | null;
    is_financial_support_during_period: boolean;
    release_numbers?: string[] | null;
    is_exemption_from_act: boolean;
    is_underwriter_hired_or_terminated: boolean;
    is_public_accountant_changed: boolean;
    is_material_weakness?: boolean | null;
    audit_opinion_series?: Series[] | null;
    is_opinion_offered: boolean;
    valuation_methods_changes: ValidatonMethodChange[];
    is_material_change: boolean;
    is_accounting_principle_change: boolean;
    payment_error_series?: Series[];
    is_payment_error_in_net_asset_value?: boolean | null;
    payment_dividend_series?: Series[] | null;
    is_payment_dividend?: boolean | null;
}

export interface ValidatonMethodChange {
    date_of_change: string;
    change_explanation: string;
    asset_type: string;
    asset_type_other_desc: string;
    investment_type: string;
    statutory_regulatory_basis: string;
    valuation_methods_change_series: Series[];
}

export enum ArchiveStatus {
    New = 'new',
    Downloading = 'downloading',
    Stored = 'stored',
    Outdated = 'outdated',
    Corrupted = 'corrupted',
    QueuedDownloading = 'queued_downloading',
    ExtractingFailed = 'extracting_failed',
}

export enum FilingEntityType {
    Filer = 'filer',
    FiledBy = 'filed_by',
    FiledFor = 'filed_for',
    SerialCompany = 'serial_company',
    SubjectCompany = 'subject_company',
    ReportingOwner = 'reporting_owner',
    Issuer = 'issuer',
}

export enum FilingDocumentStatus {
    New = 'new',
    Parsing = 'parsing',
    Parsed = 'parsed',
    Failed = 'failed',
}

export enum AddressType {
    Business = 'business',
    Mail = 'mail',
}

export enum NCENFormType {
    NCEN = 'N-CEN',
    NCENA = 'N-CEN/A',
}

export enum InvestmentCompanyType {
    N1A = 'N-1A',
    N2 = 'N-2',
    N3 = 'N-3',
    N4 = 'N-4',
    N5 = 'N-5',
    N6 = 'N-6',
    S6 = 'S-6'
}

export interface EdgarState {
    archives: Archive[];
    archivesCount: number;
    filingEntities: FilingEntity[];
    filingEntitiesCount: number;
    filingEntityInfo: Partial<FilingEntity> | null;
    ncenFormData: NCENFormData | null;
    dFormData: DFormData | null;
}

export interface NCENSection {
    [key: string]: any;
}

export interface Series {
    series_name: string;
    series_id: string;
}

export interface TerminatedSeries extends Series {
    termination_date: string;
}

export interface CommonStock {
    exchange: string;
    ticker_symbol: string;
}

export interface SecurityRelatedItem {
    description: string;
    security_class_title: string;
    common_stocks: CommonStock[];
    other_security_description: string;
}

interface FundType {
    type: string;
    desc: string;
}

export interface RightsOfferingFund {
    fund_types: FundType[];
    participation_percent: string;
}

export interface SecondaryOffering {
    types: string[];
    desc: string;
}

export interface RepurchaseSecurity extends SecondaryOffering {}

export interface LongTermDebtDefault {
    nature: string;
    date: string;
    per_thousand_amount: string;
    total_amount: string;
}

export interface DividendInArrears {
    issue_title: string;
    amount_per_share_in_arrear: string;
}

export interface ClosedEndManagement {
    form_id: number;
    security_related_items?: SecurityRelatedItem[];
    rights_offering_funds?: RightsOfferingFund[];
    is_rights_offering?: boolean;
    secondary_offerings?: SecondaryOffering;
    is_secondary_offering?: boolean;
    repurchase_securities?: RepurchaseSecurity[];
    is_repurchase_security?: boolean;
    long_term_debt_defaults?: LongTermDebtDefault[];
    is_default_long_term_debt?: boolean;
    dividends_in_arrears?: DividendInArrears[];
    is_dividends_in_arrears?: boolean;
    is_securities_modified: boolean;
    management_fee?: number;
    net_operating_expenses?: number;
    market_price_per_share?: number;
    net_asset_value_per_share?: number;
    is_small_transfer_agent_hired_or_terminated?: boolean;
    is_small_custodian_hired_terminated?: boolean;
}

export interface SecurityExchange {
    fund_exchange: string;
    funds_ticker_symbol: string;
}

export interface ExchangeTradedFund {
    form_id: number;
    fund_name?: string;
    etf_series_id?: string;
    security_exchanges: SecurityExchange[];
    is_collateral_required: boolean;
    creation_unit_num_of_shares: number;
    average_percentage_purchased: number;
    standard_deviation_purchased: number;
    creation_unit_purchased_in_kind: number;
    creation_unit_purchased_sd_in_kind: number;
    average_percentage_redeemed: number;
    percent_sd_redeemed: number;
    creation_unit_percentage_redeemed_in_kind: number;
    creation_unit_sd_redeemed_in_kind: number;
    creation_unit_transaction_fee_per_unit?: number;
    creation_unit_transaction_fee_many_units ?: number;
    creation_unit_transaction_fee_percentage_per_unit ?: number;
    creation_unit_transaction_fee_cash_per_unit ?: number;
    creation_unit_transaction_fee_cash_many_units ?: number;
    creation_unit_transaction_fee_cash_percentage_per_unit ?: number;
    purchase_creation_unit_dollar_per_unit ?: number;
    purchase_creation_unit_dollar_per_more_units ?: number;
    purchase_creation_unit_dollar_percentage_per_unit ?: number;
    purchase_creation_unit_cash_per_unit ?: number;
    purchase_creation_unit_cash_per_more_units ?: number;
    purchase_creation_unit_cash_percentage_per_unit ?: number;
    is_by_affiliated_person?: boolean;
    is_exclusive_fund?: boolean;
    before_fund_fees_expenses_diff?: number;
    after_fund_fees_expenses_diff?: number;
    before_fund_fees_expenses_sd?: number;
    after_fund_fees_expenses_sd?: number;
    is_in_kind_etf?: boolean;
}

export interface RegistrantHeldSecurity {
    registrant_held_security_ticker_symbol: string;
    registrant_holds_security_cusip_no: string;
    registrant_num_share: string;
}

export interface UnitInvestmentTrust {
    form_id: number;
    is_uit_admin_hired_terminated?: boolean;
    num_of_existing_series?: number;
    series_ciks?: string[];
    num_of_new_series_registration?: number;
    new_series_aggregate_value?: number;
    num_of_series_current_prospectus?: number;
    num_of_existing_series_additional_units?: number;
    additional_units_total_value?: number;
    unit_in_portfolio_value?: number;
    total_assets_series?: number;
    is_registrant_separate_insurance_account?: boolean;
    separate_account_series_id?: string;
    num_of_contracts?: number;
    is_rule_6c7_reliance?: boolean;
    is_rule_11a2_reliance?: boolean;
    registrant_held_securities?: RegistrantHeldSecurity[];
}

export interface NCENFormData {
    filer_information: {
        cik: string;
        investment_company_type: InvestmentCompanyType;
        is_live: boolean;
        return_copy_flag: boolean;
        override_internet_flag: boolean;
        confirming_copy_flag: boolean;
    };
    series_class_information?: NCENSection;
    general_information: {
        report_ending_period: string;
        is_report_period_lt_12: boolean;
    };
    registrant_information: RegistrantInfo;
    management_questions?: NCENSection[];
    closed_end_management?: ClosedEndManagement;
    exchange_traded_funds?: ExchangeTradedFund[];
    unit_investment_trust?: UnitInvestmentTrust;
    attachments?: NCENSection;
    signature: NCENSection;
}

export interface DFormIssuer {
    issuer_identity: {
        is_primary: boolean;
        cik: string;
        entity_name: string;
        entity_type: string; // or use enum
        jurisdiction_of_inc: string;
        year_of_inc: {
            within_five_years: boolean;
            value: string;
        };
        issuer_previous_name_list: {
            value: string | null;
            previous_name: string[];
        };
        edgar_previous_name_list: {
            value: string | null;
            previous_name: string[];
        };
    };
    contact_information: {
        street1: string;
        street2: string;
        city: string;
        state_or_country: string;
        state_or_country_description: string;
        zip_code: string;
        issuer_phone_number: string;
    };
}

export interface DFormRelatedPerson {
    first_name: string;
    middle_name?: string;
    last_name: string;
    related_person_address: {
        street1: string;
        street2: string;
        city: string;
        state_or_country: string;
        state_or_country_description: string;
        zip_code: string;
    };
    related_person_relationship_list: string[]; // or array of enum
    relationship_clarification: string;
}

export interface DFormOfferingData {
    industry_group_type: string;
    investment_fund_info: {
        investment_fund_type: string;
        is_40_act: boolean;
    };
    revenue_range: string;
    federal_exemptions_exclusions: string[];
    new_or_amendment: {
        is_amendment: boolean;
        previous_accession_number: string;
    };
    date_of_first_sale: {
        value: string;
    };
    duration_of_offering: boolean;
    is_equity_type: boolean;
    is_pooled_investment_fund_type: boolean;
    is_business_combination_transaction: boolean;
    clarification_of_response: string | null;
    minimum_investment_accepted: string;
    sales_compensation_list: {
        recipient_name: string;
        recipient_crd_number: string;
        associated_bd_name: string | null;
        associated_bd_crd_number: string | null;
        recipient_address: {
            street1: string;
            city: string;
            state_or_country: string;
            state_or_country_description: string;
            zip_code: string;
        },
        states_of_solicitation_list: string[];
        foreign_solicitation: boolean;
    }[];
    offering_sales_amounts: {
        total_offering_amount: string;
        total_amount_sold: string;
        total_remaining: string;
    };
    investors: {
        has_non_accredited_investors: boolean;
        total_number_already_invested: string;
    };
    sales_commissions_finders_fees: {
        sales_commissions: {
            dollar_amount: string;
            is_estimate: boolean;
        },
        finders_fees: {
            dollar_amount: string;
        }
    };
    use_of_proceeds: {
        gross_proceeds_used: {
            dollar_amount: string;
        },
        clarification_of_response: string | null;
    };
    recipients: {
        recipient_name: string;
        recipient_crd_number: string;
        associated_bd_name: string;
        associated_bd_crd_number: string;
        recipient_address: {
            street1: string;
            street2: string;
            city: string;
            state_or_country: string;
            state_or_country_description: string;
            zip_code: string;
        },
        states_of_solicitation_list: string[];
        foreign_solicitation: boolean;
    }[];
}

export interface DFormData {
    general_info: {
        submission_type: string;
        is_live: boolean;
    };
    issuers: DFormIssuer[];
    related_persons: DFormRelatedPerson[];
    offering_data: DFormOfferingData;
    signature_block: {
        signatures: {
            issuer_name: string;
            signature_name: string;
            name_of_signer: string;
            signature_title: string;
            signature_date: string;
        }[];
        authorized_representative: boolean;
    };
}
