import { defaultSortPagination, DEFAULT_FILTER } from '../../constants';
import { AccountStatus, AccountType, RcaStatus } from './types';
import { SelectItem } from '../types';

export const messages = {
    MESSAGE_RCA_APPROVE_SUCCESS: 'RCA was approved successfully',
    MESSAGE_RCA_APPROVE_FAILED: 'RCA approving failed',
    MESSAGE_RCA_DENY_SUCCESS: 'RCA was denied successfully',
    MESSAGE_RCA_DENY_FAILED: 'RCA denying failed',
    MESSAGE_RCA_CREATE_SUCCESS: 'Account creation was queued successfully',
    MESSAGE_RCA_CREATE_FAILED: 'Account creation failed',
    MESSAGE_RCA_CREATE_NEW_SUCCESS: 'RCA was created successfully',
    MESSAGE_RCA_CREATE_NEW_FAILED: 'RCA creation failed',
};

export const initialState = {
    list: {
        count: 0,
        results: [],
        next: '',
        previous: ''
    },
    refreshStep: 0
};

export const defaultFilter = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        entity_type: ''
    },
    headerType: 'rcaRequests'
};

export const ACCOUNT_TYPES_SELECT: SelectItem[] = [
    {label: 'Requestor', value: AccountType.Requestor},
    {label: 'Responder', value: AccountType.Responder},
];

export const ACCOUNT_STATUS_SELECT: SelectItem[] = [
    {label: 'Test', value: AccountStatus.Test},
    {label: 'Client', value: AccountStatus.Client},
    {label: 'CounterParty', value: AccountStatus.CounterParty},
];

export const RCA_STATUSES_SELECT: SelectItem[] = [
    {label: 'Awaiting approval', value: RcaStatus.AwaitingApproval},
    {label: 'Approved', value: RcaStatus.Approved},
    {label: 'Denied', value: RcaStatus.Denied},
    {label: 'Account created', value: RcaStatus.AccountCreated},
];

export const modals = {
    view: 'RcaViewModal'
};
