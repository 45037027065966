import { Action } from 'redux';

import { AuditTrail } from './entity';

export const GET_AUDIT_TRAIL = 'store/erm/auditTrail/get';
export const SET_AUDIT_TRAIL = 'store/erm/auditTrail/set';

export interface GetAuditTrailAction extends Action {
    filter: any;
}

export interface SetAuditTrailAction extends Action {
    auditTrail: AuditTrail;
}

export type AuditTrailAction = GetAuditTrailAction | SetAuditTrailAction;