import { FlaggedEntityState } from './types';

import {
    GET_FLAGGED_ENTITIES,
    SET_FLAGGED_ENTITIES,
    DELETE_FLAGGED_ENTITIES,
    ASSOCIATE_ENTITY,
    ASSIGN_FLAGGED_ENTITIES,
    FlaggedEntityAction,
    SetFlaggedEntityAction
} from './actions';

const initialState = {};

const reducer = (state: FlaggedEntityState = initialState, action: FlaggedEntityAction) => {
    switch (action.type) {
        case ASSOCIATE_ENTITY:
            return state;
        case ASSIGN_FLAGGED_ENTITIES:
            return state;
        case GET_FLAGGED_ENTITIES:
            return state;
        case SET_FLAGGED_ENTITIES:
            return {
                ...state,
                items: (<SetFlaggedEntityAction> action).flaggedEntity
            };
        default:
            return state;
    }
};

export default reducer;