import MockAdapter from 'axios-mock-adapter';

import rcaMock from './json/rca';

export default (mock: MockAdapter) => {
    mock.onGet(/\/rca\//).reply(200, rcaMock.list);

    // mock.onGet(/\/users\/info/).reply(500, {
    //     errors: ['error1', 'error2']
    // });
    // mock.onGet(/\/users\/info/).reply(500, 'String error response');
};