import { connect } from 'react-redux';
import { addOtherName } from 'store/Erm/Entity/actionCreators';
import { setCloseGlobalModal } from 'store/GlobalModal/actionCreators';
import AddOtherName from 'components/Erm/Actions/Properties/AddOtherName';

const mapStateToProps = (state: any, ownProps: any) => {
    const modal = state.globalModal;
    return {
        payload: modal && modal.payload ? modal.payload : {},
    };
};

const mapDispatchToProps = {
    addOtherName,
    closeModal: setCloseGlobalModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOtherName);
