import { get } from 'lodash';

class GlobalHelper {
    static clearEmptyFields(data: any) {
        let newObject = {};
        for (let item in data) {
            if (data[item]) {
                newObject[item] = data[item];
            }
        }

        return newObject;
    }

    static deleteOption(value: string, data: any[]) {
        return data.filter((item: any) => item.value !== value);
    }

    static copyObject(currentObject: any) {
        return JSON.parse(JSON.stringify(currentObject));
    }

    static getValueFromObject(object: any, value: string, defaultValue: string | null = '') {
        return get(object, value, defaultValue);
    }

    static isUuidData(data: string) {
        const reg = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return reg.test(data) === true;
    }

    static formatSnakeCaseToTitle(snakeCasedKey: string): string {
        const title = snakeCasedKey.replace(/_/g, ' ');
        return title.charAt(0).toUpperCase() + title.slice(1);
    }
}

export default GlobalHelper;