import * as React from 'react';
import { Button, Form } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { MenuItem, SelectField, TextField } from 'material-ui';
const memoize = require('memoize-one').default;

import {
    DataSourcesConfig,
    DataSourcesValues,
    FlaggedEntityMessage,
    RadioOption,
    ResolveConflictBody,
} from 'store/Erm/FlaggedEntity/types';
import {
    getResolveConflictBodyToSend,
    parseFlaggedEntityMessage,
    setLabelsToResolveConflictDataSources,
    setLabelsToResolveConflictEntityTypes,
    setLabelsToResolveConflictLegalNames,
} from 'helpers/dataSourceHelper';
import { formatText } from 'helpers/tableHelper';
import { SelectItem, ValueTypeChoices } from 'store/types';
import FormGroupWithRadioButtonGroup from './FormGroupWithRadioButtonGroup';
import {
    DATA_SOURCE_KEY_TYPE_LABELS,
    DEFAULT_FLAGGED_ENTITY_MESSAGE,
    ENTITY_TYPES_SELECT_WITHOUT_UNKNOWN,
    ENTITY_VALUE,
    OTHER_VALUE,
} from 'containers/Erm/constants';
import FormGroupWithCheckboxGroup from './FormGroupWithCheckboxGroup';
import { DataSourceKeyTypeEnum } from 'store/Erm/types';
import { ResolveConflictEntityAction } from 'store/Erm/FlaggedEntity/actions';
import { ModalPayload } from 'store/GlobalModal/types';

const addIcon = require(`../../../../assets/img/sharedIcons/add.svg`);
const trashIcon = require(`../../../../assets/img/sharedIcons/trash.svg`);

interface ResolveConflictsProps {
    flaggedEntityMessage: ModalPayload;
    resolveConflicts: (
        flaggedMessageId: number,
        body: ResolveConflictBody,
        flaggedEntityName?: string
    ) => ResolveConflictEntityAction;
    handleCancel: () => void;
}

export interface ResolveConflictsState {
    flaggedEntityMessage: FlaggedEntityMessage;
    resolveConflictBody: ResolveConflictBody;
    legalNames: RadioOption[];
    legalNameRadioValue: string;
    otherLegalNameValue: string;
    entityTypes: RadioOption[];
    entityTypeRadioValue: string;
    otherEntityTypeValue: string;
    dataSources: DataSourcesConfig;
    otherDataSources: DataSourcesValues;
    hasMessageDataSources: boolean;
}

const styles = require('./ResolveConflictsModal.scss');

const dataSourceKeyTypeArray: string[] = [
    DataSourceKeyTypeEnum.SEC,
    DataSourceKeyTypeEnum.CRD,
    DataSourceKeyTypeEnum.LEI,
    DataSourceKeyTypeEnum.CIK,
    DataSourceKeyTypeEnum.SERIES_ID,
    DataSourceKeyTypeEnum.CLASS_ID,
    DataSourceKeyTypeEnum.TICKER,
];

class ResolveConflictsModal extends React.PureComponent<ResolveConflictsProps, ResolveConflictsState> {
    state: ResolveConflictsState = {
        flaggedEntityMessage: DEFAULT_FLAGGED_ENTITY_MESSAGE,
        resolveConflictBody: {
            entity_id: '',
            legal_name: '',
            data_sources: [],
            entity_type: '',
        },
        legalNames: [],
        legalNameRadioValue: '',
        otherLegalNameValue: '',
        entityTypes: [],
        otherEntityTypeValue: '',
        entityTypeRadioValue: '',
        dataSources: {},
        otherDataSources: {
            lei: [],
            crd: [],
            sec: [],
            cik: [],
            series_id: [],
            class_id: [],
            ticker: [],
        },
        hasMessageDataSources: false,
    };

    getCountOfDataSourcesInFlaggedMessage = memoize((flaggedEntityMessage: FlaggedEntityMessage) => {
        let dataSourcesCount = 0;
        Object.keys(flaggedEntityMessage.eventMessage).forEach((eventMessageKey: string) => {
            if (flaggedEntityMessage.eventMessage[eventMessageKey]
                && dataSourceKeyTypeArray.indexOf(eventMessageKey) >= 0
            ) {
                dataSourcesCount++;
            }
        });

        return dataSourcesCount;
    });

    componentDidMount() {
        const flaggedEntityMessage = parseFlaggedEntityMessage(this.props.flaggedEntityMessage);
        const hasMessageDataSources = this.getCountOfDataSourcesInFlaggedMessage(flaggedEntityMessage) > 0;
        const legalNames: RadioOption[] = setLabelsToResolveConflictLegalNames(flaggedEntityMessage);
        const entityTypes: RadioOption[] = setLabelsToResolveConflictEntityTypes(flaggedEntityMessage);
        const dataSources = setLabelsToResolveConflictDataSources(flaggedEntityMessage);
        this.setState({flaggedEntityMessage, legalNames, entityTypes, dataSources, hasMessageDataSources});
    }

    render() {
        const flaggedEntityMessage = this.state.flaggedEntityMessage;
        const entityId = flaggedEntityMessage.entity.id;
        const conflictEntityName = flaggedEntityMessage.entity.legalName;
        const route = `/erm/entities/${entityId}/properties/`;
        const body: ResolveConflictBody = getResolveConflictBodyToSend(this.state);
        let disabledResolveConflict = !body.legal_name || !body.entity_type ||
            (this.state.hasMessageDataSources &&
                (!body.data_sources || body.data_sources && body.data_sources.length === 0));

        if (!disabledResolveConflict && body.data_sources
            && body.data_sources.length !== this.getCountOfDataSourcesInFlaggedMessage(flaggedEntityMessage)
        ) {
            disabledResolveConflict = true;
        }
        const messageDataSourceType = formatText(
            flaggedEntityMessage.eventMessage.dataSourceId, ValueTypeChoices.ToUppercase
        );
        return (
            <div>
                {
                    conflictEntityName && entityId &&
                    <div>
                        <h5>Entity in Conflict:&nbsp;
                        <NavLink to={route} onClick={this.props.handleCancel}>
                            <span className="input-label">{conflictEntityName}</span>
                        </NavLink>
                        </h5>
                    </div>
                }
                <div>
                    <h5>Flagged Message Source Type:&nbsp;
                        <span className="input-label">{messageDataSourceType}</span>
                    </h5>
                </div>
                <hr/>
                <div>{this.renderForm()}</div>
                <div className="float-md-left">
                    <Button color="secondary" onClick={this.props.handleCancel}>Cancel</Button>
                </div>
                <div className="float-md-right">
                    <Button
                        color="secondary"
                        onClick={this.handleResolveConflicts}
                        disabled={disabledResolveConflict}
                    >
                        Update Entity
                    </Button>
                </div>
            </div>
        );
    }

    renderForm () {
        return (
            <Form onSubmit={this.handleResolveConflicts} className="col-md-12">
                <FormGroupWithRadioButtonGroup
                    label="Legal Name"
                    radioGroupName="legalName"
                    defaultSelectedRadio={ENTITY_VALUE}
                    handleRadioChange={this.handleLegalNameRadioChange}
                    radioOptions={this.state.legalNames}
                >
                    <TextField
                        type="text"
                        value={this.state.otherLegalNameValue}
                        onChange={this.handleInputChange}
                        name="otherLegalName"
                        id="otherLegalName"
                        hintText="Enter Entity Name"
                        disabled={this.state.legalNameRadioValue !== OTHER_VALUE}
                    />
                </FormGroupWithRadioButtonGroup>
                <FormGroupWithRadioButtonGroup
                    label="Entity Type"
                    radioGroupName="entityType"
                    defaultSelectedRadio={ENTITY_VALUE}
                    handleRadioChange={this.handleEntityTypeRadioChange}
                    radioOptions={this.state.entityTypes}
                >
                    <SelectField
                        floatingLabelText="Choose Entity Type"
                        value={this.state.otherEntityTypeValue}
                        onChange={this.handleSelectChange}
                        disabled={this.state.entityTypeRadioValue !== OTHER_VALUE}
                    >
                        {
                            ENTITY_TYPES_SELECT_WITHOUT_UNKNOWN.map((item: SelectItem, id: number) => {
                                return <MenuItem
                                    key={id}
                                    value={item.value}
                                    primaryText={item.label}
                                />;
                            })
                        }
                    </SelectField>
                </FormGroupWithRadioButtonGroup>
                {
                    this.state.dataSources && Object.keys(this.state.dataSources).length > 0 &&
                    Object.keys(this.state.dataSources)
                        .filter(key => {
                            return this.state.dataSources[key].length > 0;
                        })
                        .map((key: string, index: number) => {
                            const formattedKey = DATA_SOURCE_KEY_TYPE_LABELS.hasOwnProperty(key)
                                ? DATA_SOURCE_KEY_TYPE_LABELS[key]
                                : formatText(key, ValueTypeChoices.ToUppercase);

                            return (
                                <FormGroupWithCheckboxGroup
                                    key={index}
                                    label={formattedKey}
                                    handleCheckboxChange={this.handleDataSourceChange}
                                    checkboxOptions={this.state.dataSources[key]}
                                    settingsKey={key}
                                >
                                    {
                                        this.state.otherDataSources[key] &&
                                        this.state.otherDataSources[key].map((value: string, idx: number) => {
                                            return (
                                                <div className="col-md-12" key={idx}>
                                                    <TextField
                                                        type="text"
                                                        value={value || ''}
                                                        onChange={
                                                            (event) => this.handleDataSourceInputChange(event, key, idx)
                                                        }
                                                        name={'other' + key + 'Number'}
                                                        id={'other' + key + 'Number'}
                                                        hintText={'Enter ' + formattedKey + ' Number'}
                                                        className={styles.inputText}
                                                    />
                                                    <img
                                                        className={styles.removeDataSource}
                                                        src={trashIcon}
                                                        alt="Remove other Data Source"
                                                        onClick={
                                                            (event: any) => this.deleteDataSourceInput(event, key, idx)
                                                        }
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                    <div key="addButton" className="col-md-12">
                                        <img
                                            className={styles.addNewDataSource}
                                            src={addIcon}
                                            alt="Add other Data Source"
                                            onClick={(event: any) => this.addNewDataSourceInput(event, key)}
                                        />
                                    </div>
                                </FormGroupWithCheckboxGroup>
                            );
                        })
                }
            </Form>
        );
    }

    addNewDataSourceInput = (event: any, dataSourceKey: string) => {
        event.preventDefault();
        let newCurrentDataSourceKeyArray = [...this.state.otherDataSources[dataSourceKey]];
        newCurrentDataSourceKeyArray.push('');
        if (this.state.otherDataSources && this.state.otherDataSources.hasOwnProperty(dataSourceKey)) {
            this.setState(
                { otherDataSources: {...this.state.otherDataSources, [dataSourceKey]: newCurrentDataSourceKeyArray}},
            );
        }
    }

    deleteDataSourceInput = (event: any, dataSourceKey: string, index: number) => {
        event.preventDefault();
        let newCurrentDataSourceKeyArray = [...this.state.otherDataSources[dataSourceKey]];
        newCurrentDataSourceKeyArray.splice(index, 1);
        if (this.state.dataSources && this.state.dataSources.hasOwnProperty(dataSourceKey)) {
            this.setState(
                { otherDataSources: {...this.state.otherDataSources, [dataSourceKey]: newCurrentDataSourceKeyArray}},
            );
        }
    }

    handleResolveConflicts = (event: any) => {
        event.preventDefault();
        const body: ResolveConflictBody = getResolveConflictBodyToSend(this.state);
        const messageId = this.props.flaggedEntityMessage.id;
        const flaggedEntityName = this.state.flaggedEntityMessage.entityName;
        this.props.resolveConflicts(messageId, body, flaggedEntityName);
    }

    handleInputChange = (event: any) => {
        this.setState({otherLegalNameValue: event.target.value});
    }

    handleSelectChange = (event: any, index: number, value: string) => {
        this.setState({otherEntityTypeValue: value});
    }

    handleDataSourceInputChange = (event: any, dataSourceKey: string, index: number) => {
        event.preventDefault();
        let newCurrentDataSourceKeyArray = [...this.state.otherDataSources[dataSourceKey]];
        newCurrentDataSourceKeyArray[index] = event.target.value;
        if (this.state.otherDataSources && this.state.otherDataSources.hasOwnProperty(dataSourceKey)) {
            this.setState(
                { otherDataSources: {...this.state.otherDataSources, [dataSourceKey]: newCurrentDataSourceKeyArray}},
            );
        }
    }

    handleDataSourceChange = (event: any, index: number, dataSourceKey: string) => {
        event.preventDefault();
        const checked = this.state.dataSources[dataSourceKey][index].checked;
        let newCurrentDataSourceKeyArray = [...this.state.dataSources[dataSourceKey]];
        newCurrentDataSourceKeyArray[index].checked = !checked;
        if (this.state.dataSources && this.state.dataSources.hasOwnProperty(dataSourceKey)) {
            this.setState(
                {
                    dataSources: {
                        ...this.state.dataSources,
                        [dataSourceKey]: newCurrentDataSourceKeyArray,
                    }
                }
            );
        }
    }

    handleEntityTypeRadioChange = (event: any) => {
        event.preventDefault();
        this.setState({entityTypeRadioValue: event.target.value});
    }

    handleLegalNameRadioChange = (event: any) => {
        event.preventDefault();
        this.setState({legalNameRadioValue: event.target.value});
    }
}

export default ResolveConflictsModal;