import { Action } from 'redux';

import { Relationship } from './types';

export const GET_RELATIONSHIPS = 'store/erm/relationship/get';
export const GET_RELATIONSHIP = 'store/erm/relationship/detail/get';
export const SET_RELATIONSHIPS = 'store/erm/relationship/set';
export const SET_RELATIONSHIP = 'store/erm/relationship/detail/set';
export const CREATE_RELATIONSHIP = 'store/erm/relationship/create';
export const EDIT_RELATIONSHIP = 'store/erm/relationship/edit';
export const DELETE_RELATIONSHIP = 'store/erm/relationship/delete';

export interface GetRelationshipAction extends Action {
    filter: any;
}

export interface GetRelationshipDetailAction extends Action {
    id: string;
}

export interface SetRelationshipAction extends Action {
    relationship: Relationship;
}

export interface SetRelationshipDetailAction extends Action {
    relationship: any;
}

export interface CreateRelationshipAction extends Action {
    payload: any;
}

export interface EditRelationshipAction extends Action {
    entityId: any;
    id: any;
    payload: any;
}

export type RelationshipAction = GetRelationshipAction |
    SetRelationshipAction |
    CreateRelationshipAction |
    SetRelationshipDetailAction |
    EditRelationshipAction |
    GetRelationshipDetailAction;