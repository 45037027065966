import { Action } from 'redux';
import { FiltersState } from './types';

export const GET_FILTERS = 'store/headers/GetFilters';
export const SET_FILTERS = 'store/headers/SetFilters';
export const GET_FILTERS_ITEMS = 'store/filters/GetFiltersItems';
export const SET_FILTERS_ITEMS = 'store/filters/SetFiltersItems';
export const CALL_APPLY_FILTERS = 'store/filters/CallApplyFilters';

export interface GetFiltersAction extends Action {
    filterName: string;
    payload: Array<any>;
}

export interface SetFiltersAction extends Action {
    filterName: string;
    payload: Array<any>;
}

export interface GetFiltersItemsAction extends Action {
    filterItemName: string;
    payload: Array<any>;
}

export interface SetFiltersItemsAction extends Action {
    filterItemName: string;
    payload: Array<any>;
}

export interface CallApplyFiltersAction extends Action {
    currentFilter: string;
    actionType: string;
    otherFilter: FiltersState;
}

export type FiltersAction = GetFiltersAction |
    SetFiltersAction |
    CallApplyFiltersAction |
    GetFiltersItemsAction |
    SetFiltersItemsAction
    ;
