import { put, all, takeLatest, select } from 'redux-saga/effects';

import * as edgarApi from 'services/edgar';
import {
    EXPORT_FORMS, ExportFormsAction,
    GET_ARCHIVE_LIST, GET_D_FROM_DATA,
    GET_FILING_ENTITY_LIST, GET_NCEN_FROM_DATA,
    GetArchiveListAction, GetDFormDataAction,
    GetFilingEntityListAction, GetNCENFormDataAction,
    INIT_REDOWNLOADING_THE_ARCHIVE,
    InitRedownloadingAction
} from 'store/Edgar/actions';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import {
    getArchiveList, setArchiveList, setDFormData, setFilingEntityList,
    setNCENFormData
} from 'store/Edgar/actionCreators';
import { FilterNames } from 'store/Filters/types';
import { getFilters } from 'store/Filters/selectors';

function* getArchives(action: GetArchiveListAction) {
    try {
        yield put(initAction(action.type));

        const archives = yield edgarApi.getArchives(action.filter);
        yield put(setArchiveList(archives.results, archives.count));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* initRedownloadingTheArchive(action: InitRedownloadingAction) {
    try {
        yield put(initAction(action.type));
        yield edgarApi.initRedownloadingArchive(action.archiveId);

        const archiveFilters = yield select(getFilters, FilterNames.EdgarArchives);
        yield put(getArchiveList(archiveFilters));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getFilingEntities(action: GetFilingEntityListAction) {
    try {
        yield put(initAction(action.type));

        const filingEntities = yield edgarApi.getFilingEntities(action.filter);
        yield put(setFilingEntityList(filingEntities.results, filingEntities.count));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* exportForms(action: ExportFormsAction) {
    try {
        yield put(initAction(action.type));
        const response = yield edgarApi.exportForms(action.filter);
        yield put(doneActionSuccess(action.type, response.status));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getNCENFormData(action: GetNCENFormDataAction) {
    try {
        yield put(initAction(action.type));

        const {filing_entity_info, ...ncenForm} = yield edgarApi.getNCENForm(action.id);
        yield put(setNCENFormData(filing_entity_info, ncenForm));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getDFormData(action: GetDFormDataAction) {
    try {
        yield put(initAction(action.type));

        const {filing_entity_info, ...dForm} = yield edgarApi.getDForm(action.id);
        yield put(setDFormData(filing_entity_info, dForm));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchGetArchives() {
    yield takeLatest(GET_ARCHIVE_LIST, getArchives);
}

function* watchInitRedownloading() {
    yield takeLatest(INIT_REDOWNLOADING_THE_ARCHIVE, initRedownloadingTheArchive);
}

function* watchGetFilingEntities() {
    yield takeLatest(GET_FILING_ENTITY_LIST, getFilingEntities);
}

function* watchExportForms() {
    yield takeLatest(EXPORT_FORMS, exportForms);
}

function* watchGetNcenFormData() {
    yield takeLatest(GET_NCEN_FROM_DATA, getNCENFormData);
}

function* watchGetDFormData() {
    yield takeLatest(GET_D_FROM_DATA, getDFormData);
}

export default function* root() {
    yield all([
        watchGetArchives(),
        watchInitRedownloading(),
        watchGetFilingEntities(),
        watchExportForms(),
        watchGetNcenFormData(),
        watchGetDFormData(),
    ]);
}