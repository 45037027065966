import MockAdapter from 'axios-mock-adapter';

import mockUser from './mockUser';
import mockRca from './mockRca';

import { AxiosInstance } from 'axios';

export default (axios: AxiosInstance) => {
    let mock = new MockAdapter(axios);

    mockUser(mock);
    mockRca(mock);
};