import { put, all, takeLatest } from 'redux-saga/effects';

import * as ermApi from 'services/erm';
import NotificationHelper from 'helpers/notificationHelper';
import {
    CREATE_NEW_ENTITY,
    DELETE_DATASOURCE_BY_ID,
    EDIT_DATASOURCE,
    GET_ENTITIES,
    GET_ENTITY_PROPERTIES,
    UPDATE_ENTITY_PROPERTIES,
    ADD_DATASOURCE,
    CREATE_DATA_SOURCES,
    CreateNewEntityAction,
    CreateDataSourcesAction,
    GetEntitiesAction,
    UpdateEntityPropertiesAction,
    GetEntityPropertiesAction,
    EditDatasourceAction,
    AddDatasourceAction,
    AddEntityOtherNameAction,
    UpdateEntityOtherNameAction,
    SwapEntityLegalNameAndOtherNameAction,
    UPDATE_ENTITY_OTHER_NAME,
    SWAP_LEGAL_AND_OTHER_NAME,
    ADD_OTHER_NAME,
    PROPAGATE_ENTITY,
    PropagateEntityAction
} from 'store/Erm/Entity/actions';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import {
    getEntities as getEntitiesActionCreator, getEntityProperties, setEntities, setEntityProperties
} from 'store/Erm/Entity/actionCreators';
import { getAuditTrail } from 'store/Erm/AuditTrail/actionCreators';
import { setCloseGlobalModal } from 'store/GlobalModal/actionCreators';
import { createDataSources } from 'store/Erm/Entity/actionCreators';

import { CREATE_ENTITY_FAIL_MESSAGE, CREATE_DATA_DOURCE_FAIL_MESSAGE } from 'store/Erm/Entity/constants';
import { FilterNames } from 'store/Filters/types';
import { callApplyFilters } from 'store/Filters/actionCreators';
import { GET_FLAGGED_ENTITIES } from 'store/Erm/FlaggedEntity/actions';
import { ModalDeleteAction } from 'store/GlobalModal/actions';

import { DEFAULT_FILTER } from '../../../constants';

function* getEntities(action: GetEntitiesAction) {
    try {
        yield put(initAction(action.type));
        const response = yield ermApi.getEntities(action.filter);
        yield put(setEntities(response.results, response.count));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* createNewEntity(action: CreateNewEntityAction) {

    try {
        yield put(initAction(action.type));
        const payload = yield ermApi.createEntity(action.newEntity);
        yield put(createDataSources(payload.id, action.dataSources));
        yield put(doneActionSuccess(action.type));
        if (action.newEntity.hasOwnProperty('flagged_entity_message_id')) {
            yield put(callApplyFilters(FilterNames.ErmFlaggedEntities, GET_FLAGGED_ENTITIES));
        } else {
            yield put(getEntitiesActionCreator());
        }
    } catch (errors) {
        NotificationHelper.error(CREATE_ENTITY_FAIL_MESSAGE);
        yield put(doneActionFail(action.type, errors));

    }
}

function* updateEntityProperties(action: UpdateEntityPropertiesAction) {

    try {
        yield put(initAction(action.type));
        yield ermApi.updateEntityProperties(action.payload);
        yield put(doneActionSuccess(action.type));
        yield put(getEntityProperties(action.payload.id));
        yield put(getAuditTrail({target_id: action.payload.id}));
        NotificationHelper.success('Changes have been successfully saved!');
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* addEntityOtherName(action: AddEntityOtherNameAction) {
    try {
        yield put(initAction(action.type));
        yield ermApi.addOtherName(action.entityId, action.newObj);
        yield put(doneActionSuccess(action.type));
        const entityProperties =  yield ermApi.getEntityProperties(action.entityId);
        yield put(setEntityProperties(entityProperties));
        yield put(getAuditTrail({ ...DEFAULT_FILTER, target_id: action.entityId}));
        yield put(setCloseGlobalModal());
        NotificationHelper.success('Other name has been successfully added!');
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* updateEntityOtherName(action: UpdateEntityOtherNameAction) {
    try {
        yield put(initAction(action.type));
        yield ermApi.updateEntityOtherName(action.entityId, action.otherNameId, action.updateData);
        yield put(doneActionSuccess(action.type));
        yield put(getEntityProperties(action.entityId));
        yield put(getAuditTrail({target_id: action.entityId}));
        NotificationHelper.success('Other name has been successfully updated!');
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* swapLegalAndOtherName(action: SwapEntityLegalNameAndOtherNameAction) {
    try {
        yield put(initAction(action.type));
        yield ermApi.swapLegalAndOtherName(action.entityId, action.otherNameId);
        yield put(doneActionSuccess(action.type));
        yield put(getEntityProperties(action.entityId));
        yield put(getAuditTrail({target_id: action.entityId}));
        NotificationHelper.success('Changes have been successfully saved!');
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* propagateEntity(action: PropagateEntityAction) {
    try {
        yield put(initAction(action.type));
        yield ermApi.propagateEntityToCredit(action.entityId);
        yield put(doneActionSuccess(action.type));
        yield put(getAuditTrail({target_id: action.entityId}));
        NotificationHelper.success('Data was propagated');
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* getEntityPropertiesById(action: GetEntityPropertiesAction) {
    try {
        yield put(initAction(action.type));
        const entityProperties =  yield ermApi.getEntityProperties(action.id);
        yield put(setEntityProperties(entityProperties));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* deleteDatasourceById(action: ModalDeleteAction) {
    try {
        yield put(initAction(action.type));
        yield ermApi.deleteDatasourceById(action.modalPayload);
        yield put(doneActionSuccess(action.type));
        const entityProperties =  yield ermApi.getEntityProperties(action.modalPayload.id);
        yield put(setEntityProperties(entityProperties));
        yield put(getAuditTrail({target_id: action.modalPayload.id}));
        yield put(setCloseGlobalModal());
        NotificationHelper.success('Datasource has been successfully deleted!');
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* editDatasource(action: EditDatasourceAction) {
    try {
        yield put(initAction(action.type));
        yield ermApi.editDatasource(action.payload);
        yield put(doneActionSuccess(action.type));
        const entityProperties =  yield ermApi.getEntityProperties(action.payload.data.payload.id);
        yield put(setEntityProperties(entityProperties));
        yield put(getAuditTrail({target_id: action.payload.data.payload.id}));
        yield put(setCloseGlobalModal());
        NotificationHelper.success('Datasource has been successfully edited!');
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* addDatasource(action: AddDatasourceAction) {
    try {
        yield put(initAction(action.type));
        yield ermApi.addDatasource(action.payload);
        yield put(doneActionSuccess(action.type));
        const entityProperties =  yield ermApi.getEntityProperties(action.payload.data.payload.id);
        yield put(setEntityProperties(entityProperties));
        yield put(getAuditTrail({ ...DEFAULT_FILTER, target_id: action.payload.data.payload.id}));
        yield put(setCloseGlobalModal());
        NotificationHelper.success('Datasource has been successfully added!');
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* createNewDataSources(action: CreateDataSourcesAction) {
    try {
        yield put(initAction(action.type));

        action.dataSources.forEach((dataSource: any) => {
            ermApi.createDataSourceForEntity(action.entityId, dataSource);
        });

        yield put(doneActionSuccess(action.type));
        yield put(setCloseGlobalModal());
        NotificationHelper.success();
    } catch (errors) {
        yield put(setCloseGlobalModal());
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(CREATE_DATA_DOURCE_FAIL_MESSAGE);
    }
}

function* watchAddDatasource() {
    yield takeLatest(ADD_DATASOURCE, addDatasource);
}

function* watchEditDatasource() {
    yield takeLatest(EDIT_DATASOURCE, editDatasource);
}

function* watchDeleteDatasource() {
    yield takeLatest(DELETE_DATASOURCE_BY_ID, deleteDatasourceById);
}

function* watchGetEntity() {
    yield takeLatest(GET_ENTITIES, getEntities);
}

function* watchCreateNewEntity() {
    yield takeLatest(CREATE_NEW_ENTITY, createNewEntity);
}

function* watchUpdateEntityProperties() {
    yield takeLatest(UPDATE_ENTITY_PROPERTIES, updateEntityProperties);
}

function* watchGetEntityProperties() {
    yield takeLatest(GET_ENTITY_PROPERTIES, getEntityPropertiesById);
}

function* watchCreateDataSources() {
    yield takeLatest(CREATE_DATA_SOURCES, createNewDataSources);
}

function* watchUpdateEntityOtherName() {
    yield takeLatest(UPDATE_ENTITY_OTHER_NAME, updateEntityOtherName);
}

function* watchUseOtherNameAsLegal() {
    yield takeLatest(SWAP_LEGAL_AND_OTHER_NAME, swapLegalAndOtherName);
}

function* watchAddEntityOtherName() {
    yield takeLatest(ADD_OTHER_NAME, addEntityOtherName);
}

function* watchPropagateEntity() {
    yield takeLatest(PROPAGATE_ENTITY, propagateEntity);
}

export default function* root() {
    yield all([
        watchGetEntity(),
        watchCreateNewEntity(),
        watchUpdateEntityProperties(),
        watchGetEntityProperties(),
        watchDeleteDatasource(),
        watchEditDatasource(),
        watchAddDatasource(),
        watchCreateDataSources(),
        watchUpdateEntityOtherName(),
        watchUseOtherNameAsLegal(),
        watchAddEntityOtherName(),
        watchPropagateEntity(),
    ]);
}
