import { put, all, takeEvery, takeLatest } from 'redux-saga/effects';

import * as ermApi from 'services/erm';
import NotificationHelper from 'helpers/notificationHelper';

import {
    GET_RELATIONSHIPS, GET_RELATIONSHIP, CREATE_RELATIONSHIP, EDIT_RELATIONSHIP, DELETE_RELATIONSHIP
} from './actions';
import { setRelationships, setRelationship } from './actionCreators';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { setCloseGlobalModal } from 'store/GlobalModal/actionCreators';
import { getAuditTrail } from 'store/Erm/AuditTrail/actionCreators';
import { ModalDeleteAction } from 'store/GlobalModal/actions';
import { callApplyFilters } from 'store/Filters/actionCreators';
import { FilterNames } from 'store/Filters/types';

import { DEFAULT_FILTER, PAGE_SOURCE } from '../../../constants';

function* getRelationship(action: any) {

    try {
        yield put(initAction(action.type));
        const relationship = yield ermApi.getRelationships(action.filter);
        yield put(setRelationships(relationship));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getRelationshipDetail(action: any) {
    try {
        yield put(initAction(action.type));
        const relationship = yield ermApi.getRelationship(action.id);
        yield put(setRelationship(relationship));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* editRelationship(action: any) {

    try {
        yield put(initAction(action.type));
        yield ermApi.putRelationship(action.payload.entityId, action.payload.id, action.payload.editableObject);
        yield put(doneActionSuccess(action.type));

        if (action.payload.entityId && action.payload.pageSource !== PAGE_SOURCE.list) {
            const entityRelationships = yield ermApi.getRelationships(
                {...DEFAULT_FILTER, entity_id: action.payload.entityId}
            );
            yield put(setRelationships(entityRelationships));
            yield put(getAuditTrail({...DEFAULT_FILTER, target_id: action.payload.entityId}));
        } else {
            const entityRelationships = yield ermApi.getRelationships(
                {...DEFAULT_FILTER}
            );
            yield put(setRelationships(entityRelationships));
        }
        NotificationHelper.success('Relationship has been changed');
        yield put(setCloseGlobalModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error('We have an error');
    }
}

function* createRelationship(action: any) {

    try {
        yield put(initAction(action.type));
        yield ermApi.createRelationship(action.payload);
        yield put(doneActionSuccess(action.type));
        yield put(setCloseGlobalModal());
        if (action.entityId) {
            const entityRelationships = yield ermApi.getRelationships(
                {...DEFAULT_FILTER, entity_id: action.entityId}
            );
            yield put(setRelationships(entityRelationships));
            yield put(getAuditTrail({...DEFAULT_FILTER, target_id: action.entityId}));
        } else {
            const entityRelationships = yield ermApi.getRelationships(
                {...DEFAULT_FILTER}
            );
            yield put(setRelationships(entityRelationships));
        }
        NotificationHelper.success('Relationship has been created');
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error('You can"t create a Relationship');
    }
}

function* deleteRelationship(action: ModalDeleteAction) {
    try {
        yield put(initAction(action.type));
        yield ermApi.deleteRelationship(action.modalPayload.row.id);
        yield put(doneActionSuccess(action.type));
        if (action.modalPayload.id && action.modalPayload.pageSource !== PAGE_SOURCE.list) {
            yield put(callApplyFilters(
                FilterNames.ErmEntityRelationships,
                GET_RELATIONSHIPS,
                {entity_id: action.modalPayload.id})
            );
            yield put(getAuditTrail({...DEFAULT_FILTER, target_id: action.modalPayload.id}));
        } else {
            yield put(callApplyFilters(FilterNames.ErmRelationships, GET_RELATIONSHIPS));
        }

        yield put(setCloseGlobalModal());
        NotificationHelper.success('Relationship has been successfully deleted!');
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchCreteRelationship() {
    yield takeEvery(CREATE_RELATIONSHIP, createRelationship);
}

function* watchGetRelationship() {
    yield takeEvery(GET_RELATIONSHIPS, getRelationship);
}

function* watchGetRelationshipDetail() {
    yield takeEvery(GET_RELATIONSHIP, getRelationshipDetail);
}

function* watchEditRelationship() {
    yield takeEvery(EDIT_RELATIONSHIP, editRelationship);
}

function* watchDeleteRelationship() {
    yield takeLatest(DELETE_RELATIONSHIP, deleteRelationship);
}

export default function* root() {
    yield all([
        watchGetRelationship(),
        watchCreteRelationship(),
        watchGetRelationshipDetail(),
        watchEditRelationship(),
        watchDeleteRelationship(),
    ]);
}