import * as React from 'react';
import User from '../../store/Auth/models/user';

import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import Button from 'reactstrap/lib/Button';

const styles = require('./UserButton.scss');

interface Props {
    user: User;
    isAuthenticated: boolean;
    isInitialized: boolean;
    login: () => any;
    logout: () => any;
}

const UserButton: React.FunctionComponent<Props> = ({user, isAuthenticated, isInitialized, login, logout}) => {
    let dropDown = <div>Loading...</div>;
    if (isInitialized) {
        if (user && isAuthenticated) {
            dropDown = (
                <>
                    <DropdownToggle>
                        {user.last_name} {user.first_name}
                    </DropdownToggle>
                    <DropdownMenu >
                        <DropdownItem onClick={logout}>
                            Logout
                        </DropdownItem>
                    </DropdownMenu>
                </>
            );
        } else {
            dropDown = <Button onClick={login}>Login</Button>;
        }
    }
    return (
        <UncontrolledDropdown className={styles.userButton}>
            {dropDown}
        </UncontrolledDropdown>
    );
};

export default UserButton;