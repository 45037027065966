import { HeadersState } from './types';
import { GET_HEADERS, SET_HEADERS, SetHeadersAction, VisibleHeadersAction } from './actions';
import { CellTypeChoices, FiltersItems, ValueTypeChoices } from '../types';

import { tableHeaders as rcaHeaders } from 'components/Rca/RcaRequests';
import { tableHeaders as usersHeaders } from 'components/Users/List';
import { DATA_SOURCE_KEY_TYPE_LABELS, DATA_SOURCE_TYPE_LABELS } from 'containers/Erm/constants';
import { DataSourceEntityFromAPI } from '../Erm/FlaggedEntity/types';

const initialState: HeadersState = {
    'adv': [
        { name: 'id', title: '#', active: true, },
        { name: 'created_at', title: 'Created Date', active: true, valueType: ValueTypeChoices.Date, }
    ],
    'erm': [
        { name: 'id', title: '#', active: false, },
        {
            name: 'legal_name',
            title: 'Legal Name',
            active: true,
            cellType: CellTypeChoices.Link,
            linkTemplate: '/erm/entities/{id}/properties',
        },
        { name: 'entity_type', title: 'Entity Type', active: true, valueType: ValueTypeChoices.ContainsUnderscore, },
        { name: 'status', title: 'Status', active: true, valueType: ValueTypeChoices.ContainsUnderscore, },
        { name: 'created_at', title: 'Created At', active: true, valueType: ValueTypeChoices.Date, },
        { name: 'updated_at', title: 'Updated At', active: true, valueType: ValueTypeChoices.Date, },
        { name: 'normalized_name', title: 'Normalized Name', active: false, },
        { name: 'list_name', title: 'List Name', active: false, },
    ],
    'ermDatasources': [
        { name: 'id', title: '#', active: false,  },
        {
            name: 'data_source',
            title: 'Source',
            active: true,
            cellType: CellTypeChoices.Custom,
            transformer: (row: DataSourceEntityFromAPI) => DATA_SOURCE_TYPE_LABELS[row.data_source]
        },
        {
            name: 'data_source_key_type',
            title: 'Key Type',
            active: true,
            cellType: CellTypeChoices.Custom,
            transformer: (row: DataSourceEntityFromAPI) => DATA_SOURCE_KEY_TYPE_LABELS[row.data_source_key_type]
        },
        { name: 'data_source_key', title: 'Key', active: true, },
        { name: 'valid_from', title: 'Valid From', active: true, valueType: ValueTypeChoices.Date, },
        { name: 'valid_to', title: 'Valid To', active: true, valueType: ValueTypeChoices.Date, },
        { name: 'is_valid', title: 'Is Valid', active: true, valueType: ValueTypeChoices.Boolean, },
    ],
    'ermRelationships': [
        { name: 'id', title: '#', active: true, },
        {
            name: 'start_name',
            title: 'Starting Entity',
            active: true,
            cellType: CellTypeChoices.Link,
            linkTemplate: '/erm/entities/{start_id}/properties',
        },
        { name: 'start_type', title: 'Type', active: true, valueType: ValueTypeChoices.ContainsUnderscore, },
        {
            name: 'relationship_type',
            title: 'Relationship Type',
            active: true,
            valueType: ValueTypeChoices.ContainsUnderscore,
        },
        {
            name: 'end_name',
            title: 'Ending Entity',
            active: true,
            cellType: CellTypeChoices.Link,
            linkTemplate: '/erm/entities/{end_id}/properties',
        },
        { name: 'end_type', title: 'Type', active: true, valueType: ValueTypeChoices.ContainsUnderscore, },
        { name: 'source', title: 'Source', active: true, valueType: ValueTypeChoices.ContainsUnderscore, },
        { name: 'valid_from', title: 'Valid From', active: false, valueType: ValueTypeChoices.Date, },
        { name: 'valid_to', title: 'Valid To', active: false, valueType: ValueTypeChoices.Date, },
        { name: 'is_valid', title: 'Is Valid', active: false, valueType: ValueTypeChoices.Boolean, }
    ],
    'ermFlaggedEntities': [
        { name: 'id', title: '#', active: false, },
        { name: 'created_at', title: 'Date Generated', active: true, valueType: ValueTypeChoices.Date, },
        { name: 'entity_name', title: 'Name', active: true, },
        { name: 'data_source', title: 'Source', active: true, valueType: ValueTypeChoices.ToUppercase, },
        { name: 'event_type', title: 'Event Type', active: true, valueType: ValueTypeChoices.ContainsUnderscore, },
        { name: 'error_type', title: 'Error Type', active: true, valueType: ValueTypeChoices.ContainsUnderscore, },
        { name: 'error_message', title: 'Error message', active: true, },
        { name: 'assigned_to', title: 'Assignee', active: true, valueType: ValueTypeChoices.StringOrNone,
            cellType: CellTypeChoices.FilterItems, filterType: FiltersItems.Users
        },
    ],
    'ermFlaggedRelationships': [
        { name: 'id', title: '#', active: false, },
        { name: 'created_at', title: 'Created', active: true, valueType: ValueTypeChoices.Date, },
        { name: 'starting_entity', title: 'Starting Entity', active: true },
        {
            name: 'relationship_type',
            title: 'Relationship',
            active: true,
            valueType: ValueTypeChoices.ContainsUnderscore,
        },
        { name: 'ending_entity', title: 'Ending Entity', active: true },
        { name: 'data_source', title: 'Source', active: true, valueType: ValueTypeChoices.ToUppercase, },
        { name: 'event_type', title: 'Event Type', active: true, valueType: ValueTypeChoices.ContainsUnderscore, },
        { name: 'error_type', title: 'Error Type', active: true, valueType: ValueTypeChoices.ContainsUnderscore, },
        { name: 'assigned_to', title: 'Assignee', active: true, valueType: ValueTypeChoices.StringOrNone,
            cellType: CellTypeChoices.FilterItems, filterType: FiltersItems.Users
        },
        { name: 'error_message', title: 'Error message', active: true, valueType: ValueTypeChoices.ContainsUnderscore},
    ],
    'ermMergeEntities': [
        { name: 'id', title: '#', active: false },
        {
            name: 'main_entity.legal_name',
            title: 'Main Entity',
            active: true,
            cellType: CellTypeChoices.Link,
            linkTemplate: '/erm/entities/{main_entity.id}/properties',
        },
        {
            name: 'merging_entity.legal_name',
            title: 'Merge Entity',
            active: true,
            cellType: CellTypeChoices.Link,
            linkTemplate: '/erm/entities/{merging_entity.id}/properties',
        },
        { name: 'is_conflict', title: 'Is Conflict', active: true, valueType: ValueTypeChoices.Boolean },
    ],
    'auditTrail': [
        { name: 'id', title: '#', active: false, },
        { name: 'created_at', title: 'Date', active: true, valueType: ValueTypeChoices.DateTime, },
        { name: 'event_type', title: 'Event', active: true, valueType: ValueTypeChoices.ContainsUnderscore, },
        { name: 'user_id', title: 'User', active: true, valueType: ValueTypeChoices.StringOrNone,
            cellType: CellTypeChoices.FilterItems, filterType: FiltersItems.Users
        },
        {
            name: 'event_data_view',
            title: 'Event Data',
            active: true,
            cellType: CellTypeChoices.ModalWithText,
        },
        { name: 'target_id', title: 'Target Id', active: false, },
        { name: 'target_type', title: 'Target Type', active: false, },
    ],
    'associateToExisting': [
        { name: 'legal_name', title: 'Legal Name', active: true, },
        { name: 'entity_type', title: 'Entity Type', active: true, valueType: ValueTypeChoices.ContainsUnderscore, },
        { name: 'actions', title: 'Actions', active: true, cellType: CellTypeChoices.SelectAction, }
    ],
    'rcaRequests': rcaHeaders,
    'users': usersHeaders,
};

export const headersReducer = (state: HeadersState = initialState, action: VisibleHeadersAction) => {
    switch (action.type) {
        case GET_HEADERS:
            return {
                ...state,
                [action.block]: action.headers
            };
        case SET_HEADERS:
            action = (<SetHeadersAction> action);
            return {
                ...state,
                [action.block]: action.headers
            };
        default:
            return state;
    }
};