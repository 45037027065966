import * as React from 'react';
import { Button, Form } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Checkbox } from 'material-ui';

import {
    AssociateAndResolveBody,
    CheckboxOption,
    DataSourcesConfig,
    FlaggedEntityMessage,
    RadioOption,
    ResolveConflictBody,
} from 'store/Erm/FlaggedEntity/types';
import {
    getAssociateAndResolveEntityBody,
    parseFlaggedEntityMessage,
    setLabelsToResolveConflictDataSources,
    setLabelsToResolveConflictLegalNames,
} from 'helpers/dataSourceHelper';
import { formatText } from 'helpers/tableHelper';
import { ValueTypeChoices } from 'store/types';
import FormGroupWithRadioButtonGroup from 'components/Erm/Actions/FlaggedEntitiesActions/FormGroupWithRadioButtonGroup';
import {
    DATA_SOURCE_KEY_TYPE_LABELS,
    DEFAULT_FLAGGED_ENTITY_MESSAGE,
    ENTITY_VALUE,
    RADIO_BUTTONS_CONFIG_WITHOUT_OTHER_VALUE,
} from 'containers/Erm/constants';
import FormGroupWithCheckboxGroup from 'components/Erm/Actions/FlaggedEntitiesActions/FormGroupWithCheckboxGroup';
import { DataSourceKeyTypeEnum } from 'store/Erm/types';
import { ModalPayload } from 'store/GlobalModal/types';
import { Entity } from 'store/Erm/Entity/entity';
import { AssociateAndResolveEntityAction } from 'store/Erm/FlaggedEntity/actions';

const memoize = require('memoize-one').default;

interface Props {
    flaggedEntityMessage: ModalPayload;
    handleBackClick: () => void;
    associateAndResolveEntity: (
        flaggedMessageId: number,
        body: AssociateAndResolveBody,
        flaggedEntityName?: string,
        associatedEntityName?: string
    ) => AssociateAndResolveEntityAction;
    chosenEntity: Entity | null;
}

export interface AssociateAndResolveConflictsState {
    flaggedEntityMessage: FlaggedEntityMessage;
    resolveConflictBody: ResolveConflictBody;
    legalNames: RadioOption[];
    legalNameRadioValue: string;
    dataSources: DataSourcesConfig;
    hasMessageDataSources: boolean;
    saveOtherName: boolean;
    useEntityType?: boolean;
}

const dataSourceKeyTypeArray: string[] = [
    DataSourceKeyTypeEnum.SEC,
    DataSourceKeyTypeEnum.CRD,
    DataSourceKeyTypeEnum.LEI,
    DataSourceKeyTypeEnum.CIK,
    DataSourceKeyTypeEnum.SERIES_ID,
    DataSourceKeyTypeEnum.CLASS_ID,
    DataSourceKeyTypeEnum.TICKER,
];

const styles = require('./ResolveConflictsModal.scss');

class AssociateAndResolveSecondStepContent extends React.PureComponent<Props, AssociateAndResolveConflictsState> {
    state: AssociateAndResolveConflictsState = {
        flaggedEntityMessage: DEFAULT_FLAGGED_ENTITY_MESSAGE,
        resolveConflictBody: {
            entity_id: '',
            legal_name: '',
            data_sources: [],
            entity_type: '',
        },
        saveOtherName: true,
        legalNames: [],
        legalNameRadioValue: '',
        dataSources: {},
        hasMessageDataSources: false,
        useEntityType: undefined,
    };

    getCountOfDataSourcesInFlaggedMessage = memoize((flaggedEntityMessage: FlaggedEntityMessage) => {
        let dataSourcesCount = 0;
        Object.keys(flaggedEntityMessage.eventMessage).forEach((eventMessageKey: string) => {
            if (flaggedEntityMessage.eventMessage[eventMessageKey]
                && dataSourceKeyTypeArray.indexOf(eventMessageKey) >= 0
            ) {
                dataSourcesCount++;
            }
        });

        return dataSourcesCount;
    });

    getUseEntityTypeCheckboxOptions = memoize((
        useEntityType: boolean,
        isUseEntityTypeDisabled: boolean
    ): CheckboxOption[] => {
        const useEntityTypeLabel = 'Select here to override message entity type with existing entity type. ' +
            'Entity types must match for the associate action to be valid. ' +
            'Any updates to the entity type of the existing entity must be entered directly on the entity\'s ' +
            'Properties page after reviewing its datasources and relationships to confirm an update is appropriate.';

        return [{
            label: useEntityTypeLabel,
            checked: useEntityType,
            value: 'use_entity_type',
            disabled: isUseEntityTypeDisabled,
        }];
    });

    componentDidMount() {
        const flaggedEntityMessage = parseFlaggedEntityMessage({
            ...this.props.flaggedEntityMessage,
            entity: this.props.chosenEntity
        });
        const hasMessageDataSources = this.getCountOfDataSourcesInFlaggedMessage(flaggedEntityMessage) > 0;
        const legalNames: RadioOption[] = setLabelsToResolveConflictLegalNames(
            flaggedEntityMessage,
            RADIO_BUTTONS_CONFIG_WITHOUT_OTHER_VALUE
        );

        const dataSources = setLabelsToResolveConflictDataSources(flaggedEntityMessage, true);
        this.setState({flaggedEntityMessage, legalNames, dataSources, hasMessageDataSources});
    }

    render() {
        const flaggedEntityMessage = this.state.flaggedEntityMessage;
        const entityId = flaggedEntityMessage.entity.id;
        const conflictEntityName = flaggedEntityMessage.entity.legalName;
        const route = `/erm/entities/${entityId}/properties/`;

        const messageDataSourceType = formatText(
            flaggedEntityMessage.eventMessage.dataSourceId, ValueTypeChoices.ToUppercase
        );

        const associateButtonDisabled = !this.isUseEntityTypeDisabled && !this.state.useEntityType;

        return (
            <div>
                {
                    conflictEntityName && entityId &&
                    <div>
                      <h5>Associate to entity:&nbsp;
                        <NavLink to={route} onClick={this.props.handleBackClick}>
                          <span className="input-label">{conflictEntityName}</span>
                        </NavLink>
                      </h5>
                    </div>
                }
                <div>
                    <h5>Flagged Message Source Type:&nbsp;
                        <span className="input-label">{messageDataSourceType}</span>
                    </h5>
                </div>
                <div className={styles['form-wrapper']}>{this.renderForm()}</div>
                <div>
                    <div className="float-md-left">
                        <Button color="secondary" onClick={this.props.handleBackClick}>Back</Button>
                    </div>
                    <div className="float-md-right">
                        <Button
                            color="secondary"
                            onClick={this.handleAssociateAndResolve}
                            disabled={associateButtonDisabled}
                        >
                            Associate
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    renderForm () {
        return (
            <Form onSubmit={this.handleAssociateAndResolve} className="col-md-12">
                <div className={styles.block}>
                    <div className={styles.block}>
                        <FormGroupWithRadioButtonGroup
                            label="Legal name"
                            radioGroupName="legalName"
                            defaultSelectedRadio={ENTITY_VALUE}
                            handleRadioChange={this.handleLegalNameRadioChange}
                            radioOptions={this.state.legalNames}
                        />
                    </div>
                    <div className="row">
                        <div className="offset-md-3 col-md-9">
                            <Checkbox
                                label="Add unused name to the Other Names (Default enabled)"
                                checked={this.state.saveOtherName}
                                onCheck={this.handleSaveOtherNameChange}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.block}>
                    <FormGroupWithCheckboxGroup
                        label="Entity type"
                        handleCheckboxChange={this.handleUseEntityType}
                        checkboxOptions={this.getUseEntityTypeCheckboxOptions(
                            !!this.state.useEntityType,
                            this.isUseEntityTypeDisabled
                        )}
                    />
                </div>
                {
                    this.state.dataSources && Object.keys(this.state.dataSources).length > 0 &&
                    Object.keys(this.state.dataSources)
                        .filter(key => {
                            return this.state.dataSources[key].length > 0;
                        })
                        .map((key: string, index: number) => {
                            const formattedKey = DATA_SOURCE_KEY_TYPE_LABELS.hasOwnProperty(key)
                                ? DATA_SOURCE_KEY_TYPE_LABELS[key]
                                : formatText(key, ValueTypeChoices.ToUppercase);

                            return (
                                <div key={index} className={styles.block}>
                                    <FormGroupWithCheckboxGroup
                                        label={formattedKey}
                                        handleCheckboxChange={this.handleDataSourceChange}
                                        checkboxOptions={this.state.dataSources[key]}
                                        settingsKey={key}
                                    />
                                </div>
                            );
                        })
                }
            </Form>
        );
    }

    handleAssociateAndResolve = (event: any) => {
        event.preventDefault();
        const body: AssociateAndResolveBody = getAssociateAndResolveEntityBody(this.state);
        const messageId = this.props.flaggedEntityMessage.id;
        const flaggedEntityName = this.state.flaggedEntityMessage.entityName;
        const associatedEntityName = this.state.flaggedEntityMessage.entity.legalName;
        this.props.associateAndResolveEntity(messageId, body, flaggedEntityName, associatedEntityName);
    }

    handleDataSourceChange = (event: any, index: number, dataSourceKey: string) => {
        event.preventDefault();
        const checked = this.state.dataSources[dataSourceKey][index].checked;
        let newCurrentDataSourceKeyArray = [...this.state.dataSources[dataSourceKey]];
        newCurrentDataSourceKeyArray[index].checked = !checked;
        if (this.state.dataSources && this.state.dataSources.hasOwnProperty(dataSourceKey)) {
            this.setState((prevState) =>
                ({
                    dataSources: {
                        ...prevState.dataSources,
                        [dataSourceKey]: newCurrentDataSourceKeyArray,
                    }
                })
            );
        }
    }

    handleLegalNameRadioChange = (event: any) => {
        event.preventDefault();
        this.setState({legalNameRadioValue: event.target.value});
    }

    handleSaveOtherNameChange = (event: any) => {
        event.preventDefault();
        const checked = this.state.saveOtherName;
        this.setState({saveOtherName: !checked});
    }

    handleUseEntityType = (event: any, index: number) => {
        event.preventDefault();
        const checked = this.state.useEntityType;
        this.setState({useEntityType: !checked});
    }

    get isUseEntityTypeDisabled(): boolean {
        return this.state.flaggedEntityMessage.eventMessage.entityType ===
            this.state.flaggedEntityMessage.entity.entityType;
    }
}

export default AssociateAndResolveSecondStepContent;