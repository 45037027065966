import {
    GET_USERS, LOCK_USER, REFRESH_USERS_DATA, RESET_USER_ATTEMPTS, SET_USERS, UNLOCK_USER, UPDATE_USER
} from './actions';
import { GetListResponse, UsersFilter } from './types';
import { UpdateRequest } from './models/updateRequest';

export const refreshData = () => ({
    type: REFRESH_USERS_DATA
});

export const getItems = (filter: UsersFilter) => ({
    type: GET_USERS,
    filter
});

export const setItems = (list: GetListResponse) => ({
    type: SET_USERS,
    list
});

export const update = (id: number, request: UpdateRequest, closeModal = true) => ({
    type: UPDATE_USER,
    id, request, closeModal
});

export const lock = (id: number, closeModal = true) => ({
    type: LOCK_USER,
    id, closeModal
});

export const unlock = (id: number, closeModal = true) => ({
    type: UNLOCK_USER,
    id, closeModal
});

export const resetAttempts = (id: number, closeModal = true) => ({
    type: RESET_USER_ATTEMPTS,
    id, closeModal
});