import Rca from './models/rca';

export interface RcaState {
    list: GetListResponse;
    refreshStep: number;
}

export interface RcaFilter {
    limit: number;
    offset: number;
    ordering: string;
    account_type: string;
    status: string;
    submitter_email: string;
    approver_email: string;
}

export enum AccountType {
    Requestor = 'requestor',
    Responder = 'responder'
}

export enum AccountStatus {
    Test = 'test',
    Client = 'client',
    CounterParty = 'counterparty'
}

export enum RcaStatus {
    AwaitingApproval = 'awaiting_approval',
    Approved = 'approved',
    Denied = 'denied',
    AccountCreated = 'account_created'
}

export interface GetListResponse {
    count: number;
    results: Rca[];
    next: string;
    previous: string;
}