import { RcaState } from './types';

import { initialState } from './constants';
import { SET_RCA_ITEMS, RcaAction, SetItemsAction, REFRESH_RCA_DATA } from './actions';

export const rcaReducer = (state: RcaState = initialState, action: RcaAction) => {
    switch (action.type) {
        case REFRESH_RCA_DATA:
            return {
                ...state,
                refreshStep: state.refreshStep + 1
            };
        case SET_RCA_ITEMS:
            return {
                ...state,
                list: (<SetItemsAction> action).list
            };
        default:
            return state;
    }
};