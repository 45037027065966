import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationButton } from '../../../store/types';

const styles = require('./Button.scss');

interface Props {
    button: NavigationButton;
}

const Button: React.FunctionComponent<Props> = ({button}) => {
    const home = require(`../../../assets/img/navicons/${button.icon}.svg`);
    return (
        <NavLink
            to={button.route}
            activeClassName={styles.selected}
            className={`${styles.navButton} d-flex`}
        >
            <div className={`${styles.image}`}>
                <img src={home} width="40" alt={button.title}/>
            </div>
            <div className={`${styles.title}`}>
                {button.title}
            </div>
        </NavLink>
    );
};

export default Button;