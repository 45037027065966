import * as React from 'react';

import { HeaderSettings } from 'store/types';
import HeaderColumn from './HeaderColumn';

interface Props {
    header: HeaderSettings[];
    handleChange: (name: string, type: string, value: any) => void;
    ordering?: string;
    orderSign?: string;
}

class DataTableHeader extends React.PureComponent<Props> {
    render() {
        const {header, handleChange, ordering, orderSign} = this.props;
        const filteredHeaders = header.filter(item => {
            return item.active;
        });

        return (
            <thead>
                <tr>
                    {
                        filteredHeaders.map((item, idx) => (
                            <HeaderColumn
                                key={idx}
                                header={item}
                                handleChange={handleChange}
                                ordering={ordering}
                                orderSign={orderSign}
                            />
                        ))
                    }
                </tr>
            </thead>
        );
    }
}

export default DataTableHeader;