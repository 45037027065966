import * as React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Checkbox } from 'material-ui';
import { FormGroup } from 'reactstrap';

import { CheckboxOption } from 'store/Erm/FlaggedEntity/types';

const styles = {
    block: {
        width: '100%',
        marginBottom: -10,
    },
    checkbox: {
        display: 'block',
    },
};

interface Props {
    label?: string;
    settingsKey?: string;
    handleCheckboxChange: (event: any, index: number, key?: string) => void;
    checkboxOptions: CheckboxOption[];
}

const FormGroupWithCheckboxGroup: React.FunctionComponent<Props> = ({
    label, settingsKey, handleCheckboxChange, checkboxOptions, children
}) => (
    <div className="row">
        {
            label &&
            <FormGroup className="col-md-3">
                <span>{label}</span>
            </FormGroup>
        }
        <MuiThemeProvider>
            <div className={label ? 'col-md-9' : 'col-md-12'}>
                {
                    checkboxOptions && checkboxOptions.map((item, idx) => {
                        return (
                            <Checkbox
                                key={idx}
                                label={item.label}
                                disabled={item.disabled}
                                checked={item.checked}
                                onCheck={(event: any) => handleCheckboxChange(event, idx, settingsKey)}
                                style={styles.checkbox}
                            />
                        );
                    })
                }
                {children && children}
            </div>
        </MuiThemeProvider>
    </div>
);

export default FormGroupWithCheckboxGroup;
