import * as React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getModalParams, isModalOpen } from 'store/Modal/selectors';
import { AppState } from 'store/types';
import { closeModal } from 'store/Modal/actionCreators';
import { lock, unlock } from 'store/Users/actionCreators';
import ConfirmModal from 'components/Shared/Modal/ConfirmModal';
import * as userHelper from 'store/Users/userHelper';
import { User } from 'store/Users/models/user';
import { messages } from 'store/Users/constants';

export const name = 'LOCK_UNLOCK_USERS_MODAL';

interface Props {
    isOpened: boolean;
    params: User;
    toggle: () => any;
    lock: (id: number, closeModal: boolean) => any;
    unlock: (id: number, closeModal: boolean) => any;
}

class LockUnlockModal extends React.PureComponent<Props> {
    handleYes = () => {
        const { params: user } = this.props;
        if (userHelper.isLocked(user)) {
            this.props.unlock(user.id, true);
        } else {
            this.props.lock(user.id, true);
        }
    }

    get body() {
        const { params: user } = this.props;
        if (userHelper.isLocked(user)) {
            return messages.QUESTION_UNLOCK;
        } else {
            return messages.QUESTION_LOCK;
        }
    }

    get title() {
        const { params: user } = this.props;
        if (userHelper.isLocked(user)) {
            return 'Unlock';
        } else {
            return 'Lock';
        }
    }

    render() {
        const {isOpened, toggle} = this.props;
        return (
            <ConfirmModal
                isOpened={isOpened}
                toggle={toggle}
                no={toggle}
                yes={this.handleYes}
                title={this.title}
                body={this.body}
            />
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    isOpened: isModalOpen(state, {name}),
    params: getModalParams(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            toggle: closeModal,
            lock, unlock
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LockUnlockModal);
