import { MergeEntityState } from './types';

import { SET_MERGE_ENTITIES, SetMergeEntitiesAction, MergeEntityActions } from './actions';
import { initialState } from './constants';

const reducer = (state: MergeEntityState = initialState, action: MergeEntityActions) => {
    switch (action.type) {
        case SET_MERGE_ENTITIES:
            return {
                ...state,
                entities: (<SetMergeEntitiesAction> action).entities,
                count: (<SetMergeEntitiesAction> action).count,
            };
        default:
            return state;
    }
};

export default reducer;