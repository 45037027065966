import { GET_GLOBAL_MODAL, SET_GLOBAL_MODAL, SET_CLOSE_GLOBAL_MODAL } from './actions';

export const getGlobalModal = (globalModal: any) => ({
    type: GET_GLOBAL_MODAL,
    globalModal
});

export const setCloseGlobalModal = () => ({
    type: SET_CLOSE_GLOBAL_MODAL,
});

export const setGlobalModal = (globalModal: any) => ({
    type: SET_GLOBAL_MODAL,
    globalModal
});
