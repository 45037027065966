import { Action } from 'redux';

import FlaggedRelationship from './flaggedRelationship';

export const GET_FLAGGED_RELATIONSHIPS = 'store/erm/flaggedRelationship/get';
export const SET_FLAGGED_RELATIONSHIPS = 'store/erm/flaggedRelationship/set';
export const DELETE_FLAGGED_RELATIONSHIPS = 'store/erm/flaggedRelationship/delete';
export const ASSIGN_FLAGGED_RELATIONSHIPS = 'store/erm/flaggedRelationship/assign';
export const BULK_REPROCESS_FLAGGED_RELATIONSHIPS = 'store/erm/flaggedRelationship/bulk_reprocess';
export const REPROCESS_FLAGGED_RELATIONSHIPS = 'store/erm/flaggedRelationship/reprocess';
export const MODIFY_FLAGGED_RELATIONSHIP = 'store/erm/flaggedRelationships/modify';

export interface GetFlaggedRelationshipAction extends Action {
    filter: any;
}

export interface SetFlaggedRelationshipAction extends Action {
    flaggedRelationship: FlaggedRelationship;
}

export interface DeleteFlaggedRelationshipAction extends Action {
    payload: any;
}

export interface AssignFlaggedRelationshipAction extends Action {
    payload: any;
}

export interface ReprocessFlaggedRelationshipAction extends Action {
    payload: any;
}

export interface ModifyFlaggedRelationshipsAction extends Action {
    id: any;
    payload: any;
}

export type FlaggedRelationshipAction = GetFlaggedRelationshipAction |
    SetFlaggedRelationshipAction |
    DeleteFlaggedRelationshipAction |
    AssignFlaggedRelationshipAction |
    ModifyFlaggedRelationshipsAction |
    ReprocessFlaggedRelationshipAction;