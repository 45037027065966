import * as React from 'react';

import CountData from '../Count/CountData';
import { FilterType } from 'store/types';

interface Props {
    name: string;
    value?: any;
    title: string;
    count?: number;
    handleChange: (name: any, type: string, value: string) => void;
    countLabel?: string;
    withCount: boolean;
    className?: string;
    valueDecorator?: (value: string) => string;
}

const styles = require('./SelectFilter.scss');
const InputFilter: React.FunctionComponent<Props> = ({
    name, value, title, count, handleChange, countLabel, withCount, className, valueDecorator
}) => {
    let prettyValue = value !== undefined && value !== null && String(value) || '';

    if (valueDecorator) {
        prettyValue = valueDecorator(prettyValue);
    }

    const decoratedHandleChange = (event: any) => {
        let targetValue = event.target.value;
        if (valueDecorator) {
            targetValue = valueDecorator(event.target.value);
        }
        handleChange(name, FilterType.Input, targetValue);
    };

    return (
        <div className={styles.select_filter}>
            <div className="row">
                <div className={className || 'col-md-10'}>
                    <input
                        type="text"
                        className="form-control"
                        value={prettyValue}
                        id={name}
                        name={name}
                        onChange={decoratedHandleChange}
                        placeholder={title}
                    />
                </div>
                {
                    withCount &&
                    <div className="col-md-2">
                        <CountData count={count}/>
                        <span> {countLabel}</span>
                    </div>
                }
            </div>
        </div>
    );
};

export default InputFilter;