import * as React from 'react';
import { Form, Input } from 'reactstrap';
import { Checkbox, IconButton } from 'material-ui';
import ActionGrade from 'material-ui/svg-icons/action/delete';
import { MuiThemeProvider } from 'material-ui/styles';

import { getEntities } from 'services/erm';
import { ENTITY_TYPES, ENTITY_TYPES_SELECT, RELATIONSHIP_TYPES_SELECT } from 'containers/Erm/constants';
import GlobalHelper from 'helpers/globalHelper';
import { DataSourceKeyTypeEnum, DataSourceTypeEnum } from 'store/Erm/types';
import FormHelper from 'helpers/formHelper';
import UiDateRangePicker from 'components/Shared/ui/UiDateRangePicker';
import UiSelect from 'components/Shared/ui/Select';
import UiAsyncSelect from 'components/Shared/ui/AsyncSelect';
import { SelectItem } from 'store/types';

import { DEFAULT_ITEMS_PER_PAGE } from '../../../../constants';

interface Props {
    payload: any;
    modifyFlaggedRelationship: any;
    errors: any[];
    validator: any;
    getData: any;
    createRelationship: any;
    match: any;
}

interface Relationship {
    data_source_id: any;
    entities_type: any;
    relationship_type: any;
    starting_entity_type_doc: any;
    ending_entity_type_doc: any;
    starting_entity_name: SelectItem | string | null;
    ending_entity_name: SelectItem | string | null;
    starting_entity_type: any;
    ending_entity_type: any;
    data_source_type_starting: any;
    data_source_type_ending: any;
    data_sources: any;
    valid_from: any;
    valid_to: any;
}

const styles = require('./ModifyMessage.scss');

const filterCheckedElements = (element: any, type: any) => {
    return element[type].filter((item: any) => {
        if (item) {
            return item.is_checked === true;
        } else {
            return false;
        }

    });
};

class CreateNewEntity extends React.Component<Props> {

    config = {
        type_entities: GlobalHelper.deleteOption(ENTITY_TYPES.unknown, ENTITY_TYPES_SELECT),
        relationship_types: RELATIONSHIP_TYPES_SELECT,
        type_doc: [
            {label: 'sec', value: DataSourceKeyTypeEnum.SEC},
            {label: 'crd', value: DataSourceKeyTypeEnum.CRD},
            {label: 'lei', value: DataSourceKeyTypeEnum.LEI}
        ],
        entities_type: [],
        advSelect: []
    };

    fields = {
        starting_entity_type: ['required'],
        ending_entity_type: ['required'],
    };

    state: Relationship = {
        data_source_id: '',
        entities_type: [],
        relationship_type: '',
        starting_entity_type_doc: '',
        ending_entity_type_doc: '',
        starting_entity_name: null,
        ending_entity_name: null,
        starting_entity_type: '',
        ending_entity_type: '',
        data_source_type_starting: '',
        data_source_type_ending: '',
        valid_from: null,
        valid_to: null,
        data_sources: {
            starting: [],
            ending: []
        },
    };

    componentDidMount() {
        this.props.getData({limit: DEFAULT_ITEMS_PER_PAGE});
        if (this.props.payload.row) {
            let copyObject: any = {...this.state};
            const currentObject = this.props.payload.row.event_message;
            for (let item in copyObject) {
                if (currentObject[item]) {
                    copyObject[item] = currentObject[item];

                    if (currentObject[item] === ENTITY_TYPES.unknown) {
                        copyObject[item] = '';
                    }
                }
            }
            const typeDoc = ['sec', 'crd', 'lei'];
            let dataSources: any = {
                starting: [],
                ending: []
            };
            typeDoc.forEach((item: any) => {
                if (currentObject['starting_entity_' + item]) {
                    const dataSource = this.props.payload.row.event_message.data_source_id;
                    const newStarting: any = {
                        data_source: dataSource,
                        data_source_key_type: item,
                        data_source_key: currentObject['starting_entity_' + item],
                        is_checked: true
                    };
                    dataSources.starting.push(newStarting);
                }

                if (currentObject['ending_entity_' + item]) {
                    const dataSource = this.props.payload.row.event_message.data_source_id;
                    const newEnding: any = {
                        data_source: dataSource,
                        data_source_key_type: item,
                        data_source_key: currentObject['ending_entity_' + item],
                        is_checked: true
                    };
                    dataSources.ending.push(newEnding);
                }

                this.setState({...copyObject, data_sources: {...dataSources}});
            });
        }
        if (this.props.payload && this.props.payload.data) {
            const legalName = this.props.payload.data.legal_name;
            const id = this.props.payload.data.id;
            this.setState({
                ending_entity_name: {label: legalName, value: id},
                starting_entity_name: {label: legalName, value: id},
            });
        }
    }

     handleSubmit = async (event: any) => {
        event.preventDefault();

        if (this.props.payload.row) {
             await this.props.validator(this.fields, this.state);
             if (!this.props.errors.length) {
                 let finalData = GlobalHelper.copyObject(this.state);
                 finalData.data_sources.starting = filterCheckedElements(finalData.data_sources, 'starting');
                 finalData.data_sources.ending = filterCheckedElements(finalData.data_sources, 'ending');
                 this.props.modifyFlaggedRelationship(
                     this.props.payload.row.id, GlobalHelper.clearEmptyFields(finalData));
             }
         }

        if (!this.props.payload.row && this.state.starting_entity_name
            && this.state.ending_entity_name)  {
            const payload = {
                start_entity: typeof this.state.starting_entity_name === 'string'
                    ? this.state.starting_entity_name
                    : this.state.starting_entity_name.value,
                end_entity: typeof this.state.ending_entity_name === 'string'
                    ? this.state.ending_entity_name
                    : this.state.ending_entity_name.value,
                relationship_type: this.state.relationship_type,
                valid_from: FormHelper.setFormatDateTime(this.state.valid_from),
                valid_to: FormHelper.setFormatDateTime(this.state.valid_to),
                data_source: 'admin'
            };
            const entityId = this.props.payload.data && this.props.payload.data.id
                ? this.props.payload.data.id
                : '';
            this.props.createRelationship(payload, entityId);
        }
    }

    handleInput = (event: any) => {
        const currentEl = event.target;
        let newState = {};
        newState[currentEl.name] = currentEl.value;

        this.setState({...newState});
    }

    handleAddCheckBox = (event: any) => {
        event.preventDefault();
        const currentEl = event.target;
        const fiendedEl = this.state.data_sources[currentEl.name].find((item: any) => {
            return item.data_source_key_type === this.state['data_source_type_' + currentEl.name];
        });

        const dataSourceKey = this.state[currentEl.name + '_entity_type_doc'];
        if (!fiendedEl && this.state['data_source_type_' + currentEl.name].length > 0 && dataSourceKey.length > 0) {
            const newob = {};
            let dataSource = '';

            if (dataSourceKey === DataSourceKeyTypeEnum.SEC || dataSourceKey === DataSourceKeyTypeEnum.CRD) {
                dataSource = DataSourceTypeEnum.ADV;
            } else {
                dataSource = DataSourceTypeEnum.LEI;
            }

            newob[currentEl.name] = [...this.state.data_sources[currentEl.name], {
                data_source: dataSource,
                data_source_key_type: this.state['data_source_type_' + currentEl.name],
                data_source_key: dataSourceKey,
                is_checked: true
            }];
            this.setState({data_sources: {...this.state.data_sources, ...newob}});
        }
    }

    handleDeleteCheckbox = (item: any, relationship: any) => {
        let newObject = {};
        const newDataSources = this.state.data_sources[relationship].filter((current: any) => {
            return current.data_source_key_type !== item.data_source_key_type;
        });
        newObject[relationship] = newDataSources;

        this.setState({data_sources: {...this.state.data_sources, ...newObject}});
    }

    handleSelectChange = (selectItem: SelectItem, type: string) => {
        let currentValue = {};
        currentValue[type] = selectItem ? selectItem.value : null;
        this.setState({...currentValue});
    }

    handleAsyncSelectChange = (selectItem: SelectItem, type: string) => {
        let currentValue = {};
        currentValue[type] = selectItem || null;
        this.setState({...currentValue});
    }

    handleCheckBox = (item: any, relationship: any) => {
        let newObject = {};

        const newDataSources: any = this.state.data_sources[relationship].map((current: any) => {
                let elementNew: any = {
                    data_source: current.data_source,
                    data_source_key_type: current.data_source_key_type,
                    data_source_key: current.data_source_key,
                    is_checked: current.is_checked
                };

                if (current.data_source_key_type === item.data_source_key_type) {
                    elementNew.is_checked = !current.is_checked;
                }
                return elementNew;
        });

        newObject[relationship] = newDataSources;

        this.setState({data_sources: {...this.state.data_sources, ...newObject}});
    }

    handleChangeValid = (startDate?: any, endDate?: any ) => {
        this.setState({ valid_from: startDate, valid_to: endDate });
    }

    render() {
        let buttonTitle = 'Modify';
        let startingEntityField = (
            <Input
                type="text"
                name="starting_entity_name"
                value={this.state.starting_entity_name as string || ''}
                onChange={this.handleInput}
                id="starting_entity_name"
                placeholder="Starting Entity Name"
            />
        );

        let endingEntityField = (
            <Input
                type="text"
                name="ending_entity_name"
                value={this.state.ending_entity_name as string || ''}
                onChange={this.handleInput}
                id="ending_entity_name"
                placeholder="Ending Entity Name"
            />
        );

        if (!this.props.payload.row) {
            buttonTitle = 'Create';
            startingEntityField = (
                <UiAsyncSelect
                    placeholder="Find Starting Entity"
                    loadOptions={getEntities}
                    value={this.state.starting_entity_name as SelectItem}
                    handleChange={(currentValue: any) =>
                        this.handleAsyncSelectChange(currentValue, 'starting_entity_name')
                    }
                />
            );

            endingEntityField = (
                <UiAsyncSelect
                    placeholder="Find Ending Entity"
                    loadOptions={getEntities}
                    value={this.state.ending_entity_name as SelectItem}
                    handleChange={(currentValue: any) =>
                        this.handleAsyncSelectChange(currentValue, 'ending_entity_name')
                    }
                />
            );
        }

        return (
            <div className={styles.modify_message}>
                <Form className="col-md-12" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-md-2">
                            <label htmlFor="">Relationship Type</label>
                        </div>
                        <div className="col-md-10">
                            <UiSelect
                                placeholder="Choose Relationship Type"
                                value={this.state.relationship_type}
                                handleChange={(value: any) => this.handleSelectChange(value, 'relationship_type')}
                                options={this.config.relationship_types}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="">Starting Entity</label>
                        </div>
                        <div className="col-md-10">
                            <div className="row">
                                <div className={!this.props.payload.row ? 'col-md-12' : 'col-md-9'}>
                                    {startingEntityField}
                                </div>
                                {this.props.payload.row && <div className="col-md-3">
                                    <UiSelect
                                        placeholder="Choose Entity Type"
                                        value={this.state.starting_entity_type}
                                        handleChange={(value: any) =>
                                            this.handleSelectChange(value, 'starting_entity_type')
                                        }
                                        options={this.config.type_entities}
                                    />
                                </div>
                                }
                                <MuiThemeProvider>
                                    <div className="col-md-12">
                                        <div className="row">
                                        <table className="table">
                                            <tbody>

                                {
                                    this.state.data_sources.starting.map((item: any, idx: number) => {
                                        let deleteChckboxItem =
                                                <IconButton
                                                    touch={true}
                                                    onClick={() => this.handleDeleteCheckbox(item, 'starting')}
                                                >
                                                    <ActionGrade/>
                                                </IconButton>;
                                        return (
                                            <tr key={idx}>
                                                <td>
                                                    <Checkbox
                                                        onCheck={() => this.handleCheckBox(item, 'starting')}
                                                        checked={item.is_checked}
                                                        value={item.data_source_key_type}
                                                        label={item.data_source_key_type + ' ' +
                                                        item.data_source_key}
                                                        name={item.data_source}
                                                    />
                                                </td>
                                                <td>
                                                    {deleteChckboxItem}
                                                </td>
                                            </tr>
                                        );
                                    })
                                }

                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </MuiThemeProvider>

                                {this.props.payload.row && <div className="col-md-3">
                                    <UiSelect
                                        placeholder="Choose Data Source Type"
                                        value={this.state.data_source_type_starting}
                                        handleChange={(value: any) => this.handleSelectChange(
                                            value, 'data_source_type_starting')}
                                        options={this.config.type_doc}
                                    />
                                </div>
                                }
                                {this.props.payload.row && <div className="col-md-6">
                                    <Input
                                        type="text"
                                        value={this.state.starting_entity_type_doc}
                                        onChange={this.handleInput}
                                        name="starting_entity_type_doc"
                                        id="starting_entity_type_doc"
                                        placeholder="Data Source Key"
                                    />
                                </div>
                                }
                                {this.props.payload.row && <div className="col-md-3">
                                    <button
                                        name="starting"
                                        onClick={this.handleAddCheckBox}
                                        className="btn btn-success float-right"
                                    >
                                        add
                                    </button>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <label htmlFor="">Ending Entity</label>
                        </div>
                        <div className="col-md-10">
                            <div className="row">
                                <div className={!this.props.payload.row ? 'col-md-12' : 'col-md-9'}>
                                    {endingEntityField}
                                </div>

                                {this.props.payload.row && <div className="col-md-3">
                                    <UiSelect
                                        placeholder="Choose Entity Type"
                                        value={this.state.ending_entity_type}
                                        handleChange={(value: any) =>
                                            this.handleSelectChange(value, 'ending_entity_type')
                                        }
                                        options={this.config.type_entities}
                                    />
                                </div>}
                                <MuiThemeProvider>
                                    <div className="col-md-12">
                                        <div className="row">
                                        <table className="table">
                                            <tbody>

                                            {
                                                this.state.data_sources.ending.map((item: any, idx: number) => {
                                                    let deleteChckboxItem =
                                                        <IconButton
                                                            touch={true}
                                                            onClick={() => this.handleDeleteCheckbox(item, 'ending')}
                                                        >
                                                            <ActionGrade/>
                                                        </IconButton>;
                                                    return (
                                                        <tr key={idx}>
                                                            <td>
                                                                <Checkbox
                                                                    onCheck={() => this.handleCheckBox(item, 'ending')}
                                                                    checked={item.is_checked}
                                                                    value={item.data_source_key_type}
                                                                    label={item.data_source_key_type + ' ' +
                                                                    item.data_source_key}
                                                                    name={item.data_source}
                                                                />
                                                            </td>
                                                            <td>
                                                                {deleteChckboxItem}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }

                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </MuiThemeProvider>
                                {this.props.payload.row && <div className="col-md-3">
                                    <UiSelect
                                        placeholder="Choose Data Source Type"
                                        value={this.state.data_source_type_ending}
                                        handleChange={(value: any) => this.handleSelectChange(
                                            value, 'data_source_type_ending')}
                                        options={this.config.type_doc}
                                    />
                                </div>
                                }
                                {this.props.payload.row && <div className="col-md-6">
                                    <Input
                                        type="text"
                                        value={this.state.ending_entity_type_doc}
                                        onChange={this.handleInput}
                                        name="ending_entity_type_doc"
                                        id="ending_entity_type_doc"
                                        placeholder="Data Source Key"
                                    />
                                </div>
                                }
                                {this.props.payload.row && <div className="col-md-3">
                                    <button
                                        name="ending"
                                        onClick={this.handleAddCheckBox}
                                        className="btn btn-success float-right"
                                    >
                                        Add
                                    </button>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    {!this.props.payload.row && <div className="row">
                        <div className="col-md-2">
                            Valid (from, to)
                        </div>
                        <div className="col-md-10">
                            <UiDateRangePicker
                                startDate={this.state.valid_from}
                                endDate={this.state.valid_to}
                                startTitle="valid from"
                                endTitle="valid to"
                                handleDateChange={this.handleChangeValid}
                            />
                            </div>
                            </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <button className="btn btn-primary float-right">{buttonTitle}</button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default CreateNewEntity;