export default {
    'list' : {
        'count': 3,
        'next': null,
        'previous': null,
        'results': [
            {
                'account_name': 'Account #2',
                'account_status': 1,
                'account_type': 0,
                'ap_id': '#2',
                'approver_user': {
                    'created_at': '2018-03-07T06:46:24.772890Z',
                    'email': 'admin@gmail.com',
                    'first_name': 'First',
                    'id': 1,
                    'inviter': null,
                    'last_login': '2018-03-07T12:45:00.905199Z',
                    'last_name': 'Last',
                    'status': 'active',
                    'type': 'standard'
                },
                'id': 2,
                'initial_admin_email': 'admin@gmail.com',
                'initial_admin_first_name': 'Super',
                'initial_admin_last_name': 'Admin',
                'status': 3,
                'status_changed_at': '2018-03-07T06:50:57.570589Z',
                'submitter_user': {
                    'created_at': '2018-03-07T06:51:25.813876Z',
                    'email': 'user1@gmail.com',
                    'first_name': 'First',
                    'id': 2,
                    'inviter': null,
                    'last_login': null,
                    'last_name': 'Last',
                    'status': 'pending',
                    'type': 'standard'
                }
            },
            {
                'account_name': 'Account #3',
                'account_status': 1,
                'account_type': 0,
                'ap_id': '#3',
                'approver_user': {
                    'created_at': '2018-03-07T06:46:24.772890Z',
                    'email': 'admin@gmail.com',
                    'first_name': 'First',
                    'id': 1,
                    'inviter': null,
                    'last_login': '2018-03-07T12:45:00.905199Z',
                    'last_name': 'Last',
                    'status': 'active',
                    'type': 'standard'
                },
                'id': 3,
                'initial_admin_email': 'admin@gmail.com',
                'initial_admin_first_name': 'Super',
                'initial_admin_last_name': 'Admin',
                'status': 3,
                'status_changed_at': '2018-03-07T06:51:03.169544Z',
                'submitter_user': {
                    'created_at': '2018-03-07T06:51:25.813876Z',
                    'email': 'user1@gmail.com',
                    'first_name': 'First',
                    'id': 2,
                    'inviter': null,
                    'last_login': null,
                    'last_name': 'Last',
                    'status': 'pending',
                    'type': 'standard'
                }
            },
            {
                'account_name': 'AlphaPipe',
                'account_status': 1,
                'account_type': 0,
                'ap_id': 'AlphaPipe',
                'approver_user': {
                    'created_at': '2018-03-07T06:46:24.772890Z',
                    'email': 'admin@gmail.com',
                    'first_name': 'First',
                    'id': 1,
                    'inviter': null,
                    'last_login': '2018-03-07T12:45:00.905199Z',
                    'last_name': 'Last',
                    'status': 'active',
                    'type': 'standard'
                },
                'id': 1,
                'initial_admin_email': 'admin@gmail.com',
                'initial_admin_first_name': 'Super',
                'initial_admin_last_name': 'Admin',
                'status': 3,
                'status_changed_at': '2018-03-07T06:50:42.253124Z',
                'submitter_user': {
                    'created_at': '2018-03-07T06:51:25.813876Z',
                    'email': 'user1@gmail.com',
                    'first_name': 'First',
                    'id': 2,
                    'inviter': null,
                    'last_login': null,
                    'last_name': 'Last',
                    'status': 'pending',
                    'type': 'standard'
                }
            }
        ]
    }
};