import { put, all, takeEvery, takeLatest } from 'redux-saga/effects';

import * as ermApi from 'services/erm';
import NotificationHelper from 'helpers/notificationHelper';
import {
    GET_MERGE_ENTITIES, GetMergeEntitiesAction, MergeEntityAction, MERGE_ENTITIES, BULK_MERGE_ENTITIES,
    BulkMergeEntityAction,
} from './actions';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { setMergeEntities } from './actionCreators';
import { setCloseGlobalModal } from 'store/GlobalModal/actionCreators';
import { callApplyFilters } from 'store/Filters/actionCreators';
import { FilterNames } from 'store/Filters/types';

function* getMergeEntities(action: GetMergeEntitiesAction) {
    try {
        yield put(initAction(action.type));
        const entities = yield ermApi.getMergeEntities(action.filter);
        yield put(setMergeEntities(entities.results, entities.count));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchGetMergeEntities() {
    yield takeEvery(GET_MERGE_ENTITIES, getMergeEntities);
}

function* mergeEntities(action: MergeEntityAction) {

    try {
        yield put(initAction(action.type));
        yield ermApi.mergeEntities(action.mergingEntityRowId);
        yield put(doneActionSuccess(action.type));
        yield put(callApplyFilters(FilterNames.ErmMergeEntities, GET_MERGE_ENTITIES));
        NotificationHelper.success(`Entity merging has been started`);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchMergeEntities() {
    yield takeLatest(MERGE_ENTITIES, mergeEntities);
}

function* bulkMergeEntities(action: BulkMergeEntityAction) {
    try {
        yield put(initAction(action.type));
        yield ermApi.bulkMergeEntities(action.filter);
        yield put(doneActionSuccess(action.type));
        NotificationHelper.success(`Entities merging has been started`);
        yield put(callApplyFilters(FilterNames.ErmMergeEntities, GET_MERGE_ENTITIES));
        yield put(setCloseGlobalModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchBulkMergeEntities() {
    yield takeLatest(BULK_MERGE_ENTITIES, bulkMergeEntities);
}

export default function* root() {
    yield all([
        watchGetMergeEntities(),
        watchMergeEntities(),
        watchBulkMergeEntities()
    ]);
}