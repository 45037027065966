export const FilterNames = {
    ErmFlaggedEntities: 'erm_flagged_entities',
    ErmFlaggedRelationships: 'erm_flagged_relationships',
    ErmRelationships: 'erm_relationships',
    ErmEntityRelationships: 'erm_entity_relationships',
    ErmMergeEntities: 'erm_merge_entities',
    EdgarArchives: 'edgar_archives',
    EdgarFilingEntities: 'edgar_filing_entities',
};

export const FilterItems = {
    ermAssignTo: 'erm_assign_to',
};

export interface FiltersState {
    [key: string]: any;
}
