import * as React from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import * as authHelper from 'helpers/authHelper';
import { AppState } from 'store/types';

import { logout } from 'store/Auth/actionCreators';
import { isAuthenticated, isInitialized } from 'store/Auth/selectors';

interface Props {
    component: React.ComponentClass;
    path?: string;
    isAuth: boolean;
    isInit: boolean;
    logout: () => any;
}

class ProtectedRoute extends React.Component<Props> {
    render() {
        const {component: Component, isAuth, isInit, ...rest} = this.props;

        if (!isAuth) {
            if (isInit) {
                authHelper.redirectToLogin();
            }
        } else {
            return (
                <Route
                    {...rest}
                    render={(props) => (
                        <Component {...props} />
                    )}
                />
            );
        }

        return null;
    }
}

const mapStateToProps = (state: AppState) => ({
    isAuth: isAuthenticated(state),
    isInit: isInitialized(state)
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return bindActionCreators({logout}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps, null, {pure: false})(ProtectedRoute);
