import { connect } from 'react-redux';

import ModifyMessage from 'components/Erm/Actions/FlaggedRelationshipsActions/ModifyMessage';
import { modifyFlaggedRelationship } from 'store/Erm/FlaggedRelationship/actionCreators';
import Validation from 'decorators/ValidationDecorator';
import { getEntities } from 'store/Erm/Entity/actionCreators';
import { createRelationship } from 'store/Erm/Relationship/actionCreators';
import { AppState } from 'store/types';
import { getGlobalModalPayload } from 'store/GlobalModal/selectors';

const mapStateToProps = (state: AppState) => ({
    payload: getGlobalModalPayload(state),
});

const mapDispatchToProps = {
    modifyFlaggedRelationship,
    createRelationship,
    getData: getEntities
};

export default Validation(connect(mapStateToProps, mapDispatchToProps)(ModifyMessage));