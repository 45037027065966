import { put, all, takeEvery, takeLatest } from 'redux-saga/effects';

import * as ermApi from 'services/erm';
import NotificationHelper from 'helpers/notificationHelper';
import {
    GET_FLAGGED_ENTITIES,
    DELETE_FLAGGED_ENTITIES,
    ASSOCIATE_ENTITY,
    RESOLVE_CONFLICT_ENTITY,
    ASSIGN_FLAGGED_ENTITIES,
    REPROCESS_FLAGGED_ENTITIES,
    BULK_REPROCESS_FLAGGED_ENTITY,
    ResolveConflictEntityAction,
    AssociateAndResolveEntityAction,
} from 'store/Erm/FlaggedEntity/actions';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { setFlaggedEntities } from 'store/Erm/FlaggedEntity/actionCreators';
import { setCloseGlobalModal } from 'store/GlobalModal/actionCreators';
import { callApplyFilters } from 'store/Filters/actionCreators';
import { FilterNames } from 'store/Filters/types';
import { GET_FLAGGED_RELATIONSHIPS } from 'store/Erm/FlaggedRelationship/actions';

function* getFlaggedEntity(action: any) {

    try {
        yield put(initAction(action.type));
        const flaggedEntity = yield ermApi.getFlaggedEntities(action.filter);
        yield put(setFlaggedEntities(flaggedEntity));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchGetFlaggedEntity() {
    yield takeEvery(GET_FLAGGED_ENTITIES, getFlaggedEntity);
}

function* deleteFlaggedEntity(action: any) {
    try {
         yield put(initAction(action.type));
         yield ermApi.deleteFlaggedEntities(action.payload.messageIds);
         yield put(doneActionSuccess(action.type));
         yield put(callApplyFilters(FilterNames.ErmFlaggedEntities, GET_FLAGGED_ENTITIES));
         yield put(setCloseGlobalModal());
         NotificationHelper.success(`${action.payload.messageIds.length} entities have been successfully deleted!`);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchDeleteFlaggedEntity() {
    yield takeEvery(DELETE_FLAGGED_ENTITIES, deleteFlaggedEntity);
}

function* associateEntity(action: AssociateAndResolveEntityAction) {
    try {
        yield put(initAction(action.type));

        yield ermApi.associateAndResolveEntity(
            action.flaggedMessageId,
            action.body,
        );
        yield put(doneActionSuccess(action.type));
        yield NotificationHelper.success(
            `Flagged Entity ${action.flaggedEntityName} associated to ${action.associatedEntityName}`
        );
        yield put(setCloseGlobalModal());
        yield put(callApplyFilters(FilterNames.ErmFlaggedEntities, GET_FLAGGED_ENTITIES));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchAssociateEntity() {
    yield takeEvery(ASSOCIATE_ENTITY, associateEntity);
}

function* resolveConflictEntity(action: ResolveConflictEntityAction) {
    try {
        yield put(initAction(action.type));

        yield ermApi.resolveConflictForFlaggedEntity(
            action.flaggedMessageId,
            action.body,
        );

        yield put(doneActionSuccess(action.type));
        yield NotificationHelper.success(`Conflict resolved for Flagged Entity ${action.flaggedEntityName}`);
        yield put(setCloseGlobalModal());
        yield put(callApplyFilters(FilterNames.ErmFlaggedEntities, GET_FLAGGED_ENTITIES));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchResolveConflictEntity() {
    yield takeEvery(RESOLVE_CONFLICT_ENTITY, resolveConflictEntity);
}

function* assignFlaggedEntity(action: any) {
    try {
        yield put(initAction(action.type));

        yield ermApi.assignFlaggedEntities(action.payload.messageIds, action.payload.userId);
        yield put(doneActionSuccess(action.type));
        yield put(callApplyFilters(FilterNames.ErmFlaggedEntities, GET_FLAGGED_ENTITIES));
        NotificationHelper.success(`${action.payload.messageIds.length} entities have been successfully assigned!`);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchAssignFlaggedEntity() {
    yield takeLatest(ASSIGN_FLAGGED_ENTITIES, assignFlaggedEntity);
}

function* reprocessFlaggedEntity(action: any) {
    try {
        yield put(initAction(action.type));

        yield ermApi.reprocessFlaggedEntities(action.payload.messageIds);
        yield put(doneActionSuccess(action.type));
        yield put(callApplyFilters(FilterNames.ErmFlaggedEntities, GET_FLAGGED_ENTITIES));
        NotificationHelper.success(`${action.payload.messageIds.length} entities have been successfully reprocessed!`);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchReprocessFlaggedEntity() {
    yield takeLatest(REPROCESS_FLAGGED_ENTITIES, reprocessFlaggedEntity);
}

function* bulkReprocessFlaggedEntity(action: any) {
    try {
        yield put(initAction(action.type));
        yield ermApi.bulkReprocessFlaggedEntities();
        yield put(doneActionSuccess(action.type));
        NotificationHelper.success(
            `entities have been successfully reprocessed!`
        );
        yield put(callApplyFilters(FilterNames.ErmFlaggedRelationships, GET_FLAGGED_RELATIONSHIPS));
        yield put(setCloseGlobalModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        NotificationHelper.error(errors.message);
    }
}

function* watchBulkReprocessFlaggedEntity() {
    yield takeLatest(BULK_REPROCESS_FLAGGED_ENTITY, bulkReprocessFlaggedEntity);
}

export default function* root() {
    yield all([
        watchGetFlaggedEntity(),
        watchDeleteFlaggedEntity(),
        watchAssociateEntity(),
        watchResolveConflictEntity(),
        watchAssignFlaggedEntity(),
        watchReprocessFlaggedEntity(),
        watchBulkReprocessFlaggedEntity()
    ]);
}