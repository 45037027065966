import { BooleanFieldText, ValueTypeChoices } from '../store/types';
import * as moment from 'moment';
import { TABLE_COLUMN_DATE_FORMAT, TABLE_COLUMN_DATETIME_FORMAT } from '../constants';

export const replaceParam = (template: string, row: any): string => {
    let templateString = template;
    const paramExpression = '{(.+)}';
    const isMatch = templateString.match(paramExpression);
    if (isMatch) {
        const parameters = isMatch[1].split('.');
        const searchValue = isMatch[0];
        let obj = row;

        for (let param of parameters) {
            if (obj[param]) {
                if (typeof obj[param] === 'string') {
                    templateString = templateString.replace(searchValue, obj[param]);
                } else if (typeof obj[param] === 'object') {
                    obj = obj[param];
                }
            }
        }
    }

    return templateString;
};

export const formatText = (text: any, textType?: ValueTypeChoices): string => {
    let value = text;
    if (textType) {
        switch (textType) {
            case ValueTypeChoices.Boolean:
                value = text ? BooleanFieldText.Yes : BooleanFieldText.No;
                break;
            case ValueTypeChoices.Date:
                value = value ? moment(text).format(TABLE_COLUMN_DATE_FORMAT) : value;
                break;
            case ValueTypeChoices.DateTime:
                value = value ? moment(text).format(TABLE_COLUMN_DATETIME_FORMAT) : value;
                break;
            case ValueTypeChoices.ContainsUnderscore:
                value = value ? value.split('_').join(' ') : value;
                value = value && value[0].toUpperCase() + value.slice(1);
                break;
            case ValueTypeChoices.ToUppercase:
                value = value.toUpperCase();
                break;
            case ValueTypeChoices.StringOrNone:
                value = text ? text : 'None';
                break;
            case ValueTypeChoices.String:
            default:
                value = text;
                break;
        }
    }
    return value;
};
