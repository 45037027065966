import { HeaderSettings } from '../store/types';
import { ErmFlaggedEntitiesActions } from '../constants';

export const mapFields = (data: any, fields: HeaderSettings[]): any[] => {
    if (data === undefined || data === null) {
        return [];
    }

    return data.map((item: object) => {
        const newEntity = {};
        let i = 0;
        while (fields[i]) {
            let field = fields[i].name;
            if (item[fields[i].name]) {
                newEntity[field] = item[field];
            } else {
                newEntity[field] = 'Not Available';
            }
            i++;
        }
        return newEntity;
    });
};

export const setFilingStatus = (data: any) => {
    const statuses = {
        '12': 'status active',
        '13': 'status deleted'
    };

    // const dd = data.map(item => {
    //     const statusKey = '_' + item.status;
    //     return {
    //         ...item,
    //         status: statuses.hasOwnProperty(statusKey) ? statuses[statusKey] : undefined
    //     };
    // })

    return data.map((item: any) => {
        let newEntity: any = {};
        let i = 0;
        const statusKeys = Object.keys(statuses);
        while (statusKeys[i]) {
            if (item.status === +statusKeys[i]) {
                newEntity = {...item};
                newEntity.status = statuses[statusKeys[i]];
            }
            i++;
        }
        return newEntity;
    });
};

export const retrieveRelationships = (data: any) =>  {

    return data.map((item: any) => {
        let newEntity: any = {};
        newEntity.id = item.id;
        newEntity.start_id = item.start.id;
        newEntity.start_name = item.start.legal_name;
        newEntity.start_type = item.start.entity_type;
        newEntity.end_id = item.end.id;
        newEntity.end_name = item.end.legal_name;
        newEntity.end_type = item.end.entity_type;
        newEntity.relationship_type = item.relationship_type;
        newEntity.source = item.data_source;
        newEntity.valid_from = item.valid_from;
        newEntity.valid_to = item.valid_to;
        newEntity.is_valid = item.is_valid;
        return newEntity;
    });
};

export const retrieveAuditTrail = (data: any) =>  {
    return data.map((item: any) => {
        item.event_data_view = 'View';
        return item;
    });
};

export const getFields = (items: object[]): object[] => {
    if (items.length > 0) {
        return Object.keys(items[0]).map((field: string, idx: number) => {
            return {value: idx, title: field};
        });
    }

    return [];
};

export const getUsers = (data: Array<any>) => {
    let answer = [];

    if (data.length) {
        answer = data.map((s: any) => {
              return {     ...s,
                  label_key:  `${s.first_name} ${s.last_name}`
              };
          });
    }
    return answer;
};

export const getUsersForAction = (data: Array<any>) => {
    let answer: any[] = [];

    if (data.length) {
        answer = data.map((s: any) => {
            return {
                id: s.uuid,
                label: s.label_key,
                type: 'notmodal',
                value: ErmFlaggedEntitiesActions.assign_messages
            };
        });
    }
    return answer;
};

export const mapUsersByUuid = (data: Array<any>, value: string) => {
    let answer =  value;
    if (data) {
        const currentItem = data.find((item: any) => {
           return item.uuid === value;
        });

        if (currentItem) {
            answer =  `${currentItem.first_name} ${currentItem.last_name}`;
        }

    }

    return answer;
};
