import ApiHelper, { API_USER_URL } from '../helpers/apiHelper';
import { UsersFilter } from '../store/Users/types';
import { UpdateRequest } from '../store/Users/models/updateRequest';

export const getInfo = async () => {
    return ApiHelper.doRequest(`${API_USER_URL}/info/`, 'get');
};

export const getList = async (filter: UsersFilter) => {
    return ApiHelper.doRequest(`${API_USER_URL}/`, 'get', {...filter});
};

export const update = async (id: number, request: UpdateRequest) => {
    return ApiHelper.doRequest(`${API_USER_URL}/${id}/`, 'patch', {...request});
};

export const lock = async (id: number) => {
    return update(id, {is_locked: true});
};

export const unlock = async (id: number) => {
    return update(id, {is_locked: false});
};

export const resetAttempts = async (id: number) => {
    return ApiHelper.doRequest(`${API_USER_URL}/${id}/reset-attempts/`, 'patch');
};