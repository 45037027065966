import * as React from 'react';
import { Button } from 'reactstrap';

import DataTable from 'components/Shared/DataTable/DataTable';
import FilterPanel from 'components/Shared/Filters/FilterPanel';
import { FilterItem, FilterType, HeaderSettings } from 'store/types';
import { FiltersState } from 'store/Filters/types';
import { FilterDecoratorChange } from 'decorators/FilterDecorator';
import { Entity } from 'store/Erm/Entity/entity';

interface Props {
    results: Entity[];
    count: number;
    flaggedEntityMessage: any;
    header: HeaderSettings[];
    handleChange: FilterDecoratorChange;
    filter: FiltersState;
    handleCancel: () => void;
    handleRowClick: (rowId: number, rowData: any) => void;
    selectedRowId: number;
}

class AssociateAndResolveFirstStepContent extends React.PureComponent<Props> {

    filters: FilterItem[] = [
        { name: 'search', className: 'col-md-12', type: FilterType.Input, title: 'Search'},
    ];

    render() {
        const entities = this.props.results;
        const legalName = this.legalName;
        return (
            <div>
                {legalName && <div><b>Flagged Entity Legal Name:</b> {legalName}</div>}
                {this.renderFilters()}
                {entities && this.renderTable()}
                <div>
                    <div className="float-md-left">
                        <Button color="secondary" onClick={this.props.handleCancel}>Cancel</Button>
                    </div>
                </div>
            </div>
        );
    }

    get legalName() {
        const payload = this.props.flaggedEntityMessage;
        return payload && payload.event_message && payload.event_message.legal_name
            ? payload.event_message.legal_name
            : '';
    }

    renderTable () {
        const {results, count} = this.props;
        return (
            <DataTable
                header={this.props.header}
                rows={results}
                count={count}
                handleChange={this.props.handleChange}
                handleRowClick={this.props.handleRowClick}
                selectedRowId={this.props.selectedRowId}
            />
        );
    }

    renderFilters () {
        return (
            <div>
                <FilterPanel
                    filters={this.filters}
                    currentFilter={this.props.filter}
                    handleChange={this.props.handleChange}
                />
            </div>
        );
    }
}

export default AssociateAndResolveFirstStepContent;