import { ArchiveStatus, FilingEntityType } from './types';
import { BooleanFieldText } from '../types';

export const CONFIRM_EXPORT_EDGAR_MODAL = 'CONFIRM_EXPORT_EDGAR_MODAL';

export const initialState = {
    archives: [],
    archivesCount: 0,
    filingEntities: [],
    filingEntitiesCount: 0,
    filingEntityInfo: null,
    ncenFormData: null,
    dFormData: null,
};

export const ARCHIVE_STATUS_TITLES = {
    [ArchiveStatus.New]: 'New',
    [ArchiveStatus.Downloading]: 'Downloading',
    [ArchiveStatus.Stored]: 'Stored',
    [ArchiveStatus.Outdated]: 'Outdated',
    [ArchiveStatus.Corrupted]: 'Corrupted',
    [ArchiveStatus.QueuedDownloading]: 'Queued for downloading',
    [ArchiveStatus.ExtractingFailed]: 'Extracting failed',
};

export const ARCHIVE_STATUS_CHOICES = [
    { label: ARCHIVE_STATUS_TITLES[ArchiveStatus.New], value: ArchiveStatus.New },
    { label: ARCHIVE_STATUS_TITLES[ArchiveStatus.Downloading], value: ArchiveStatus.Downloading },
    { label: ARCHIVE_STATUS_TITLES[ArchiveStatus.Stored], value: ArchiveStatus.Stored },
    { label: ARCHIVE_STATUS_TITLES[ArchiveStatus.Outdated], value: ArchiveStatus.Outdated },
    { label: ARCHIVE_STATUS_TITLES[ArchiveStatus.Corrupted], value: ArchiveStatus.Corrupted },
    { label: ARCHIVE_STATUS_TITLES[ArchiveStatus.QueuedDownloading], value: ArchiveStatus.QueuedDownloading },
    { label: ARCHIVE_STATUS_TITLES[ArchiveStatus.ExtractingFailed], value: ArchiveStatus.ExtractingFailed },
];

export const ARCHIVE_IS_EXTRACTED_CHOICES = [
    { label: BooleanFieldText.Yes, value: 'true'},
    { label: BooleanFieldText.No, value: 'false'},
];

export const PARSED_FORM_TYPES = ['N-CEN', 'N-CEN/A', 'NT N-CEN', 'D', 'D/A'];

export const PARSED_FORM_TYPES_SETTINGS = {
    'N-CEN': { route: 'ncen-form', title: 'NCEN'},
    'N-CEN/A': { route: 'ncen-form', title: 'NCEN/A'},
    'NT N-CEN': { route: 'ncen-form', title: 'NT N-CEN'},
    'D': { route: 'd-form', title: 'D'},
    'D/A': { route: 'd-form', title: 'D/A'},
};

export const FILING_ENTITY_TITLES = {
    [FilingEntityType.Filer]: 'Filer',
    [FilingEntityType.FiledBy]: 'Filed by',
    [FilingEntityType.FiledFor]: 'Filed for',
    [FilingEntityType.SerialCompany]: 'Serial company',
    [FilingEntityType.SubjectCompany]: 'Subject company',
    [FilingEntityType.ReportingOwner]: 'Reporting owner',
    [FilingEntityType.Issuer]: 'Issuer',
};
