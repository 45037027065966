import FlaggedRelationship from './flaggedRelationship';
import {
    GET_FLAGGED_RELATIONSHIPS,
    SET_FLAGGED_RELATIONSHIPS,
    DELETE_FLAGGED_RELATIONSHIPS,
    ASSIGN_FLAGGED_RELATIONSHIPS,
    REPROCESS_FLAGGED_RELATIONSHIPS,
    MODIFY_FLAGGED_RELATIONSHIP, BULK_REPROCESS_FLAGGED_RELATIONSHIPS
} from './actions';

export const getFlaggedRelationships = (filter: any) => ({
    type: GET_FLAGGED_RELATIONSHIPS,
    filter
});

export const setFlaggedRelationships = (flaggedRelationship: FlaggedRelationship) => ({
    type: SET_FLAGGED_RELATIONSHIPS,
    flaggedRelationship
});

export const assignFlaggedRelationships = (payload: any) => ({
    type: ASSIGN_FLAGGED_RELATIONSHIPS,
    payload
});

export const bulkReprocessFlaggedRelationships = () => ({
    type: BULK_REPROCESS_FLAGGED_RELATIONSHIPS
});

export const reprocessFlaggedRelationships = (payload: any) => ({
    type: REPROCESS_FLAGGED_RELATIONSHIPS,
    payload
});

export const deleteFlaggedRelationships = (payload: any) => ({
    type: DELETE_FLAGGED_RELATIONSHIPS,
    payload
});

export const modifyFlaggedRelationship = (id: any, payload: any) => ({
    type: MODIFY_FLAGGED_RELATIONSHIP,
    id,
    payload
});